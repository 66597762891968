import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsTimeoutTimerActive } from "../../store/activity/activity.slice";
import { createHeartbeatTimer } from "../../utils/helpers/heartbeatTimerHelpers";

const HeartbeatManager = ({ currentLocationRef }) => {
  const authState = (state) => state.auth;
  const activityState = (state) => state.activity;

  const dispatch = useDispatch();
  const [heartbeatTimeStamp, setHeartbeatTimeStamp] = useState(null);
  const [isHeartbeatTimerActive, setHeartbeatTimerActive] = useState(false);
  const { lastActivityTime } = useSelector(activityState);
  const { authToken } = useSelector(authState);

  // if a guestAuth token is set, then start isModalTimerActive by setting to true
  useEffect(() => {
    if (!authToken.value) {
      setHeartbeatTimerActive(false);
      dispatch(setIsTimeoutTimerActive(false));
    } else {
      setHeartbeatTimerActive(true);
      dispatch(setIsTimeoutTimerActive(true));
    }
  }, [authToken.value]);

  // when isHeartbeatTimerActive or lastActivityTime is triggered, call createHeartbeatTimer to create
  // a new heartbeat timer while clearing the other timer.
  useEffect(() => {
    let heartbeatTimerHandle = null;
    heartbeatTimerHandle = createHeartbeatTimer(
      isHeartbeatTimerActive,
      lastActivityTime,
      heartbeatTimeStamp,
      currentLocationRef,
      authToken.value,
      setHeartbeatTimerActive,
      dispatch,
      setHeartbeatTimeStamp);

    return () => {
      clearTimeout(heartbeatTimerHandle);
    };
  }, [isHeartbeatTimerActive, lastActivityTime, authToken.value]);

  return (
    <></>
  );
};

export default HeartbeatManager;
