import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { downTime } from "../../utils/helpers/downTime";

const { REACT_APP_API_URL } = process.env;

export const getMaintenance = createAsyncThunk(
  "GET: /settings/MaintenanceWindow",
  async (arg, thunkAPI) => {
    const res = await axios
      .get(`${REACT_APP_API_URL}/settings/MaintenanceWindow`)
      .then((response) => {
        thunkAPI.dispatch(
          setMaintenanceWindow(downTime({ ...response.data.value, id: arg }))
        );
        return response.data.value;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error?.response?.data);
      });
    return res;
  }
);

const initialState = {
  isLoading: false,
  maintenanceError: null
};

export const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
    setMaintenanceWindow: (state, action) => {
      state.isMaintenanceWindowActive = action.payload;
    }
  },
  extraReducers: {
    [getMaintenance.pending]: (state) => {
      state.isLoading = true;
    },
    [getMaintenance.fulfilled]: (state, action) => {
      if (action?.payload) {
        const { message, endDate, startDate, whitelist, enabled } = action.payload;
        state.message = message;
        state.endDate = endDate;
        state.startDate = startDate;
        state.whitelist = whitelist;
        state.enabled = enabled;
        state.isLoading = false;
      }
    },
    [getMaintenance.rejected]: (state, action) => {
      state.isLoading = false;
      state.maintenanceError = action?.payload || action;
    }
  }
});

export const { setMaintenanceWindow } = maintenanceSlice.actions;
export default maintenanceSlice.reducer;
