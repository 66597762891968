export const constructRegisterPayload = (form) => {
  const {
    authScheme,
    institutionId,
    memberId,
    firstName,
    lastName,
    emailAddress,
    securityAnswer1,
    securityAnswer2
  } = form;
  return {
    auth_scheme: authScheme,
    institution_id: institutionId,
    member_id: memberId,
    first_name: firstName,
    last_name: lastName,
    email_address: emailAddress,
    security_answer_1: securityAnswer1,
    security_answer_2: securityAnswer2
  };
};


export const constructCreatePassword = (form, user, token, securityAnswer) => ({
  email_address: user?.email_address,
  first_name: user?.first_name,
  last_name: user?.last_name,
  password: form?.password,
  security_answer_2: securityAnswer,
  user_id: user?.id,
  token
});
