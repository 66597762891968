import React from "react";
import { DialogActions as MUIDialogActions } from "@mui/material";

/**
 * Wrapper component for Material UI DialogActions
 *
 * @component
 * @param {Object} props Props defined by Material UI. Please see Material UI documentation for details
 * API:
 *
 * - [DialogActions API](https://mui.com/material-ui/api/dialog-actions/)
 * @return {JSX.Element}
 */

export const DialogActions = (props) => {

  return (
    <MUIDialogActions
      data-cy="dialogActions"
      {...props}
    >
      {props.children}
    </MUIDialogActions>
  );
};
