
import React, { useEffect, useRef, useState } from "react";
import { PayToForm } from "@swbc/swivel-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "../../components";
import Title from "../../components/Title";
import { useGoogleAnalytics } from "../../services/analytics/hooks/useGoogleAnalytics";
import { clearLoanToEdit, getLoans, putLoan, resetPutLoanApiResponses } from "../../store/loans/loans.slice";

const AddAccount = () => {
  // Named selectors
  const authState = (state) => state.auth;
  const loansState = (state) => state.loans;
  const institutionState = (state) => state.institution;

  // Pass in named selectors and gets state from redux
  const { authToken } = useSelector(authState);
  const { putLoan: putLoanState, loan, list } = useSelector(loansState);
  const { details } = useSelector(institutionState);

  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { trackEvent, categories } = useGoogleAnalytics();
  const [dropdownSelection, setDropdownSelection] = useState([]);

  const accountType = useRef(location.state?.type);
  const addOrEditAction = useRef(location.state?.action);
  const pageTitle = `${addOrEditAction?.current} ${accountType?.current}`;
  const buttonText = `${addOrEditAction?.current} ${accountType?.current}`;
  const gaAction = `non_validation_${addOrEditAction?.current}_${accountType?.current}`.toLowerCase();
  const applyToTypes = details?.apply_to_types;
  const accountApplyToTypes = details?.no_validation_file?.account_apply_to_types;
  const hasAccountApplyToTypes = details?.no_validation_file?.account_apply_to_types?.length > 0;
  const filteredLoanApplyToTypes = applyToTypes?.filter((item) => !accountApplyToTypes?.includes(item));

  const determineApplyToTypes = () => {
    if (hasAccountApplyToTypes && accountType?.current === "Account") {
      setDropdownSelection(accountApplyToTypes);
    } else if (hasAccountApplyToTypes && accountType?.current === "Loan") {
      setDropdownSelection(filteredLoanApplyToTypes);
    } else {
      setDropdownSelection(applyToTypes);
    }
  };

  useEffect(() => {
    determineApplyToTypes();
    dispatch(resetPutLoanApiResponses());
  }, [dispatch]);

  const handleSubmit = (form) => {
    const payload = {
      token: authToken?.value,
      type: form.data.pay_to_type,
      name_on_account: form.data.full_name_on_pay_to,
      number: form.data.pay_to_number,
      ...(loan?.id && { id: loan?.id })
    };

    dispatch(putLoan(payload))
      .unwrap()
      .then(() => {
        dispatch(getLoans(authToken.value));
        dispatch(clearLoanToEdit());
        trackEvent(gaAction, categories.ATTEMPT_SUCCESS);
        navigate("/accounts", { state: { addAccountMessage: `${accountType?.current} Successfully ${addOrEditAction?.current}ed!` } });
      })
      .catch(() => {
        trackEvent(gaAction, categories.ATTEMPT_FAILURE);
      });
  };

  return (
    <>
      <Title title={pageTitle} />
      <Alert message={putLoanState?.apiError?.response} severity={putLoanState?.apiError?.severity} />
      <PayToForm
        formType={accountType?.current}
        accountTypes={dropdownSelection}
        primaryButtonText={buttonText}
        isLoading={putLoanState?.isSubmitting}
        onSubmit={handleSubmit}
        disableGutters={true}
        initialValues={{
          pay_to_type: loan?.type || "",
          full_name_on_pay_to: loan?.nameOnAccount || "",
          pay_to_number: loan?.number || "",
          confirmed_pay_to_number: loan?.number || ""
        }}
        options={{
          loans: list,
          payToId: loan?.id
        }}
      />
    </>
  );
};

export default AddAccount;
