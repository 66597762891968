import React, { forwardRef, useImperativeHandle, useState, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { verifyResponse, signRequest } from "../../../../../store/achWeb/achVerification.slice";
import { setActivity } from "../../../../../store/activity/activity.slice";
import { setIsAchVerificationComplete, setWarningMessage, setManuallyLinkFlow } from "../../../../../store/payment/makePayment.slice";
import { postSaveAccount } from "../../../../../store/savedAccounts/savedAccounts.slice";
import { REGISTERED } from "../../../../../utils/constants/constants";
import { achVerificationSchema, multiple, single } from "../../../../../utils/data/achVerificationSchemas";
import locations from "../../../../../utils/data/finicityACHLocations.json";
import { constructSaveAccountPayload } from "../../../../../utils/helpers/constructAchPayload";

const FinicityService = (props, ref) => {
  const {
    setValue,
    token,
    details,
    user: { first_name, last_name, email_address },
    nameOnAccount,
    setIsModalOpen,
    setShouldShowError,
    setIsButtonDisabled,
    flow,
    achWebIframe
  } = props;

  const { native_aba_list, native_aba_list_error_text } = details;
  const timeoutMessage = "Your session to connect your bank has timed out. Please try again.";
  const blackListMessage = native_aba_list_error_text || "Invalid routing number, please try again.";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hasAlreadyOpenedFinicity, setHasAlreadyOpenedFinicity] = useState(false);
  const SWBC_SDK = new window.SWBCSDK();
  const SDK_METHOD = useRef();
  const signedMessage = useRef();
  const timeoutRef = useRef();

  // * Functions open to the parent component
  useImperativeHandle(ref, () => ({
    startFinicityFlow: () => startFinicity(),
    openFinicityFlow: () => {
      setIsButtonDisabled(true);
      if (hasAlreadyOpenedFinicity || !SDK_METHOD.current) {
        startFinicity(true);
      } else {
        SDK_METHOD.current[achVerificationSchema.start](signedMessage.current);
        preventTimeoutModal();
      }
      setHasAlreadyOpenedFinicity(true);
    },
    closeFinicityFlow: () => SDK_METHOD?.current?.[achVerificationSchema.closeFlow]()
  }));

  const preventTimeoutModal = useCallback(() => {
    timeoutRef.current = setInterval(() => {
      dispatch(setActivity());
    }, 60 * 1000);
  }, [dispatch]);

  const removeTimeoutPrevention = useCallback(() => {
    clearInterval(timeoutRef.current);
  }, []);

  const startFinicity = (shouldStartImmediately) => {
    try {
      dispatch(setWarningMessage(""));
      const { ach_web_verification_id } = details;
      const achExperience = flow === REGISTERED ? multiple : single;
      const initialization = {
        Mode: "modal",
        IntegrationID: process.env.REACT_APP_ACH_INTEGRATION_PARTNER,
        Element: "swbc-consumer-experience",
        Experience: achExperience,
        ACHWebVerificationAccountId: ach_web_verification_id?.toString()
      };
      SDK_METHOD.current = new SWBC_SDK[achVerificationSchema.type](initialization);
      SDK_METHOD.current[achVerificationSchema.handler](verifyResponseHandler);
      SDK_METHOD.current[achVerificationSchema.eventHandler](eventHandler);
      const signRequestPayload = {
        first_name,
        last_name,
        email_address,
        token,
        ach_web_verification_id: ach_web_verification_id?.toString(),
        experience: achExperience
      };
      dispatch(signRequest(signRequestPayload))
        .unwrap()
        .then((message) => {
          signedMessage.current = message;
          if (shouldStartImmediately) {
            SDK_METHOD.current[achVerificationSchema.start](signedMessage.current);
            preventTimeoutModal();
          }
        })
        .catch(() => {
          SDK_METHOD?.current[achVerificationSchema.closeFlow]();
          removeTimeoutPrevention();
          showErrorAndRedirect();
        });
    } catch (error) {
      SDK_METHOD?.current[achVerificationSchema.closeFlow]();
      removeTimeoutPrevention();
      showErrorAndRedirect();
    }
  };

  const eventHandler = (event) => {
    const hasUserExited = event?.type?.toLowerCase() === "default"
      && event?.action?.toLowerCase() === "end"
      && event?.reason?.toLowerCase() === "exit";

    if (hasUserExited) {
      removeTimeoutPrevention();
      closeFinicity();
      return setIsModalOpen(true);
    }

    const isBankNotSupported = event?.errorType?.toLowerCase() === "institution_not_supported";
    const isSetupError = event?.errorType?.toLowerCase() === "setup_error";
    if (isBankNotSupported || isSetupError) {
      removeTimeoutPrevention();
      return showErrorAndRedirect();
    }

    if (event.type === "route" && event.data.screen === "error") {
      removeTimeoutPrevention();
      closeFinicity();
      return dispatch(setWarningMessage(timeoutMessage));
    }

    if (event.type === "OpenFlow" && achWebIframe) {
      achWebIframe?.current?.focus();
    }

    return undefined;
  };

  const verifyResponseHandler = (message) => {
    try {
      const payload = {
        message,
        token
      };
      dispatch(verifyResponse(payload))
        .unwrap()
        .then((res) => {
          if (flow === REGISTERED) { // All registered flows return an array of accounts
            if (!res?.length > 0) return undefined;

            res?.map((account) => {
              validateAndSetNewAccount(account?.data);
              saveNewAccount(account?.data);
            });
          } else { // else return a single account object
            if (!res?.accountNumber) return undefined;
            validateAndSetNewAccount(res);
          }

          return dispatch(setIsAchVerificationComplete(true));
        })
        .catch((error) => {
          if (error.shouldRedirect) {
            showErrorAndRedirect();
          } else {
            setIsModalOpen(true);
          }
        })
        .finally(() => {
          removeTimeoutPrevention();
          closeFinicity();
        });
    } catch (error) {
      removeTimeoutPrevention();
      closeFinicity();
      showErrorAndRedirect();
    }
  };

  const closeFinicity = () => {
    setIsButtonDisabled(false);
    SDK_METHOD.current[achVerificationSchema.closeFlow]();
  };

  const showErrorAndRedirect = () => {
    setShouldShowError(true);
    dispatch(setManuallyLinkFlow(locations[props?.entry]));
    setTimeout(() => navigate("/link-bank-account"), 5000);
  };

  const validateAndSetNewAccount = (account) => {
    const isAbaBlacklisted = native_aba_list?.some((val) => account?.routingNumber?.includes(val?.length === 9 && val));
    if (isAbaBlacklisted) {
      return dispatch(setWarningMessage(blackListMessage));
    }

    const isValidType = ["savings", "checking"].some(type => account?.accountType?.toLowerCase() === type);
    if (!isValidType) {
      return dispatch(setWarningMessage(`Account type ${account?.accountType} is not accepted. Please use a checking or savings account.`));
    }

    const accountDescription = `Account ending in ${account?.accountNumberLast4} with ${account?.nameOfInstitution}`;
    setValue("account", accountDescription);
    setValue("accountNumber", account?.accountNumber);
    setValue("routingNumber", account?.routingNumber);
    setValue("accountType", account?.accountType);

    return undefined;
  };

  const saveNewAccount = (account) => {
    const payload = constructSaveAccountPayload(account, nameOnAccount, true);
    dispatch(postSaveAccount({ ...payload, token }))
      .unwrap()
      .then(res => {
        setValue("payFromAccount", res.id);
        setValue("payFromSavedAccount", res.id);
      })
      .catch(() => { });
  };

  return <></>;
};

export default forwardRef(FinicityService);
