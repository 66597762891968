import React from "react";
import { Typography } from "../../../mui";
import { StyledDivider } from "../../../styled/StyledDivider";
import { SummaryContainer, RowContainer } from "./styles";

const Row = (props) => (
  <>
    {props?.divider ? <StyledDivider style={{ marginBlock: "0.25rem" }} /> : null}
    <RowContainer>
      <Typography variant="body1">{props.title}</Typography>
      <Typography variant="body1" sx={{ textTransform: "capitalize" }}>{props.value}</Typography>
    </RowContainer>
  </>
);

export const Summary = (props) => {

  return (
    <SummaryContainer>
      {
        props?.showHeader
          ? (
            <header>
              <Typography variant="h2">Summary</Typography>
            </header>
          )
          : null
      }
      {
        Array.isArray(props?.rows) && props?.rows?.length > 0
          ? props?.rows?.map((ele, i) => (
            <Row
              key={ele?.title + i}
              divider={ele?.divider}
              title={ele?.title}
              value={ele?.value}
            />
          ))
          : null
      }
    </SummaryContainer>
  );
};
