export const cardFormValidators = {
  fullName: {
    message: "First and last name are required and separated by a space with out numeric values.",
    value: /^[\x21-\x7E][^0-9]{0,40}[ ][\x21-\x7E][^0-9]{0,40}$/i
  },
  cardNumber: {
    message: "Card number is required and must be valid.",
    value: /^[0-9]+$/
  },
  expirationDate: {
    message: "Expiration date must be in MM/YY format",
    value: /^(0[1-9]|1[0-2])\/([0-9]{4}|[0-9]{2})$/
  },
  securityCode: {
    message: "Please enter a valid security code.",
    value: /^[0-9]{3,4}$/
  },
  amount: {
    message: "Please enter a valid payment amount.",
    value: /^[.,\d]+$/
  },
  street: {
    message: "Street is required.",
    value: /(.|\s)*\S(.|\s)*/
  },
  city: {
    message: "City is required",
    value: /(.|\s)*\S(.|\s)*/
  },
  state: {
    message: "A valid state abbreviation or full name is required.",
    value: /(.|\s)*\S(.|\s)*/
  },
  zipCode: {
    message: "Please enter a valid zip code.",
    value: /^[0-9]{5}$/
  }
};
