import React from "react";
import { Close } from "@mui/icons-material";
import { IconButton } from "../IconButton/IconButton";

/**
 * Exit button component with icon and text.
 *
 * @component
 * @param {boolean} iconOnly If passed as prop, button will be displayed with icon only.
 * @param {boolean} iconAndText If passed as prop, button will be displayed with icon and text.
 * @param {string} aria Prop passed into aria-label attribute.
 * @param {string} text Text displayed next to icon button.
 * @param {Function} closeFunc Function called on click.
 * @return {JSX.Element}
 */

export const ExitSessionButton = ({ iconOnly, iconAndText, aria, text, closeFunc }) => {
  const containerStyle = {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    fontSize: "1rem",
    gap: "0.2rem",
    maxWidth: "70px"
  };

  const handleClick = (event) => {
    event.preventDefault();
    closeFunc();
  };

  return (
    <IconButton
      aria-label={aria}
      data-cy="exitSessionButton"
      disableTouchRipple={true}
      disableFocusRipple={true}
      id="exit-container"
      onClick={handleClick}
      sx={containerStyle}
    >
      {iconAndText ? text : null}
      {iconOnly || iconAndText ? <Close aria-hidden="true" /> : null}
    </IconButton>
  );
};
