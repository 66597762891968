import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";

// Styles
const listSubHeader = {
  boxSizing: "border-box",
  color: "#333333",
  fontSize: "1rem",
  lineHeight: "2rem",
  padding: "0rem",
  position: "relative"
};

const listItemStyle = {
  boxSizing: "border-box",
  color: "#333333",
  fontSize: "1rem",
  lineHeight: "1rem",
  padding: "0rem"
};

export const BulletList = ({ title, items, sx }) => {
  return (
    <List disablePadding sx={{ boxSizing: "border-box", display: "flex", flexDirection: "column", ...sx }}>
      <ListSubheader sx={listSubHeader}>{title}</ListSubheader>
      {
        items.map((text, i) => (
          <ListItem key={i} sx={listItemStyle} disablePadding>
            <ListItemIcon sx={{ minWidth: "1.2rem" }}>
              <CircleIcon sx={{ fontSize: ".5rem" }} />
            </ListItemIcon>
            <ListItemText sx={{ margin: 0 }}>{text}</ListItemText>
          </ListItem>
        ))
      }
    </List>
  );
};
