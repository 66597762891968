import React from "react";
import { Grid, TextField } from "@mui/material";
import { cardFormValidators } from "../../../../../utils/validators/CardFormValidators";

const FinicityDisplay = ({
  register,
  errors,
  isAchVerificationComplete,
  isFinicityFlow,
  getValues
}) => {
  const accountOptions = {
    required: "Account info is required"
  };

  const nameOnAccountOptions = {
    required: "Account name is required",
    pattern: {
      value: cardFormValidators.fullName.value,
      message: cardFormValidators.fullName.message
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <TextField
          {...register("nameOnAccount", nameOnAccountOptions)}
          autoComplete="off"
          error={!!errors?.nameOnAccount}
          fullWidth
          helperText={errors?.nameOnAccount ? errors.nameOnAccount.message : " "}
          id="nameOnAccount-input"
          inputProps={{ "aria-required": "true" }}
          label="Name on Account"
          required
          disabled={isAchVerificationComplete && !isFinicityFlow}
          sx={{ zIndex: 0 }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          {...register("account", accountOptions)}
          autoComplete="off"
          error={!!errors?.account}
          fullWidth
          helperText={errors?.account ? errors.account.message : " "}
          id="account-input"
          inputProps={{ "aria-required": "true" }}
          label="Account"
          value={getValues("account") || ""}
          sx={{ zIndex: 0 }}
          required
          disabled={true}
        />
      </Grid>
    </>
  );
};

export default FinicityDisplay;
