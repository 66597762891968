import React, { useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledMain = styled.main`
  outline: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 1.5rem;
  overflow-y: scroll;
  overflow-x: hidden;
  :focus { outline: none; }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Main = ({ children }) =>{
  const location = useLocation();
  const ref = useRef();

  useLayoutEffect(() => {
    ref?.current?.focus();
  }, [location.pathname]);

  return (
    <StyledMain ref={ref} id="mainContextBox" tabIndex="-1" >
      {children}
    </StyledMain>
  );
};
