
export const getApprovedPayFromType = (accountType) => {
  switch (true) {
    case accountType?.toLowerCase()?.includes("checking"):
      return "checking";
    case accountType?.toLowerCase()?.includes("saving"):
      return "savings";
    case accountType?.toLowerCase()?.includes("business"):
      return "business";
    default:
      return "";
  }
};
