import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Alert, BulletList, Typography, IconButton } from "../../components";
import { StyledCircularProgress } from "../../components/styled/StyledCircularProgress";
import Title from "../../components/Title";
import { postChangePassword } from "../../store/auth/auth.slice";
import { useMultipleFormErrors } from "../../utils/hooks/useMultipleFormErrors";
import { validate } from "../../utils/validators/validators";
import { formStyle, sectionStyle } from "./styles";

const passwordRequirements = [
  "Minimum length of 8 characters",
  "Lowercase letter (a-z)",
  "Uppercase letter (A-Z)",
  "Special character",
  "At least one number",
  "Cannot use previous 5 password(s)"
];


export const ProfileChangePassword = () => {
  // Named selectors
  const authState = (state) => state.auth;

  // Redux State
  const { authToken, changePassword, user } = useSelector(authState);

  // Local State
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmNewPasswordVisible, setConfirmNewIsPasswordVisible] = useState(false);

  // Hooks
  const { getValues, register, handleSubmit, formState: { errors } } = useForm({  // React Hook Form
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    defaultValues: { username: user?.email_address }
  });

  const { errorMessage } = useMultipleFormErrors(errors); // Hook that for handling multiple form errors
  const dispatch = useDispatch();

  // Handlers
  const handleChangePassword = (data) => {
    const payload = {
      token: authToken.value,
      user_id: user.id,
      old_password: data?.oldPassword,
      new_password: data?.newPassword
    };
    dispatch(postChangePassword(payload));
  };

  return (
    <>
      <Title title="Change Password" />
      <Alert
        message={changePassword?.api?.response?.response}
        severity={changePassword?.api?.response?.severity}
      />
      <Alert message={errorMessage} severity="error" />
      <Typography component="p" variant="body2" sx={{ marginBlock: "0.5rem" }}>* Required Field</Typography>

      <form style={formStyle} onSubmit={handleSubmit(handleChangePassword)} noValidate={true}>
        <BulletList title="Password must have:" items={passwordRequirements} />
        <fieldset style={sectionStyle}>
          <Typography component="legend" variant="h2" sx={{ marginBottom: "1rem" }}>Password</Typography>
          <TextField sx={{ display: "none" }} {...register("username")} autoComplete="username" />
          <TextField
            {...register("oldPassword", {
              maxLength: {
                value: 55,
                message: "Password must be less than 55 characters"
              },
              pattern: validate?.newPassword,
              required: "Old password is required"
            })}
            autoComplete="current-password"
            error={!!errors?.oldPassword}
            helperText={errors?.oldPassword?.message || " "}
            id="oldPassword"
            label="Old Password"
            variant="outlined"
            required
            type={isOldPasswordVisible ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => setIsOldPasswordVisible(!isOldPasswordVisible)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {isOldPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            {...register("newPassword", {
              maxLength: {
                value: 55,
                message: "Password must be less than 55 characters"
              },
              pattern: validate?.newPassword,
              required: "New password is required"
            })}
            autoComplete="new-password"
            error={!!errors?.newPassword}
            helperText={errors?.newPassword?.message || " "}
            id="newPassword"
            label="New Password"
            variant="outlined"
            required
            type={isNewPasswordVisible ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {isNewPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            {...register("confirmNewPassword", {
              maxLength: {
                value: 55,
                message: "Password must be less than 55 characters"
              },
              pattern: validate?.confirmNewPassword,
              required: "Confirm new password is required",
              validate: {
                checkPasswords: (value) => {
                  const { newPassword } = getValues();
                  const hasLength = newPassword.length >= 1;
                  return ((value !== newPassword) && hasLength) ? "Passwords do not match" : null;
                }
              }
            })}
            autoComplete="new-password"
            error={!!errors?.confirmNewPassword}
            helperText={errors?.confirmNewPassword?.message || " "}
            id="confirmNewPassword"
            label="Confirm New Password"
            variant="outlined"
            required
            type={isConfirmNewPasswordVisible ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => setConfirmNewIsPasswordVisible(!isConfirmNewPasswordVisible)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {isConfirmNewPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </fieldset >

        <div style={{ position: "relative", display: "flex", gap: "1rem" }}>
          <Button color="primary" disabled={changePassword?.api?.isSubmitting} fullWidth size="large" type="submit" variant="contained" >
            Save Changes
          </Button>
          {changePassword?.api?.isSubmitting ? <StyledCircularProgress /> : null}
        </div>
      </form>
    </>
  );
};
