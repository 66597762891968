import React from "react";
import { grey } from "@mui/material/colors";
import useDocumentTitle from "../utils/hooks/useDocumentTitle";
import { Typography, Box } from "./mui";
import { StyledDivider } from "./styled/StyledDivider";

/**
 * Component for displaying a styled page title and subtitle.
 *
 * @component
 * @param {string} title Title of page.
 * @param {string} subTitle Subtitle of page.
 * @param {boolean} isLoggedIn Prop to add grey background.
 * @param {boolean} isCentered Prop to center title and subtitle.
 * @param {boolean} showDivider Prop set to true by default, will remove divider if set to false.
 * @return {JSX.Element}
 */

const Title = ({ title, subTitle, isLoggedIn, isCentered, showDivider = true }) => {

  useDocumentTitle(title);

  // Styles
  const styles = {
    backgroundColor: isLoggedIn ? grey[200] : "white",
    textAlign: isCentered ? "center" : "left"
  };

  return (
    <>
      <Box data-cy="title" component="header" sx={styles}>
        {
          title
            ? <Typography variant="h1" component="h1" flexGrow={1} id="title">
              {title}
            </Typography>
            : null
        }
        {
          subTitle
            ? <Typography
              variant="body1"
              component="p"
              flexGrow={1}
              id="subTitle"
            >
              {subTitle}
            </Typography>
            : null
        }
      </Box>
      {showDivider ? <StyledDivider aria-hidden="true" /> : null}
    </>
  );
};

export default Title;
