import { useEffect, useState } from "react";

export const useAchAccountUpdate = ({ getAccounts }) => {
  const [achAccounts, setAchAccounts] = useState([]);
  useEffect(() => {
    const accounts = getAccounts?.response?.results;
    const transformedAchAccounts = accounts?.map((account) => {
      return {
        ...account,
        type: "ach",
        label: account?.banking_details?.name_of_bank
          ? account?.banking_details?.name_of_bank
          : "account",
        banking_details: {
          ...account.banking_details,
          type: account?.type
        }
      };
    });
    setAchAccounts(transformedAchAccounts);
  }, [getAccounts]);

  return achAccounts;
};
