import React from "react";
import { ListItemText as MUIListItemText } from "@mui/material";

/**
 * Wrapper component for Material UI ListItemText
 *
 * @component
 * @param {object} props Props defined by Material UI. Please see Material UI documentation for
 * API:
 * - [ListItemText API](https://mui.com/material-ui/api/list-item-text/)
 * @return {JSX.Element}
 */

export const ListItemText = (props) => {
  return (
    <MUIListItemText
      data-cy="listItemText"
      {...props}
    >
      {props.children}
    </MUIListItemText>
  );
};
