export const getLoginApiResponse = (error, config) => {
  switch (error?.status) {
    case 403:
      return {
        severity: "error",
        response: `Your account is disabled. If you feel this is an error or have questions,
            please contact ${config?.brand_name} at ${config?.brand_phone}.`
      };
    case 404:
      return {
        severity: "error",
        response: "Some of your info isn't correct. Please try again."
      };
    case 502:
      return {
        severity: "error",
        response: "An unexpected connection error occurred. Please try back later."
      };
    case 503:
      return {
        severity: "warning",
        response: error
          ? error?.data?.errorMessage?.message
          : "Temporarily Offline for Maintenance."
      };
    case 504:
      return {
        severity: "error",
        response: "An unexpected connection error occurred. Please try back later."
      };
    default:
      return {
        severity: "error",
        response: "An unexpected error occurred. Please try again."
      };
  }

};
