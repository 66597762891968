import React, { useEffect, useState } from "react";
import { Lock, LockOpen } from "@mui/icons-material";
import {
  FormControl,
  FormControlLabel,
  FormHelperText
} from "@mui/material";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Checkbox, Grid, Typography, Button } from "../../components/mui";
import Title from "../../components/Title";
import { useGoogleAnalytics } from "../../services/analytics/hooks/useGoogleAnalytics";
import { postPayment, setSubmitting, setConfirmationNum, setPendingTransactions } from "../../store/payment/makePayment.slice";
import { REGISTERED } from "../../utils/constants/constants";
import { setAmountOnLoad } from "../../utils/helpers/cardHelpers";
import { constructAchPayload } from "../../utils/helpers/constructAchPayload";
import { constructCardPayload } from "../../utils/helpers/constructCardPayload";
import { getNextBankingDay } from "../../utils/helpers/dayjsHelpers";
import { getAccountLastFour } from "../../utils/helpers/handleAccounts";
import { useMultipleFormErrors } from "../../utils/hooks/useMultipleFormErrors";
import { useNonBankingDays } from "../../utils/hooks/useNonBankingDays";
import Disclosure from "./components/Disclosure";
import PaymentSummary from "./components/Summary";

const ReviewPayment = () => {
  // Named selectors
  const paymentState = (state) => state.payment;
  const institutionState = (state) => state.institution;
  const authState = (state) => state.auth;
  const savedAccountState = (state) => state.savedAccounts;
  const nonBankingDaysState = (state) => state.nonBankingDays;

  // Pass in named selectors and gets state from redux
  const { form, loan, isSubmitting, paymentType, paymentDetails, error: paymentError } = useSelector(paymentState);
  const { details, config } = useSelector(institutionState);
  const { authToken, flow } = useSelector(authState);
  const { getAccounts } = useSelector(savedAccountState);
  const { response } = useSelector(nonBankingDaysState);
  const { watch, register, handleSubmit, formState: { errors } } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      termsCheckbox: false
    }
  });
  const { errorMessage } = useMultipleFormErrors(errors);
  const { trackEvent, actions, categories, trackGtagEvent } = useGoogleAnalytics();

  // Local State
  const [account] = useState(getAccounts?.response?.results?.find(account => account.id === paymentDetails.payFromSavedAccount));

  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nonBankingDays = useNonBankingDays(response?.value);

  useEffect(() => {
    setAmountOnLoad(loan, form, dispatch);
  }, [dispatch, form, loan]);

  // Watchers
  const watchTermsCheckbox = watch("termsCheckbox", false);

  // Formatted constants
  const displayTodaysDate = `${dayjs().format("MM/DD/YYYY")}`;
  const accountLastFour = getAccountLastFour(loan.number);
  const isCardPayment = paymentType?.toLowerCase() === "card";
  const isSelectedDate = flow === REGISTERED ? !isCardPayment : false;
  const displayPaymentDate = getNextBankingDay(nonBankingDays, details?.daily_cutoff_time);
  const futureDate = isSelectedDate ? paymentDetails?.date : displayPaymentDate;
  const cardLength = form?.cardNumber?.raw?.length;
  const maskedCardNum = form?.cardNumber?.raw?.substring(cardLength - 4, cardLength);
  const cardEnding = maskedCardNum ? `from your card ending in ${maskedCardNum}` : "";
  const achEnding = paymentDetails?.accountNumber ? `from your account ending in ${getAccountLastFour(paymentDetails?.accountNumber)}` : "";
  const accountVerbiage = isCardPayment ? cardEnding : achEnding;
  const gaAction = isCardPayment ? actions.CARD_PAYMENT : actions.ACH_PAYMENT;

  const handleClickSubmit = () => {
    const payload = isCardPayment
      ? constructCardPayload(form, [], loan?.id, authToken.value, loan?.idempotencyToken, paymentDetails, config)
      : constructAchPayload(paymentDetails, [], loan?.id, authToken.value, loan?.idempotencyToken, form.convFee, config);
    dispatch(setSubmitting(true));
    dispatch(postPayment(payload))
      .unwrap()
      .then((response) => {
        dispatch(setSubmitting(false));
        if (!response.PendingTransactions) {
          trackEvent(gaAction, categories.ATTEMPT_SUCCESS);
          dispatch(setConfirmationNum(response.tracking));
          navigate("/payment-confirmed");
        } else {
          dispatch(setPendingTransactions(response.PendingTransactions));
          navigate("/pending-payments");
        }
      })
      .catch((error) => {
        dispatch(setSubmitting(false));
        trackGtagEvent(gaAction, categories.ATTEMPT_FAILURE, { event_details: error?.message });
      });
  };

  return (
    <>
      <Title title="Review Payment" />
      <Alert
        message={paymentError?.response}
        severity={paymentError?.severity}
      />
      <Alert message={errorMessage} severity="error" />
      <form onSubmit={handleSubmit(handleClickSubmit)} id="review-form">
        <Grid
          container
          direction="column"
          columns={{ xs: 12, md: 12 }}
          spacing={1}
          component="fieldset"
          sx={{ border: "none", padding: "0px", marginTop: "0.5rem" }}
        >
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              Today&apos;s date is: {displayTodaysDate}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PaymentSummary
              savedAccount={account}
              paymentDetails={paymentDetails}
              loan={loan}
              flow={flow}
              convFee={form.convFee}
              isCardPayment={isCardPayment}
              futureDate={futureDate}
              cardBrand={form?.cardBrand}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: "grid", gap: "1rem" }}>
            <Disclosure
              details={details}
              form={form}
              accountVerbiage={accountVerbiage}
              displayTodaysDate={displayTodaysDate}
              futureDate={futureDate}
              accountLastFour={accountLastFour}
            />
          </Grid>
          <Grid item sx={{ textAlign: "center" }}>
            <FormControl error={!!errors?.termsCheckbox}>
              <FormControlLabel
                {...register("termsCheckbox", {
                  required: "Please confirm you agree to the payment terms"
                })}
                aria-label="Click check mark box to agree to payment terms."
                control={<Checkbox checked={watchTermsCheckbox || false} />}
                label={<Typography> I agree to the above Payment Terms </Typography>}
              />
              <FormHelperText>{errors?.termsCheckbox?.message || " "}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
          <Grid item xs={12} md={6} order={{ sm: 1, md: 2 }}>
            <Box sx={{ position: "relative" }} gridArea="button">
              <Button
                color="primary"
                type="submit"
                form="review-form"
                disabled={isSubmitting || getAccounts?.isSubmitting}
                loading={isSubmitting || getAccounts?.isSubmitting}
                startIcon={watchTermsCheckbox ? <Lock /> : <LockOpen />}
              >
                {`Confirm Payment $${Number(form.total).toFixed(2)}`}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={{ sm: 2, md: 1 }}>
            <Button
              color="secondary"
              disabled={isSubmitting || getAccounts?.isSubmitting}
              onClick={() => {
                trackEvent(gaAction, categories.ATTEMPT_ABANDONED);
                navigate("/accounts");
              }}
            >
              Cancel Payment
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ReviewPayment;
