import React from "react";
import { useSelector } from "react-redux";
import NonValidation from "./NonValidation";
import Validation from "./Validation";

const Register = () => {

  const institutionState = (state) => state.institution;

  const { config } = useSelector(institutionState);

  if (config?.no_validation_file?.enabled) return <NonValidation />;
  return <Validation />;
};

export default Register;
