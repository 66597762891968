import styled from "styled-components";

export const Form = styled.form`
display: grid;
grid-template-columns: 1fr;
grid-template-areas:
  "left-col"
  "divider"
  "right-col"
  "button-verify"
  "button-link";
.form-buttons {
  margin-top: 1rem;
}

${props => props.materialTheme.breakpoints.up("md")} {
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "left-col right-col"
    "button-verify button-link";
  .form-buttons {
    margin-top: 0rem;
  }
}
`;

export const sectionStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  border: "none",
  padding: "0px",
  margin: "0px"
};

export const circleProgress = {
  color: "#1A1A1A",
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-.75rem",
  marginLeft: "-.75rem"
};
