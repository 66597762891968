import React from "react";
import { IconButton as MUIIconButton } from "@mui/material";

/**
 * Wrapper component for Material UI IconButton
 *
 * @component
 * @param {Object} props Props defined by Material UI. Please see Material UI documentation for details
 * API:
 *
 * - [Checkbox API](https://mui.com/material-ui/api/icon-button/)
 * @return {JSX.Element}
 */

export const IconButton = (props) => {

  return (
    <MUIIconButton
      data-cy="iconButton"
      {...props}
    >
      {props.children}
    </MUIIconButton>
  );
};
