import React from "react";
import { Typography } from "../../../../components/mui";
import { dailyCutoffTimeFormat } from "../../../../utils/helpers/dayjsHelpers";
import { getAccountLastFour } from "../../../../utils/helpers/handleAccounts";
import { Container } from "../index.styles";

const Disclosure = (props) => {

  const { paymentDetails, loan, isSelectedDate, institutionDetails, accountVerbiage, displayPaymentDate } = props;

  const paymentDate = isSelectedDate ? paymentDetails?.date : displayPaymentDate;
  const totalAmount = (Number(paymentDetails?.convenienceFee) + Number(paymentDetails?.amount)).toFixed(2).toLocaleString();
  const convenienceFee = Number(paymentDetails?.convenienceFee).toFixed(2);
  const institutionBrandName = institutionDetails?.brand_name;
  const accountLastFour = getAccountLastFour(loan?.number);
  const dailyCutoffTime = dailyCutoffTimeFormat(institutionDetails?.daily_cutoff_time);

  return (
    <>
      <Container>
        <Typography variant="subtitle1" component="p" sx={{ fontWeight: "bold" }}>Terms and Agreements</Typography>
        <Typography variant="body1" component="p" sx={{ marginTop: "1rem" }}>
          You are authorizing {institutionBrandName} to initiate a single payment {accountVerbiage} in the amount of
          ${totalAmount}, which includes a convenience fee of ${convenienceFee},
          dated on or after {paymentDate}. This amount excluding the convenience fee, will be applied to your{" "}
          {institutionBrandName} account ending in {accountLastFour}.
          Payments submitted after {dailyCutoffTime} Central Time will be processed within 3-5 business days.
          This authorization may be revoked, prior to the processing of this payment,
          by contacting {institutionBrandName} at {institutionDetails?.brand_phone}.
        </Typography>
      </Container>
    </>
  );

};
export default Disclosure;
