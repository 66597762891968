import React from "react";
import { Button, Grid, Stack } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, Icon } from "../../components";
import { useGoogleAnalytics } from "../../services/analytics/hooks/useGoogleAnalytics";
import { setLoan, setLoanPaymentType } from "../../store/payment/makePayment.slice";
import { REGISTERED, REGISTER_NEW_USER } from "../../utils/constants/constants";
import { swivelColors } from "../../utils/data/swivelColors";
import { formatDateTime } from "../../utils/helpers/dayjsHelpers";
import { getAccountLastFour } from "../../utils/helpers/handleAccounts";
import { useViewPort } from "../../utils/hooks/useViewPort";

const AccountCard = (props) => {
  const { type, number, next_amount_due, next_due_date, id, autopay } = props.data;
  const { config, details, idempotencyToken, flow } = props;

  // Hooks
  const dispatch = useDispatch();
  const viewPort = useViewPort();
  const { swivelize } = useFlags();
  const { trackEvent, actions, categories } = useGoogleAnalytics();

  const accountEnding = getAccountLastFour(number);
  const payNowAria = `Click to make a payment on account ending in ${accountEnding}`;
  const setupAutopayAria = `Click to setup autopay on account ending in ${accountEnding}`;
  const manageAutopayAria = `Click to manage your autopay for the account ending in ${accountEnding}`;

  const isAchEnabled = details?.services?.payments?.ach?.enabled || details?.ach_payment_enabled;
  const isAutopayEnabled = details?.autopay_enabled && isAchEnabled;
  const isRegisteredFlow = flow === REGISTERED || flow === REGISTER_NEW_USER;
  const hasAutopayConfigured = isRegisteredFlow && isAutopayEnabled;
  const hasAutopayNextRunDate = autopay?.next_run_date ? formatDateTime(autopay?.next_run_date, "MM/DD/YYYY") : null;
  const hasNextAmountDue = next_amount_due !== null && next_amount_due !== "null";
  const hasNextDueDate = next_due_date !== null && next_due_date !== "null";

  const autopayFrequency = {
    "monthly": "monthly",
    "every_two_weeks": "bi-weekly",
    "weekly": "weekly"
  };
  const hasAutoPayFrequency = autopayFrequency[autopay?.frequency] || "";

  const setLoanToPay = () => {
    const loanInfo = { ...props.data, idempotencyToken };
    dispatch(setLoan(loanInfo));
    dispatch(setLoanPaymentType(type));
  };

  return (
    <Grid
      container
      wrap="nowrap"
      direction={viewPort.medium ? "row" : "column"}
      sx={{ display: "flex", gap: 2, width: "100%" }}
    >
      <Stack sx={{ paddingInline: "1rem", justifyContent: "center" }}>
        <Icon type={type} color={swivelize ? swivelColors.primary_button_color : config?.primary_button_color} />
      </Stack>

      <Grid sx={{ display: "flex", flexDirection: "column", width: "100%" }} item>
        <Typography
          variant="h2"
          component="h2"
          noWrap
          sx={{
            display: "inline-block",
            textTransform: "capitalize",
            overflow: "hidden",
            textAlign: "left",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
        >
          {type.toLowerCase()}
        </Typography>
        <Typography variant="body1" color="text.secondary" >
          {`Account ending in: ${accountEnding}`}
        </Typography>
        {
          hasNextAmountDue ?
            <Typography variant="body1" color="text.secondary" >
              {`Amount due: $${next_amount_due}`}
            </Typography>
            : null
        }
        {
          hasNextDueDate ?
            <Typography variant="body1" color="text.secondary" >
              {`Due date: ${next_due_date}`}
            </Typography>
            : null
        }
        {
          hasAutopayConfigured && hasAutopayNextRunDate ?
            <Typography variant="body1" color="text.secondary" sx={{ maxWidth: "30ch" }} >
              {`Your next ${hasAutoPayFrequency} recurring payment will occur on: ${hasAutopayNextRunDate}`}
            </Typography>
            : null
        }
      </Grid>
      { details?.apply_to_types.includes(type) ?
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            gap: 2,
            width: "100%",
            alignSelf: "center"
          }}
        >
          {
            !props?.data?.autopay
              ?
              <Button
                id={`AccountButton-${id}`}
                component={Link}
                role="button"
                to={"/make-payment"}
                variant="contained"
                fullWidth
                color="primary"
                aria-label={payNowAria}
                state={{ data: props.info }}
                onClick={() => {
                  setLoanToPay();
                  trackEvent(actions.BUTTON_CLICK, categories.PAY_NOW);
                }}
                size={viewPort.medium ? "medium" : "small"}
              >
                Pay Now
              </Button>
              : null
          }
          {
            hasAutopayConfigured ?
              <Button
                id={`Autopay-${id}`}
                component={Link}
                role="button"
                fullWidth
                to={props?.data?.autopay ? "/autopay/edit" : "/autopay/setup"}
                variant="contained"
                color="secondary"
                aria-label={props?.data?.autopay ? manageAutopayAria : setupAutopayAria}
                onClick={() => {
                  setLoanToPay();
                  trackEvent(actions.BUTTON_CLICK, props?.data?.autopay ? categories.MANAGE_AUTOPAY : categories.SETUP_AUTOPAY);
                }}
                size={viewPort.medium ? "medium" : "small"}
              >
                {
                  props?.data?.autopay
                    ? "Manage Autopay"
                    : "Setup Autopay"
                }
              </Button>
              : null
          }
        </Grid>  :
        <Grid sx={{ display: "flex", flexDirection: "column", alignSelf: "end", width: "100%" }} item>
          <Typography variant="body1">
            {`${type} Loan is not a payable Loan. Please contact ${details?.brand_name} support.`}
          </Typography>
        </Grid>
      }

    </Grid >
  );
};

export default AccountCard;
