import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  patchSaveAccountResponses,
  deleteSavedAccountResponses
} from "../../utils/data/responseCodeMessages";

const { REACT_APP_API_URL } = process.env;

export const getSavedAccounts = createAsyncThunk(
  "GET: /account",
  async (token, { rejectWithValue }) => {
    const header = { headers: { Authorization: token } };
    const res = await axios
      .get(`${REACT_APP_API_URL}/account`, header)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return rejectWithValue({
          status: error.response.status,
          data: error.response.data
        });
      });
    return res;
  }
);

export const postSaveAccount = createAsyncThunk(
  "POST: /account",
  async (payload, { rejectWithValue }) => {
    const header = { headers: { Authorization: payload?.token } };
    delete payload?.token;

    const response = await axios.post(`${REACT_APP_API_URL}/account`, payload, header)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return rejectWithValue({
          status: error?.response?.status,
          data: error?.response?.data
        });
      });

    return response;
  }
);

export const patchSaveAccount = createAsyncThunk(
  "PATCH: /account/{id}",
  async (payload, { rejectWithValue }) => {
    const url = `${REACT_APP_API_URL}/account/${payload?.id}`;
    const header = { headers: { Authorization: payload?.token } };
    delete payload?.token;

    const response = await axios.patch(url, payload, header)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        const status = error?.response?.status || error?.request?.status;
        const data = error?.response?.data;
        const errorResponse = patchSaveAccountResponses()[status]
          || patchSaveAccountResponses()[data.errorMessage]
          || patchSaveAccountResponses()["default"];
        return rejectWithValue(errorResponse);
      });

    return response;
  }
);

export const deleteSavedAccount = createAsyncThunk(
  "DELETE: /account/{id}",
  async (payload, { rejectWithValue }) => {
    const url = `${REACT_APP_API_URL}/account/${payload?.id}`;
    const header = { headers: { Authorization: payload?.token } };
    delete payload?.token;

    const response = await axios.delete(url, header)
      .then((res) => {
        const successResponse = deleteSavedAccountResponses()[res?.status];
        return {
          ...successResponse,
          id: payload?.id
        };
      })
      .catch((error) => {
        const status = error?.response?.status || error?.request?.status;
        const data = error?.response?.data;
        const errorResponse = deleteSavedAccountResponses()[status]
          || deleteSavedAccountResponses()[data.errorMessage]
          || deleteSavedAccountResponses()["default"];
        return rejectWithValue(errorResponse);
      });

    return response;
  }
);

const initialState = {
  getAccounts: {
    isLoading: false,
    isSubmitting: false,
    response: {},
    error: null
  },
  saveAccount: {
    isSubmitting: false,
    response: [],
    error: null,
    success: false,
    fulfilledAccounts: []
  },
  deleteAccount: {
    isSubmitting: false,
    response: null,
    error: null,
    success: false
  },
  expressEnhancedAccount: null
};

export const savedAccountsSlice = createSlice({
  name: "savedAccounts",
  initialState,
  reducers: {
    addSavedAccount: (state, { payload }) => {
      state.getAccounts.response.results = [...state.getAccounts.response.results, payload];
    },
    clearGetAccountsErrors: (state) => {
      state.getAccounts.error = null;
    },
    clearSavedAccount: (state) => {
      state.saveAccount.isSubmitting = initialState.saveAccount.isSubmitting;
      state.saveAccount.response = initialState.saveAccount.response;
      state.saveAccount.error = initialState.saveAccount.error;
      state.saveAccount.success = initialState.saveAccount.success;
    },
    setExpressEnhancedAccount: (state, { payload }) => {
      state.expressEnhancedAccount = payload;
    },
    setFulfilledAccounts: (state, { payload }) => {
      state.saveAccount.fulfilledAccounts = payload;
    },
    clearExpressEnhancedAccount: (state) => {
      state.expressEnhancedAccount = initialState.expressEnhancedAccount;
    },
    clearSavedAccounts: () => initialState,
    setProfileError: (state, { payload }) => {
      state.deleteAccount.response = payload;
    },
    clearDeleteAccountErrors: (state) => {
      state.deleteAccount.response = initialState.deleteAccount.response;
    }
  },
  extraReducers: {
    [getSavedAccounts.pending]: (state) => {
      state.getAccounts.isLoading = true;
      state.getAccounts.response = {};
      state.getAccounts.error = null;
    },
    [getSavedAccounts.fulfilled]: (state, { payload }) => {
      state.getAccounts.isLoading = false;
      state.getAccounts.response = payload;
      state.getAccounts.error = null;
    },
    [getSavedAccounts.rejected]: (state, { payload }) => {
      state.getAccounts.isLoading = false;
      state.getAccounts.response = {};
      state.getAccounts.error = payload;
    },
    [postSaveAccount.pending]: (state) => {
      state.saveAccount.isSubmitting = true;
      state.saveAccount.response = [];
      state.saveAccount.error = null;
      state.saveAccount.success = false;
    },
    [postSaveAccount.fulfilled]: (state, { payload }) => {
      state.saveAccount.isSubmitting = false;
      state.saveAccount.error = null;
      state.saveAccount.response = [...state.saveAccount.response, payload];
      state.getAccounts.response.results = [...state.getAccounts.response.results, payload];
      state.saveAccount.success = true;
    },
    [postSaveAccount.rejected]: (state, { payload }) => {
      state.saveAccount.isSubmitting = false;
      state.saveAccount.error = payload;
      state.saveAccount.success = false;
    },
    [patchSaveAccount.pending]: (state) => {
      state.getAccounts.isSubmitting = true;
      state.getAccounts.success = false;
      state.getAccounts.error = null;
    },
    [patchSaveAccount.fulfilled]: (state, { payload }) => {
      state.getAccounts.isSubmitting = false;
      const index = state.getAccounts.response.results.findIndex(ele => ele.id === payload.id);
      if (index !== -1) {
        state.getAccounts.response.results[index] = payload;
      }
      state.getAccounts.success = true;
    },
    [patchSaveAccount.rejected]: (state, { payload }) => {
      state.getAccounts.isSubmitting = false;
      state.getAccounts.error = payload;
      state.getAccounts.success = false;
      state.getAccounts.failures += 1;
    },
    [deleteSavedAccount.pending]: (state) => {
      state.deleteAccount.isSubmitting = true;
      state.deleteAccount.response = null;
      state.deleteAccount.error = null;
      state.deleteAccount.success = false;
    },
    [deleteSavedAccount.fulfilled]: (state, { payload }) => {
      state.deleteAccount.isSubmitting = false;
      state.deleteAccount.response = payload;
      state.deleteAccount.error = null;
      const index = state.getAccounts.response.results.findIndex(ele => ele.id === payload.id);
      if (index !== -1) {
        state.getAccounts.response.results.splice(index, 1);
      }
      state.deleteAccount.success = true;
    },
    [deleteSavedAccount.rejected]: (state, { payload }) => {
      state.deleteAccount.isSubmitting = false;
      state.deleteAccount.response = null;
      state.deleteAccount.success = false;
      state.deleteAccount.error = payload;
    }
  }
});

export const {
  clearSavedAccounts,
  clearSavedAccount,
  addSavedAccount,
  clearGetAccountsErrors,
  setProfileError,
  clearDeleteAccountErrors,
  setExpressEnhancedAccount,
  setFulfilledAccounts,
  clearExpressEnhancedAccount
} = savedAccountsSlice.actions;
export default savedAccountsSlice.reducer;
