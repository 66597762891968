import { useState, useEffect } from "react";
import { MULTIPLE_FORM_ERROR_MESSAGE } from "../constants/constants";

/**
 * useMultipleFormErrors - Custom Hook
 *
 * @param { Object } errors Object of errors on a form
 * @param { String | Array<String> } exclude String or an Array of strings, with values to exclude
 * @return {{ errorMessage: string }} Returns an object with property of errorMessage
 * @example
 * const { errorMessage } = useMultipleFormErrors(errors, "stateInput");
 * or
 * const { errorMessage } = useMultipleFormErrors(errors, ["stateInput", "city"]);
 *
 * console.log(errorMessage) // "Several errors on the form, please fix the errors for any input below."
 */

export const useMultipleFormErrors = (errors, exclude) => {
  // Local State
  const [errorMessage, setErrorMessage] = useState("");
  const cloneErrorsObject = { ...errors };

  if (typeof exclude === "string") {
    delete cloneErrorsObject[exclude];
  }

  if (Array.isArray(exclude)) {
    exclude?.map((ele) => (errors[ele] ? delete cloneErrorsObject[ele] : null));
  }

  const amountOfErrors = Object.getOwnPropertyNames(cloneErrorsObject).length;

  // Watchers
  useEffect(() => {
    (amountOfErrors > 1)
      ? setErrorMessage(MULTIPLE_FORM_ERROR_MESSAGE)
      : setErrorMessage("");
  }, [amountOfErrors]);

  return { errorMessage };
};
