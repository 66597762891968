import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Button } from "../Buttons/Button";

/**
 * Modal component with title, content and call to action buttons.
 *
 * @component
 * @param {Object} props Input props
 * @param {boolean} props.open Opens/closes modal.
 * @param {function} props.close Function called when modal is closed.
 * @param {Object} props.messages Object containing title and content of modal.
 * @param {Object} props.buttons Object containing button props - children, onClick function etc.
 * @param {JSX.Element} [props.children]
 * @return {JSX.Element}
 */

export const Modal = (props) => {
  const labelled = "alert-dialog-title";
  const described = "alert-dialog-description";

  const primary = props?.buttons?.primary || props?.primary;
  const secondary = props?.buttons?.secondary || props?.secondary;
  const checkForButtons = primary || secondary;

  const title = props?.messages?.title || props?.title;
  const content = props?.messages?.content || props?.content;

  return (
    <Dialog
      aria-describedby={props.described}
      aria-labelledby={props.labelled}
      data-cy="modal"
      open={props.open}
      onClose={props.close}
      role="alertdialog"
      PaperProps={{
        style: {
          display: "flex",
          flexDirection: "column",
          padding: "1.5rem",
          ...props.sx
        }
      }}
    >
      {
        title
          ?
          <DialogTitle
            component="h2"
            variant="h3"
            id={labelled}
            sx={{ padding: "0px" }}
          >
            {title}
          </DialogTitle>
          : null
      }
      {
        content
          ?
          <DialogContent sx={{ padding: "0px" }}>
            <DialogContentText id={described}>
              {content}
            </DialogContentText>
          </DialogContent>
          : null
      }
      {
        props.children
          ? <DialogContent>{props.children}</DialogContent>
          : null
      }
      {
        checkForButtons
          ?
          <DialogActions
            disableSpacing={true}
            sx={
              {
                display: "flex",
                flexDirection: "row-reverse",
                gap: "1rem",
                margin: "0px",
                marginTop: "1.5rem",
                padding: "0px"
              }
            }
          >
            {
              primary
                ? <Button
                  color="primary"
                  data-cy="primaryButton"
                  fullWidth
                  size="large"
                  variant="contained"
                  {...primary}
                />
                : null
            }
            {
              secondary
                ? <Button
                  color="secondary"
                  data-cy="secondaryButton"
                  fullWidth
                  size="large"
                  variant="contained"
                  {...secondary}
                />
                : null
            }
          </DialogActions>
          : null
      }
    </Dialog >
  );
};
