import React from "react";
import { Tab as MUITab } from "@mui/material";

/**
 * Wrapper component for Material UI Tab
 *
 * @component
 * @param {Object} props Props defined by Material UI. Please see Material UI documentation for
 * API:
 *
 * - [Tab API](https://mui.com/material-ui/api/tab/)
 * @return {JSX.Element}
 */

export const Tab = (props) => (
  <MUITab
    data-cy="tab"
    {...props}
  >
    {props.children}
  </MUITab>
);
