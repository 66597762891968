import React from "react";
import { TextField } from "@mui/material";
import { Box, Typography } from "../../../../components/mui";
import { schema } from "../../../../utils/validators/formHookSchema";
import { sectionStyle } from "../styles";

const UserInfo = ({
  formErrors,
  register,
  reduxForm,
  dispatch,
  uniqueIdLookupEnabled
}) => {
  return (
    <Box component="fieldset" sx={sectionStyle} gridArea="left-col">
      <Typography variant="h2">User Information</Typography>
      {uniqueIdLookupEnabled ? <span>&nbsp;</span> : null}
      <TextField {...schema.firstName.properties(formErrors, register)} />
      <TextField {...schema.lastName.properties(formErrors, register)} />
      <TextField {...schema.email.properties(formErrors, register, reduxForm?.emailAddress, dispatch)} />
    </Box>
  );
};

export default UserInfo;
