import React, { useEffect, useRef, useState } from "react";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components";
import { Typography, Grid, Divider, Alert } from "../../components/mui";
import { ButtonElement } from "../../services/sdk/components/ButtonElement.js";
import { deleteSavedAccount, setProfileError, clearDeleteAccountErrors, getSavedAccounts } from "../../store/savedAccounts/savedAccounts.slice";
import FinicityService from "../Payment/MakePayment/ach/finicity/FinicityService";
import AchModals from "../Payment/MakePayment/ach/modals";
import { SavedAccountCard } from "./SavedAccountCard";

export const ProfileSavedAccounts = () => {
  // Named selectors
  const authState = (state) => state.auth;
  const savedAccountsState = (state) => state.savedAccounts;
  const institutionState = (state) => state.institution;
  const loansState = (state) => state.loans;

  // Redux State
  const { authToken, user, flow } = useSelector(authState);
  const { getAccounts, saveAccount, deleteAccount } = useSelector(savedAccountsState);
  const { config, details } = useSelector(institutionState);
  const { list } = useSelector(loansState);

  // Local State
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldShowError, setShouldShowError] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [deleteLoadingSpinner, setDeleteLoadingSpinner] = useState(false);

  // Hooks
  const { setValue } = useForm();
  const finicityRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const finicityContainerRef = useRef();

  useEffect(() => {
    if (getAccounts?.response?.found === undefined) {
      dispatch(getSavedAccounts(authToken.value));
    }
  }, [getAccounts?.response?.found, authToken.value, dispatch]);

  const SetModalClosed = (route) => {
    setIsModalOpen(false);
    setIsButtonDisabled(false);
    if (route) navigate(route);
  };

  const savedAccounts = getAccounts?.response?.results || [];

  const handleDeleteAccount = (account) => {
    dispatch(clearDeleteAccountErrors());

    const activeLoans = list.filter(loan => (loan.autopay?.account_id.includes(account?.id)));
    setAccountId(account?.id);

    setDeleteLoadingSpinner(true);
    if (activeLoans.length) {
      const linkedLoans = activeLoans.map((loan) => (`Ending in ${loan.number.slice(-4)}`));
      setTimeout(() => {
        dispatch(setProfileError({
          severity: "error",
          message: `Cannot delete, account is used for autopay loans: [${linkedLoans.join(", ")}]`
        }));
        setDeleteLoadingSpinner(false);
      }, 1500);
    } else {
      dispatch(deleteSavedAccount({ id: account.id, token: authToken.value }));
      setDeleteLoadingSpinner(false);
    }
  };
  return (
    <div
      style={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        paddingTop: "1rem"
      }}
    >
      {/* Finicity Service */}


      {/* title */}
      <Typography variant="h2" component="h2">Saved Accounts</Typography>

      {/* listed accounts */}
      <Stack
        divider={<Divider sx={{ width: "100%", margin: "auto" }} aria-hidden="true" />}
        sx={{ margin: "0px", padding: "0px", gap: "1rem", display: "grid" }}
      >
        {
          getAccounts?.response?.found === 0
            ? <Alert message="No saved accounts have been created" severity="info" />
            :  null
        }
        {
          getAccounts?.response?.results === undefined
            ? <Alert message="Unable to display your saved accounts at this time" severity="warning" />
            : null
        }
        {
          savedAccounts?.map((account, i) => (
            <SavedAccountCard
              key={i}
              account={account}
              accountId={accountId}
              deleteAccount={deleteAccount}
              deleteLoadingSpinner={deleteLoadingSpinner}
              handleDeleteAccount={handleDeleteAccount}
            />
          ))
        }
      </Stack>
      { savedAccounts?.length > 0 ? <Divider sx={{ width: "100%", margin: "auto" }} aria-hidden="true" /> : null }

      {/* add account button */}
      <Grid item xs={12} container spacing={1} direction="row-reverse">
        <Grid item xs={12} md={6}>
          {
            details?.services?.sdk?.enhanced
              ? <ButtonElement />
              :
              <>
                <div id="swbc-consumer-experience" tabIndex="-1" ref={finicityContainerRef} />
                <FinicityService
                  details={details}
                  flow={flow}
                  nameOnAccount={`${user.first_name} ${user.last_name}`}
                  ref={finicityRef}
                  setValue={setValue}
                  setIsButtonDisabled={setIsButtonDisabled}
                  setIsModalOpen={setIsModalOpen}
                  setShouldShowError={setShouldShowError}
                  token={authToken.value}
                  user={user}
                  entry="profile"
                  achWebIframe={finicityContainerRef}
                />

                <AchModals
                  shouldShowError={shouldShowError}
                  isModalOpen={isModalOpen}
                  setModalClosed={SetModalClosed}
                  config={config}
                  entry="profile"
                />
                <Button
                  color="secondary"
                  disabled={isButtonDisabled || getAccounts.isLoading || saveAccount.isSubmitting}
                  loading={isButtonDisabled || getAccounts.isLoading || saveAccount.isSubmitting}
                  onClick={() => finicityRef.current.openFinicityFlow()}
                >
                CONNECT YOUR BANK
                </Button>
              </>
          }
        </Grid>
      </Grid>
    </div>
  );
};
