import React from "react";
import { Alert, AlertTitle } from "../../components";

const SsoTimeout = () => {
  return (
    <Alert subTitle="You&apos;re now logged out. Please reopen this dialogue from within your banking application to log in." severity="error">
      <AlertTitle>Session timed out due to inactivity.</AlertTitle>
    </Alert>
  );
};

export default SsoTimeout;
