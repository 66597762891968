import React from "react";
import { Divider as MUIDivider } from "@mui/material";

/**
 * Wrapper component for Material UI Divider
 *
 * @component
 * @param {Object} props Props defined by Material UI. Please see Material UI documentation for details
 * API:
 *
 * - [Checkbox API](https://mui.com/material-ui/api/divider/)
 * @return {JSX.Element}
 */

export const Divider = (props) => {

  return (
    <MUIDivider
      data-cy="divider"
      {...props}
    >
    </MUIDivider>
  );
};
