import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  ${props => props.materialTheme.breakpoints.up("md")} {
    flex-direction: row-reverse;
    gap: 1rem;
  }
`;
