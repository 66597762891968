import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const useViewPort = () => {
  const theme = useTheme();
  return {
    desktop: useMediaQuery(theme.breakpoints.up("lg")),
    medium: useMediaQuery(theme.breakpoints.up("md")),
    tablet: useMediaQuery(theme.breakpoints.up("sm")),
    mobile: useMediaQuery(theme.breakpoints.up("xs"))
  };
};
