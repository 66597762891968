export const swivelColors = {
  brand_color: "#510C76",
  brand_logo_url: "https://s3.amazonaws.com/bacon-weblet.development.assets.us-east-1/Swivel_logo_RGB.png",
  link_color: "#009CA6",
  primary_button_background_color: "#510C76",
  primary_button_color: "#510C76",
  primary_button_color_focus: "#C017A2",
  primary_button_color_hover: "#C017A2",
  primary_button_font_color: "#FFFFFF",
  secondary_button_background_color: "#C017A2",
  secondary_button_font_color: "#FFFFFF",
  tertiary_button_background_color: "#5AC5CB",
  tertiary_button_font_color: "#FFFFFF"
};
