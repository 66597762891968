import React from "react";
import { SvgIcon as MUISvgIcon } from "@mui/material";

/**
 * Wrapper component for Material UI SvgIcon
 *
 * @component
 * @param {object} props Props defined by Material UI. Please see Material UI documentation for
 * API:
 * - [SvgIcon API](https://mui.com/material-ui/api/svg-icon/)
 * @return {JSX.Element}
 */

export const SvgIcon = (props) => {
  return (
    <MUISvgIcon
      data-cy="svgicon"
      {...props}
    >
      {props.children}
    </MUISvgIcon>
  );
};
