import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  previousLocations: [],
  currentLocation: null,
  errorPageLoaded: false
};

export const navHistorySlice = createSlice({
  name: "navHistory",
  initialState,
  reducers: {
    setCurrentLocation: (state, action) => {
      if (!action?.payload?.route) {
        return;
      }

      const { payload } = action;
      if (!state?.currentLocation) {
        state.currentLocation = payload;
        return;
      }

      const isSameLocation = state.currentLocation?.route === payload?.route;
      if (state.currentLocation !== null && !isSameLocation) {
        state.previousLocations.push(state.currentLocation);
      }

      state.currentLocation = payload;
    },
    setErrorPageLoaded: (state, action) => {
      state.errorPageLoaded = action?.payload || false;
    },
    handleBackNavigation: (state) => {
      if (state.previousLocations.length > 0) {
        state.currentLocation = state.previousLocations.pop();
      }
    },
    addBreadCrumb: (state, action) => {
      state.previousLocations.push(action.payload);
    },
    clearNavHistory: (state) => {
      state.previousLocations = [];
    },
    jumpToNavRoute: (state, action) => {
      while (state.previousLocations.length > 0) {
        const lastItem = state.previousLocations[state.previousLocations.length - 1];
        if (action.payload.find(x => x.toLowerCase() === lastItem.route.toLowerCase())) {
          setCurrentLocation(lastItem);
          break;
        } else {
          state.previousLocations.pop();
        }
      }
    },
    resetNavState: () => initialState
  }
});

export const {
  setCurrentLocation,
  setErrorPageLoaded,
  handleBackNavigation,
  addBreadCrumb,
  clearNavHistory,
  jumpToNavRoute,
  resetNavState
} = navHistorySlice.actions;
export default navHistorySlice.reducer;
