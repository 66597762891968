import { useRef, useEffect } from "react";
import { APP_NAME, TIMEOUT_MODAL_TITLE } from "../constants/constants";

const useDocumentTitle = (title, prevailOnUnmount = false) => {

  const titlePrefix = `${APP_NAME} | ${title}`;
  const defaultTitle = useRef(document.title);
  const checkTitle = title !== TIMEOUT_MODAL_TITLE;

  useEffect(() => {
    if (checkTitle) {
      document.title = titlePrefix;
    }
  }, [checkTitle, titlePrefix]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [prevailOnUnmount]);
};

export default useDocumentTitle;
