import React from "react";
import { CreatePasswordForm } from "@swbc/swivel-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert } from "../../components";
import Title from "../../components/Title";
import { useGoogleAnalytics } from "../../services/analytics/hooks/useGoogleAnalytics";
import { postNonValidationRegister, deleteAuthToken } from "../../store/auth/auth.slice";
import { getInstitutionDetails } from "../../store/institution/institution.slice";
import { resetNavState, setCurrentLocation } from "../../store/navhistory/navhistory.slice";
import { routeMap } from "../../utils/data/routeMap";

const NonValidationCreatePassword = () => {
  // * Named selectors
  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;

  // * Use named selectors
  const { isInstitutionDetailsLoading } = useSelector(institutionState);
  const { register: { nonValidation } } = useSelector(authState);

  // * Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categories, actions, trackEvent } = useGoogleAnalytics();

  const successfulRegistrationBackLink = () => {
    const routeItem = routeMap.get("/login");
    const dataForCurrentLocation = {
      title: routeItem.title,
      route: routeItem.route
    };
    dispatch(setCurrentLocation(dataForCurrentLocation));
  };

  const completeRegistration = (form) => {
    const payload = { ...nonValidation.payload, password: form?.data?.password };

    dispatch(postNonValidationRegister(payload))
      .unwrap()
      .then((response) => {
        trackEvent(actions.NON_VALIDATION_REGISTRATION, categories.ATTEMPT_SUCCESS);
        response.institutionId = payload.institution_id;
        dispatch(getInstitutionDetails(response))
          .unwrap()
          .then(() => {
            dispatch(resetNavState());
            successfulRegistrationBackLink();
            navigate("/success/registration");
          })
          .catch(() => {
            dispatch(deleteAuthToken());
          });
      })
      .catch(() => {
        trackEvent(actions.NON_VALIDATION_REGISTRATION, categories.ATTEMPT_FAILURE);
      });
  };

  return (
    <>
      <Title title="Create Password" />
      <Alert message={nonValidation?.error?.response} severity={nonValidation?.error?.severity} />
      <section>
        <CreatePasswordForm
          disableGutters={true}
          onSubmit={completeRegistration}
          isFullWidth={false}
          isLoading={nonValidation.isSubmitting || isInstitutionDetailsLoading}
        />
      </section>
    </>
  );
};

export default NonValidationCreatePassword;
