import React from "react";
import { AlertTitle as MUIAlertTitle } from "@mui/material";

/**
 * Wrapper component for Material UI AlertTitle
 *
 * @component
 * @param {Object} props Props defined by Material UI. Please see Material UI documentation for
 * API:
 *
 * - [AlertTitle API](https://mui.com/material-ui/api/alert-title/)
 * @return {JSX.Element}
 */

export const AlertTitle = (props) => (
  <MUIAlertTitle
    data-cy="alertTitle"
    {...props}
  >
    {props.children}
  </MUIAlertTitle>
);
