import React from "react";
import { Stack } from "@mui/material";
import { Typography } from "../../../../components";

const InfoRow = ({ leftSide, rightSide }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      {leftSide && <Typography variant="body1" component="p">{leftSide}</Typography>}
      {rightSide && <Typography variant="body1" component="p">{rightSide}</Typography>}
    </Stack>
  );
};

export default InfoRow;
