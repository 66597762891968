const initialState = {
  manuallyLinkFlow: null,
  error: false,
  isSubmitting: false,
  paymentType: "",
  loan: null,
  isPaymentSuccessful: false,
  pendingTransactions: [],
  formErrorMessage: "",
  warningMessage: "",
  loanType: "",
  stateOfResidence: {
    isConfirmed: false,
    state: ""
  },
  form: {
    type: "card",
    fullName: "",
    firstName: "",
    lastName: "",
    amount: "",
    cardNumber: {
      raw: "",
      userDisplay: ""
    },
    expirationDate: "",
    securityCode: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    cardBrand: "",
    convFee: 0
  },
  idempotency: {
    isSubmitting: false,
    token: "",
    error: ""
  },
  paymentDetails: { accountType: "" },
  history: {
    isLoading: false,
    response: null,
    error: null
  },
  autopay: {
    payFromDetails: null,
    isSubmitting: false,
    response: null,
    error: null
  },
  fee: {
    isLoading: false,
    response: null,
    error: null
  },
  isFinicityFlow: true,
  isAchVerificationComplete: false
};

export default initialState;
