import React from "react";
import { List as MUIList } from "@mui/material";

/**
 * Wrapper component for Material UI List
 *
 * @component
 * @param {object} props Props defined by Material UI. Please see Material UI documentation for
 * API:
 * - [List API](https://mui.com/material-ui/api/list/)
 * @return {JSX.Element}
 */

export const List = (props) => {
  return (
    <MUIList
      data-cy="list"
      {...props}
    >
      {props.children}
    </MUIList>
  );
};
