import React from "react";
import Title from "../../components/Title";

const MaintenanceWindow = ({ title, subtitle }) => {
  return (
    <Title title={title} subTitle={subtitle} showDivider={false} isCentered />
  );
};

export default MaintenanceWindow;
