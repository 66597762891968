import styled from "styled-components";

export const Container = styled.div`
  margin-top: 1.5rem;
`;

export const ButtonContainer = styled.div`
display: grid;
gap: 2rem;
grid-template-columns: 1fr;
grid-template-areas:
  "left-col"
  "right-col"
  "button";

${props => props.materialTheme.breakpoints.up("md")} {
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "left-col right-col"
    ". button";
}
`;
