import styled from "styled-components";

export const LinksContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const IconLink = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  color: #333333;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    box-shadow: 0 0 11px rgba(33,33,33,.2);
    border-radius: 8px;
  }

  &:focus-visible {
    box-shadow: 0 0 11px rgba(33,33,33,.2);
  }
`;

export const secondaryText = {
  display: "inline-block",
  marginLeft: "auto",
  minWidth: "5ch",
  overflow: "hidden",
  textAlign: "right",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
};
