import React, { useEffect } from "react";
import { useTheme } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Box, Button, Link } from "../../../components/mui";
import { useTransactionContext } from "../../../context";
import { addBreadCrumb } from "../../../store/navhistory/navhistory.slice";
import { clearLoanState } from "../../../store/payment/makePayment.slice";
import { EXPRESS_PAY } from "../../../utils/constants/constants";
import { routeMap } from "../../../utils/data/routeMap";
import { dailyCutoffTimeFormat } from "../../../utils/helpers/dayjsHelpers";
import EmailIcon from "../../assets/email.svg";
import PhoneIcon from "../../assets/phone.svg";
import SuccessIcon from "../../assets/success.svg";
import { PaymentConfirmedWrapper } from "./index.styles";

const PaymentConfirmedPage = () => {

  const materialTheme = useTheme();
  const contactStyle = { textDecoration: "none", marginLeft: "0.5rem", color: "#003DA5" };

  const { paymentConfirmation } = useTransactionContext();

  const navHistoryState = (state) => state.navHistory;
  const institutionState = (state) => state.institution;
  const authState = (state) => state.auth;

  const dispatch = useDispatch();

  const { currentLocation } = useSelector(navHistoryState);

  const { config, details } = useSelector(institutionState);
  const { user, flow } = useSelector(authState);

  useEffect(() => {
    if (currentLocation.route.toLowerCase() === "/payment-confirmed") {
      const routeItem = routeMap.get("/accounts");
      const navObj = {
        title: routeItem.title,
        route: routeItem.route,
        time: Date.now()
      };
      dispatch(addBreadCrumb(navObj));
    }
  }, [currentLocation]);

  useEffect(() => {
    dispatch(clearLoanState());
  }, []);

  return (
    <>
      <PaymentConfirmedWrapper materialTheme={materialTheme}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
          <section>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "1.5rem" }}>
              <img src={SuccessIcon} alt="success icon" />
            </Box>
            <Typography sx={{ textAlign: "center", marginBottom: "0.75rem" }} variant="h2" component="h2">
              Thank You! <br />
              Payment Sent Successfully
            </Typography>
            <Typography sx={{ textAlign: "center", marginBottom: "2.5rem" }} variant="body1" component="p">
              Your payment was submitted on: <br />
              {paymentConfirmation.time} <br />
              Confirmation # <b>{paymentConfirmation.number}</b>
            </Typography>
          </section>
          <section>
            <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "2.5rem" }}>
              <Typography variant="body1" sx={{ textAlign: "center" }} component="p">
                A payment confirmation email has been sent to {user?.email_address}.<br />
                Please retain the email as receipt of your payment.
              </Typography>
            </Box>
          </section>
          <section>
            <Typography variant="body1" component="p" sx={{ marginBottom: "1.5rem" }}>
              Payments submitted after {dailyCutoffTimeFormat(details?.daily_cutoff_time)}
              {" "}Central Time will be processed within 3-5 business days.
              If any questions or concerns, contact us
            </Typography>
            {details?.brand_phone &&
              <Box sx={{ display: "flex" }}>
                <img src={PhoneIcon} alt="phone" />
                <Link href={`tel:${details?.brand_phone}`} sx={contactStyle}>{details?.brand_phone}</Link>
              </Box>
            }
            {details?.brand_email &&
              <Box sx={{ display: "flex" }}>
                <img src={EmailIcon} alt="email" />
                <Link href={`mailto:${details?.brand_email}`} sx={contactStyle}>{details?.brand_email}</Link>
              </Box>
            }
          </section>
          {(flow === EXPRESS_PAY && config?.express_pay_only === false) &&
            <Box sx={{ width: "100%", marginTop: "1.5rem" }}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                size="large"
                component={RouterLink}
                role="button"
                to={"/register/guest"}
              >
                Register
              </Button>
            </Box>
          }
        </Box>
      </PaymentConfirmedWrapper>
    </>
  );
};

export default PaymentConfirmedPage;
