import React, { useState } from "react";
import { Button } from "@mui/material";
import { Link, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "../mui";

const DomainRedirectDialog = ({ brand, url, message }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Link href={url} rel="noopener noreferrer" target="_blank" onClick={handleClickOpen}>{url}</Link>
      <Dialog aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" role="alertdialog" open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">{`You Are Leaving ${brand}'s Website`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" size="large" variant="contained" onClick={handleClose}>No Thanks</Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            href={url}
            onClick={handleClose}
            rel="noopener noreferrer"
            target="_blank"
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DomainRedirectDialog;
