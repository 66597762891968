import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { Grid } from "../../../../components/mui";
import { cardFormValidators } from "../../../../utils/validators/CardFormValidators";

const ManualLinkDisplay = (props) => {
  const { register, errors, setValue, nameOnAccount } = props;

  const manualLinkState = (state) => state.achDetails;
  const { manuallyLinkForm: { bankingDetails } } = useSelector(manualLinkState);

  useEffect(() => {
    setValue("accountNumber", bankingDetails?.accountNumber);
    setValue("routingNumber", bankingDetails?.routingNumber);
    setValue("accountType", bankingDetails?.accountType);
    setValue("type", bankingDetails?.type);
    setValue("nameOnAccount", bankingDetails?.nameOnAccount || nameOnAccount);
  }, []);

  const nameOnAccountOptions = {
    required: "Account name is required",
    pattern: {
      value: cardFormValidators.fullName.value,
      message: cardFormValidators.fullName.message
    }
  };

  const routingNumberOptions = {
    required: "Routing number is required",
    minlength: 9,
    pattern: {
      value: /^\d*$/,
      message: "Routing number must be numeric"
    }
  };

  const accountNumberOptions = {
    required: "Account number is required",
    pattern: {
      value: /^\d*$/,
      message: "Account number must be numeric"
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <TextField
          {...register("nameOnAccount", nameOnAccountOptions)}
          autoComplete="off"
          error={!!errors?.nameOnAccount}
          fullWidth
          helperText={errors?.nameOnAccount ? errors.nameOnAccount.message : " "}
          id="nameOnAccount-input"
          inputProps={{ "aria-required": "true" }}
          label="Name on Account"
          required
          sx={{ zIndex: 0 }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          {...register("routingNumber", routingNumberOptions)}
          autoComplete="off"
          error={!!errors?.routingNumber}
          fullWidth
          helperText={errors?.routingNumber ? errors.routingNumber.message : " "}
          id="routingNumber-input"
          inputProps={{ "aria-required": "true" }}
          label="Routing number"
          required
          disabled
          value={bankingDetails?.routingNumber}
          sx={{ zIndex: 0 }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          {...register("accountNumber", accountNumberOptions)}
          autoComplete="off"
          error={!!errors?.accountNumber}
          fullWidth
          helperText={errors?.accountNumber ? errors.accountNumber.message : " "}
          id="accountNumber-input"
          inputProps={{ "aria-required": "true" }}
          label="Account Number"
          required
          sx={{ zIndex: 0 }}
        />
      </Grid>
    </>
  );
};

export default ManualLinkDisplay;
