import React from "react";
import { visuallyHidden } from "@mui/utils";

/**
 * Navigation Announcer is a visually hidden component, read by screen readers.
 * @component
 * @param   {string} modalName Name of the navigation announcer.
 * @return  {JSX.Element} Visually hidden navigation announcer.
 */
const NavigationAnnouncer = ({ currentPageName }) => (
  <output data-cy="announcer" style={visuallyHidden} aria-live="polite" aria-atomic="true">
    {
      currentPageName && `Navigated to ${currentPageName} page.`
    }
  </output>
);

export default NavigationAnnouncer;
