import React from "react";
import { useDispatch } from "react-redux";
import { Modal } from "../../../../../components";
import { setManuallyLinkFlow } from "../../../../../store/payment/makePayment.slice";
import locations from "../../../../../utils/data/finicityACHLocations.json";

const AchModals = (props) => {
  const dispatch = useDispatch();
  const { shouldShowError, isModalOpen, setModalClosed, config } = props;

  const handleManuallyLink = () => {
    dispatch(setManuallyLinkFlow(locations[props?.entry]));
    setModalClosed("/link-bank-account");
  };

  return (
    <>
      <Modal
        open={shouldShowError}
        messages={{ title: "Redirecting to manually link your bank account" }}
      />

      <Modal
        open={isModalOpen}
        close={setModalClosed}
        messages={{
          title: "Sorry, we were not able to find your financial institution",
          content:
            `The financial institution that you were looking to link to your profile is not in the network.
          Please select another payment method or manually link a bank account.
          Otherwise, please contact us ${config?.brand_phone ? `at ${config?.brand_phone}` : ""} for further guidance.`
        }}
        buttons={{
          primary: {
            children: "Link Bank Account",
            onClick: handleManuallyLink
          },
          secondary: {
            children: locations[props.entry].modal,
            onClick: () => setModalClosed(locations[props.entry].route)
          }
        }}
      />
    </>
  );
};

export default AchModals;
