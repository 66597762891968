const ACHWebVerification = {
  "TYPE": "ACHWebVerification",
  "HANDLER": "setACHWebVerificationHandler",
  "START": "startACHWebVerification",
  "ACCOUNT_ID": "ACHWebVerificationAccountId",
  "CLOSE_FLOW": "closeACHWebFlow"
};

export const experiences = {
  "VERIFY_ACH_ENHANCED_SINGLE_ACCOUNT": ACHWebVerification,
  "VERIFY_ACH_ENHANCED_MULTIPLE_ACCOUNTS": ACHWebVerification,
  "VERIFY_ACH_MANUAL_ACCOUNT": ACHWebVerification
};
