import styled from "styled-components";


export const ContentWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
`;

export const Container = styled.div`
  box-sizing: border-box;
  height: ${props => props.height};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TargetElement = styled.div`
  height: ${props => props.height};
`;

export const ButtonWrapper = styled.div`
  .connect-bank-button {
    grid-area: button;
    box-sizing: border-box;
  }

  margin-top: 4rem;
  box-sizing: border-box;
  display: grid;
  width: 100%;

  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "button"
  ;

  ${props => props.materialTheme.breakpoints.up("md")} {
    column-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      ". button"
    ;
  }
`;

export const SuccessWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  gap: 1rem;
`;
