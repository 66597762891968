import states from "../data/states.json";

export const constructCardPayload = (form, pendingTransactions, loanId, token, idempotencyToken, paymentDetails, config) => {
  const {
    type,
    firstName,
    lastName,
    cardNumber,
    expirationDate,
    securityCode,
    amount,
    street,
    city,
    state,
    zipCode,
    convFee
  } = form;

  const stateAbbreviation = states.find(s => s.longName === state)?.name;
  const stateOfResidenceAbbreviation = states.find(state => state.longName === paymentDetails?.stateOfResidenceCardDropdown)?.name;

  return {
    token,
    type,
    known_pending_transaction_numbers: pendingTransactions.map((trans) => trans.TrackingNumber),
    loans: [{
      amount: Number(amount),
      id: loanId
    }],
    card_details: {
      first_name: firstName,
      last_name: lastName,
      card_number: cardNumber.raw,
      expiration_date: expirationDate,
      security_code: securityCode,
      billing_address: {
        street,
        city,
        state: stateAbbreviation,
        zip_code: zipCode
      }
    },
    request_token: idempotencyToken,
    state_of_residence: stateOfResidenceAbbreviation,
    fee: convFee,
    fee_source: paymentDetails.feeSource,
    attribution: null,
    brandDetails: {
      brand_phone: config?.brand_phone
    }
  };
};
