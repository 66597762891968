import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Stack } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { Button, Icon } from "../../components";
import { Typography, SvgIcon, Grid } from "../../components/mui";
import { swivelColors } from "../../utils/data/swivelColors";
import { formatAccount } from "../../utils/helpers/commonHelpers";
import { useViewPort } from "../../utils/hooks/useViewPort";

export const SavedAccountCard = (props) => {
  // Named selectors
  const institutionState = (state) => state.institution;
  const savedAccountsState = (state) => state.savedAccounts;

  // Redux State
  const { config } = useSelector(institutionState);
  const { deleteAccount } = useSelector(savedAccountsState);

  // Hooks
  const viewPort = useViewPort();
  const { swivelize } = useFlags();

  return (
    <Grid
      container
      wrap="nowrap"
      direction={viewPort.medium ? "row" : "column"}
      sx={{ display: "flex", gap: 2, width: "100%" }}
    >
      <Stack sx={{ paddingInline: viewPort.medium ? "1rem" : 0, justifyContent: "center" }}>
        <Icon type="banking" color={swivelize ? swivelColors.primary_button_color : config?.primary_button_color} />
      </Stack>

      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center"
        }}
      >
        <Typography
          variant="body1"
          component="h2"
        >
          {formatAccount(props?.account?.last_4, props?.account?.banking_details?.name_of_bank)}
        </Typography>
      </Grid>

      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          gap: 2,
          width: viewPort.medium ? "25%" : "100%",
          alignSelf: "center"
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size={viewPort.medium ? "medium" : "small"}
          startIcon={<SvgIcon component={DeleteForeverIcon}></SvgIcon>}
          disabled={
            props?.accountId === props?.account.id ? props?.deleteAccount.isSubmitting
            || props?.deleteLoadingSpinner : null
            || deleteAccount.isSubmitting
          }
          loading={
            props?.accountId === props?.account.id ? props?.deleteAccount.isSubmitting
            || props?.deleteLoadingSpinner : null
          }
          onClick={() => props?.handleDeleteAccount(props?.account)}
        >
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};
