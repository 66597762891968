import initialState from "./initialState";

const actions = {
  setIsPaymentSuccessful: (state, action) => { state.isPaymentSuccessful = action.payload; },
  setLoan: (state, action) => { state.loan = action.payload; },
  setCardBrand: (state, action) => { state.form.cardBrand = action.payload; },
  setExpirationDate: (state, action) => { state.form.expirationDate = action.payload; },
  setSecurityCode: (state, action) => { state.form.securityCode = action.payload; },
  setPaymentAmount: (state, action) => { state.form.amount = action.payload; },
  setStreet: (state, action) => { state.form.street = action.payload; },
  setCity: (state, action) => { state.form.city = action.payload; },
  setState: (state, action) => { state.form.state = action.payload; },
  setZipCode: (state, action) => { state.form.zipCode = action.payload; },
  setConvFee: (state, action) => { state.form.convFee = action.payload; },
  setTotal: (state, action) => { state.form.total = action.payload; },
  setConfirmationNum: (state, action) => { state.confirmationNum = action.payload; },
  setSubmitting: (state, action) => { state.isSubmitting = action.payload; },
  setPaymentType: (state, action) => { state.paymentType = action.payload; },
  setIsFinicityFlow: (state, action) => { state.isFinicityFlow = action.payload; },
  setIsAchVerificationComplete: (state, action) => { state.isAchVerificationComplete = action.payload; },
  setPendingTransactions: (state, action) => { state.pendingTransactions = action?.payload; },
  setFormErrorMessage: (state, action) => { state.formErrorMessage = action.payload; },
  setWarningMessage: (state, action) => { state.warningMessage = action.payload; },
  setLoanPaymentType: (state, action) => { state.loanType = action.payload; },
  setAutoPayDetails: (state, action) => { state.autopay.payFromDetails = action.payload; },
  setIsStateOfResidenceConfirmed: (state, action) => { state.stateOfResidence.isConfirmed = action.payload; },
  setStateOfResidence: (state, action) => { state.stateOfResidence.state = action.payload; },
  clearPaymentState: () => initialState,
  resetAchVerificationState: (state) => {
    state.isFinicityFlow = true;
    state.isAchVerificationComplete = false;
  },
  clearPaymentDetails: (state) => { state.paymentDetails = initialState.paymentDetails; },
  clearCardForm: (state) => { state.form = initialState.form; },
  clearPaymentForm: (state) => {
    state.form = initialState.form;
  },
  setName: (state, action) => {
    state.form.fullName = action.payload;
    if (!state.form.fullName.includes(" ")) {
      return;
    }
    const strTokens = state.form.fullName.split(" ");
    const [name] = strTokens;
    state.form.firstName = name;
    if (strTokens.length > 1) {
      strTokens.shift();
      state.form.lastName = strTokens.join(" ");
    }
  },
  setCardNumbers: (state, action) => {
    state.form.cardNumber.raw = action.payload.raw;
    state.form.cardNumber.userDisplay = action.payload.userDisplay;
  },
  setPaymentDetails: (state, action) => {
    delete action.payload.paymentType;
    state.paymentDetails = action.payload;
  },
  setManuallyLinkFlow: (state, { payload }) => {
    state.manuallyLinkFlow = payload;
  },
  setPaymentDate: (state, { payload }) => {
    state.paymentDetails.date = payload;
  },
  clearIdempotency: (state) => {
    state.idempotency = initialState.idempotency;
  },
  clearLoanState: (state) => {
    state.loan = initialState.loan;
  }
};

export default actions;
