import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { schema } from "../../utils/validators/formHookSchema";
import { IconButton } from "../mui";

const PasswordComponent = ({ formErrors, register }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <TextField
      {...schema.password.properties(formErrors, register, isPasswordVisible)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              onMouseDown={(e) => e.preventDefault()}
            >
              {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default PasswordComponent;
