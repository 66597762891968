import React from "react";
import { DialogContentText as MUIDialogContentText } from "@mui/material";

/**
 * Wrapper component for Material UI DialogContentText
 *
 * @component
 * @param {Object} props Props defined by Material UI. Please see Material UI documentation for details
 * API:
 *
 * - [DialogContentText API](https://mui.com/material-ui/api/dialog-content-text/)
 * @return {JSX.Element}
 */

export const DialogContentText = (props) => {

  return (
    <MUIDialogContentText
      data-cy="dialogContentText"
      {...props}
    >
      {props.children}
    </MUIDialogContentText>
  );
};
