import React from "react";
import { Box, TextField } from "@mui/material";
import { Typography } from "../../../../components/mui";
import { schema } from "../../../../utils/validators/formHookSchema";
import { sectionStyle } from "../styles";

const AccountDetails = ({
  formErrors,
  register,
  config
}) => {
  return (
    <Box component="fieldset" sx={sectionStyle} gridArea="right-col">
      <Typography variant="h2" noWrap>Security Questions</Typography>
      {
        config?.no_validation_file?.member_id?.shown
          ?
          <TextField {...schema.memberId.properties(formErrors, register, config)} />
          :
          null
      }
      {
        config?.no_validation_file?.registration_fields?.map((field, index) => {
          return (
            <TextField key={index} {...schema.nonValidationField.properties(formErrors, register, { ...field, index })} />
          );
        })
      }
    </Box>
  );
};

export default AccountDetails;
