import React, { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, InputAdornment, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Box, BulletList, IconButton, Grid } from "../../components";
import { StyledCircularProgress } from "../../components/styled/StyledCircularProgress";
import Title from "../../components/Title";
import { useGoogleAnalytics } from "../../services/analytics/hooks/useGoogleAnalytics";
import { postCreatePassword, postResetPassword } from "../../store/auth/auth.slice";
import { resetNavState, setCurrentLocation } from "../../store/navhistory/navhistory.slice";
import * as Constants from "../../utils/constants/constants";
import { getUserFriendlyResponse } from "../../utils/data/resetPasswordResponses";
import { routeMap } from "../../utils/data/routeMap";
import { constructCreatePassword } from "../../utils/helpers/constructRegisterPayload";
import { schema } from "../../utils/validators/formHookSchema";
import { validate } from "../../utils/validators/validators";

const CreatePassword = ({ isResetPasswordFlow = false, urlToken = null }) => {
  // * Named selectors
  const authState = (state) => state.auth;

  // * Use named selectors
  const { user, authToken, promote, resetPassword } = useSelector(authState);

  // * Hooks
  const { state } = useLocation();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    getValues,
    clearErrors
  } = useForm({ mode: "onBlur", defaultValues: { username: user?.email_address } });
  const { trackEvent, actions, categories } = useGoogleAnalytics();

  const formErrorsAmount = Object.keys(formErrors).length;
  useEffect(() => {
    if (formErrorsAmount > 1) { setErrorMessage(Constants.MULTIPLE_FORM_ERROR_MESSAGE); }
    else { setErrorMessage(""); }
  }, [formErrorsAmount]);


  const successfulRegistrationBackLink = () => {
    const routeItem = routeMap.get("/login");
    const dataForCurrentLocation = {
      title: routeItem.title,
      route: routeItem.route
    };
    dispatch(setCurrentLocation(dataForCurrentLocation));
  };


  const completeRegistration = (form) => {
    setErrorMessage("");
    const payload = isResetPasswordFlow
      ? { new_password: form?.password, token: urlToken }
      : constructCreatePassword(form, user, authToken.value, state?.securityAnswer);

    const passwordFunc = isResetPasswordFlow ? postResetPassword : postCreatePassword;
    const navigateRoute = isResetPasswordFlow ? "/success/password-reset" : "/success/registration";

    dispatch(passwordFunc(payload))
      .unwrap()
      .then(() => {
        trackEvent(actions.REGISTRATION, categories.ATTEMPT_SUCCESS);
        dispatch(resetNavState());
        successfulRegistrationBackLink();
        navigate(navigateRoute);
      })
      .catch(error => {
        trackEvent(actions.REGISTRATION, categories.ATTEMPT_FAILURE);
        const errorMessage = isResetPasswordFlow ? getUserFriendlyResponse(error) : error?.data?.errorMessage;
        setErrorMessage(errorMessage|| "An unexpected error has occurred. Please try again.");
      });
  };

  const validateMatchingPasswords = () => {
    const password = getValues("password");
    const confirmPassword = getValues("confirmPassword");
    if (!password || !confirmPassword) return null;
    if (password !== confirmPassword) return "Password must match";

    clearErrors("password");
    clearErrors("confirmPassword");
    return null;
  };

  const passwordValidation = {
    ...schema.password.options,
    validate: (value) => validateMatchingPasswords(value, "password"),
    pattern: validate.newPassword
  };

  const confirmPasswordValidation = {
    ...schema.password.options,
    validate: (value) => validateMatchingPasswords(value, "confirmPassword"),
    pattern: validate.newPassword
  };

  const defaultList = [
    "Minimum length of 8 characters",
    "Lowercase letter (a-z)",
    "Uppercase letter (A-Z)",
    "Special Character",
    "At least one number"
  ];

  const displayList = isResetPasswordFlow
    ? [...defaultList, "Cannot use previous 5 password(s)"]
    : defaultList;

  return (
    <>
      <Title title="Create Password" />
      <Alert message={errorMessage} severity="error" />
      <section>
        <BulletList title="Password must have:" items={displayList} sx={{ marginBottom: "1rem" }} />
      </section>
      <section>
        <form onSubmit={handleSubmit(completeRegistration)} noValidate={true} >
          <Grid container spacing={1} component="fieldset" sx={{ border: "none", padding: "0" }}>
            {/* hidden field for screen reader to save login info */}
            <TextField sx={{ display: "none" }} {...register("username")} autoComplete="username" />
            <Grid item xs={12}>
              <TextField
                {...schema.defaultProperties("password")}
                {...register("password", passwordValidation)}
                error={!!formErrors?.password}
                helperText={formErrors?.password?.message || " "}
                id="password"
                inputMode="text"
                label="New Password"
                autoComplete="new-password"
                type={isPasswordVisible ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...schema.defaultProperties("confirmPassword")}
                {...register("confirmPassword", confirmPasswordValidation)}
                error={!!formErrors?.confirmPassword}
                helperText={formErrors?.confirmPassword?.message || " "}
                id="confirmPassword"
                inputMode="text"
                label="Re-enter Password"
                autoComplete="off"
                type={isConfirmPasswordVisible ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {isConfirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} container direction="row-reverse">
              <Grid item xs={12} md={6}>
                <Box sx={{ position: "relative" }}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    variant="contained"
                    type="submit"
                    aria-label="Submit and navigate to login"
                    disabled={promote.isSubmitting  || resetPassword.isSubmitting}
                  >
                    Submit
                  </Button>
                  {(promote.isSubmitting  || resetPassword.isSubmitting) && <StyledCircularProgress />}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </section>
    </>
  );
};

export default CreatePassword;
