import { styled } from "@mui/system";
import { Divider as MUIDivider } from "../mui";

/**
 * Divider component with static styles.
 *
 * @component
 * @return {JSX.Element}
 */

// MUI component
const Styled = styled(MUIDivider);

const Divider = ({ style }) => ({
  width: "100%",
  borderColor: "#EFEFEF",
  marginBlock: "1rem",
  ...style
});

export const StyledDivider = Styled(Divider);
