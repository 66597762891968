import React, { Fragment, useState, useRef, useMemo, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
  FormControlLabel,
  useTheme
} from "@mui/material";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Checkbox, Typography, Calendar, MenuItem, Modal } from "../../components";
import { Summary } from "../../components/custom/features/Summary";
import Title from "../../components/Title";
import { useGoogleAnalytics } from "../../services/analytics/hooks/useGoogleAnalytics";
import { ButtonElement } from "../../services/sdk/components/ButtonElement.js";
import { putEditAutopay, deleteAutopay } from "../../store/loans/loans.slice";
import { getSavedAccounts, patchSaveAccount, setFulfilledAccounts } from "../../store/savedAccounts/savedAccounts.slice";
import { validateAmount } from "../../utils/helpers/cardHelpers";
import { validateIsBusinessDay } from "../../utils/helpers/dayjsHelpers";
import { getAutopayConvenienceFee, getPaymentConfig } from "../../utils/helpers/getPaymentConfig";
import { getAccountLastFour } from "../../utils/helpers/handleAccounts";
import { useMultipleFormErrors } from "../../utils/hooks/useMultipleFormErrors";
import { useNonBankingDays } from "../../utils/hooks/useNonBankingDays";
import { validate } from "../../utils/validators/validators";
import FinicityService from "../Payment/MakePayment/ach/finicity/FinicityService";
import AchModals from "../Payment/MakePayment/ach/modals";
import { Form } from "./styles";

const formOptions = {
  mode: "onBlur",
  reValidateMode: "onChange",
  shouldFocusError: true
};

export const EditAutopaySchedule = () => {
  // Named selectors
  const authState = (state) => state.auth;
  const accountsState = (state) => state.savedAccounts;
  const institutionState = (state) => state.institution;
  const paymentState = (state) => state.payment;
  const loansState = (state) => state.loans;
  const achVerification = (state) => state.achVerification;

  // Pass in named selectors and gets state from redux
  const { authToken, user, flow } = useSelector(authState);
  const { getAccounts, saveAccount } = useSelector(accountsState);
  const { config, details } = useSelector(institutionState);
  const { loan } = useSelector(paymentState);
  const { autopay } = useSelector(loansState);
  const { sign, verify } = useSelector(achVerification);

  // Local State
  const [openModal, setOpenModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldShowError, setShouldShowError] = useState(false);

  // Hooks
  const { watch, trigger, setValue, register, handleSubmit, formState: { errors, isDirty } } = useForm(formOptions);
  const { errorMessage } = useMultipleFormErrors(errors);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const finicityRef = useRef();
  const finicityContainerRef = useRef();
  const materialTheme = useTheme();
  const nonBankingDays = useNonBankingDays();
  const { trackEvent, actions, categories } = useGoogleAnalytics();

  // Filter Accounts
  const results = useMemo(() => getAccounts?.response?.results || [], [getAccounts?.response?.results]);
  const loanAccountId = useMemo(() => loan?.autopay?.account_id, [loan?.autopay?.account_id]);
  const [accounts] = useMemo(() => results.filter(ele => ele.id === loanAccountId), [results, loanAccountId]);

  // Values to Watch
  const watchAccount = watch("payFromAccountDropdown", accounts?.id);
  const watchCheckbox = watch("checkbox", !loan?.autopay?.process_after);
  const watchEndDate = watch("endDate", loan?.autopay?.ending_date);
  const watchEndDateOptions = watch("endDateOptions", loan?.autopay?.ending || "until_you_cancel");
  const watchFrequency = watch("frequency", loan?.autopay?.frequency);
  const watchNameOnAccount = watch("nameOnAccount", accounts?.banking_details?.name_on_account);
  const watchPaymentAmount = watch("paymentAmount", loan?.autopay?.payment_amount.toFixed(2));
  const watchStartDate = watch("startDate", loan?.autopay?.starting_date);

  // Format values
  const currentLoan = results?.find(loan => loan.id === watchAccount);
  const loanNameOnAccount = currentLoan?.banking_details?.name_on_account;
  const amountToPay = Number(watchPaymentAmount) || Number(0);
  const convenienceFee = Math.abs(Number(getAutopayConvenienceFee(details, { loan: loan?.type })));
  const total = ((Number(amountToPay) || 0) + Number(convenienceFee));
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const nextDueDate = (loan?.next_due_date !== null && loan?.next_due_date !== "null")
    ? { title: "Due Date:", value: loan?.next_due_date }
    : null;

  useEffect(() => {
    if (getAccounts?.response?.found === undefined) {
      dispatch(getSavedAccounts(authToken.value));
    }
  }, [getAccounts?.response?.found, authToken.value, dispatch]);

  useEffect(() => {
    setValue("nameOnAccount", loanNameOnAccount, {
      shouldValidate: true,
      shouldDirty: true
    });
  }, [watchAccount, setValue, loanNameOnAccount]);

  useEffect(() => {
    if (saveAccount?.fulfilledAccounts.length === 1) {
      const [account] = saveAccount.fulfilledAccounts;
      setValue("payFromAccountDropdown", account?.value?.id);
      return () => {
        dispatch(setFulfilledAccounts([]));
      };
    }
  }, [saveAccount.fulfilledAccounts]);

  // Handlers
  const handleFormSubmit = (data) => {
    const payload = {
      token: authToken?.value,
      loan_id: loan?.id,
      account_id: data?.payFromAccountDropdown,
      ending: data?.endDateOptions,
      frequency: data?.frequency,
      payment_amount: data.paymentAmount ? Math.abs(Number(data.paymentAmount)) : amountToPay,
      process_after: !data?.checkbox,
      skips: [],
      starting_date: dayjs(data?.startDate).format("YYYY-MM-DD"),
      brandDetails: {
        brand_phone: config?.brand_phone,
        brand_name: config?.brand_name
      }
    };

    if (watchEndDateOptions === "on_a_specific_date") {
      payload.ending_date = dayjs(data?.endDate).format("YYYY-MM-DD");
    }

    if (currentLoan?.banking_details?.name_on_account !== watchNameOnAccount) {
      const accountPayload = {
        token: authToken?.value,
        id: watchAccount,
        banking_details: {
          name_on_account: data?.nameOnAccount
        }
      };
      dispatch(patchSaveAccount(accountPayload))
        .unwrap()
        .then((response) => {
          if (response?.banking_details?.name_on_account === accountPayload?.banking_details?.name_on_account) {
            dispatch(putEditAutopay(payload))
              .unwrap()
              .then(() => {
                trackEvent(actions.UPDATE_AUTOPAY, categories.ATTEMPT_SUCCESS);
              })
              .catch(() => {
                trackEvent(actions.UPDATE_AUTOPAY, categories.ATTEMPT_FAILURE);
              });
          }
        }).catch(() => { });
    } else {
      dispatch(putEditAutopay(payload))
        .unwrap()
        .then(() => {
          trackEvent(actions.UPDATE_AUTOPAY, categories.ATTEMPT_SUCCESS);
        })
        .catch(() => {
          trackEvent(actions.UPDATE_AUTOPAY, categories.ATTEMPT_FAILURE);
        });
    }
  };

  const handleDeleteAutopay = () => {
    const payload = {
      token: authToken?.value,
      loan_id: loan?.id
    };
    dispatch(deleteAutopay(payload))
      .unwrap()
      .then(() => {
        trackEvent(actions.DELETE_AUTOPAY, categories.ATTEMPT_SUCCESS);
        navigate("/accounts");
      })
      .catch(() => {
        trackEvent(actions.DELETE_AUTOPAY, categories.ATTEMPT_FAILURE);
        setOpenModal(false);
      });
  };

  // Helpers
  const maskedLoanNumber = (loan) => {
    const accountLastFour = getAccountLastFour(loan);
    const getLoanPreFix = loan.replace(/[^accountLastFour/]/g, "*");
    return `${getLoanPreFix}${accountLastFour}`;
  };

  const setModalClosed = (route) => {
    setIsModalOpen(false);
    setIsButtonDisabled(false);
    if (route) navigate(route);
  };

  const isValidDate = (event) => {
    const formattedDate = dayjs(event).format("MM/DD/YYYY");
    const businessDay = !validateIsBusinessDay(dayjs(event));
    const nonBankingDay = nonBankingDays?.includes(formattedDate);
    if (watchFrequency === "monthly") {
      return nonBankingDay;
    }
    return businessDay || nonBankingDay;
  };

  const { ach, shouldDisableAutopayAmount } = getPaymentConfig(details, loan?.type, true);

  return (
    <Fragment>
      <Title title="Edit Autopay Schedule" />
      <Alert
        message={getAccounts?.error?.response || autopay?.api?.response?.response}
        severity={getAccounts?.error?.severity || autopay?.api?.response?.severity}
      />
      <Alert
        message={autopay?.deleted?.response?.response}
        severity={autopay?.deleted?.response?.severity}
      />
      <Alert message={errorMessage} severity="error" />
      <Typography component="p" variant="body2" sx={{ marginBlock: "0.5rem" }}>* Required Field</Typography>

      <Form materialTheme={materialTheme} onSubmit={handleSubmit(handleFormSubmit)} noValidate={true}>
        <section className="inputs-container">
          <fieldset className="fieldset-container">
            <Typography component="legend" variant="h2" sx={{ marginBottom: "0.875rem" }}>
              Payment Details
            </Typography>

            <div style={{ marginBottom: "1rem" }}>
              {
                details?.services?.sdk?.enhanced
                  ? <ButtonElement />
                  :
                  <>
                    <div id="swbc-consumer-experience" tabIndex="-1" ref={finicityContainerRef} />
                    <FinicityService
                      details={details}
                      flow={flow}
                      nameOnAccount={user?.account_name}
                      ref={finicityRef}
                      setValue={setValue}
                      setIsButtonDisabled={setIsButtonDisabled}
                      setIsModalOpen={setIsModalOpen}
                      setShouldShowError={setShouldShowError}
                      token={authToken.value}
                      user={user}
                      entry="editAutopay"
                      achWebIframe={finicityContainerRef}
                    />

                    <AchModals
                      shouldShowError={shouldShowError}
                      isModalOpen={isModalOpen}
                      setModalClosed={setModalClosed}
                      config={config}
                      entry="editAutopay"
                    />
                    <Button
                      color="secondary"
                      disabled={
                        getAccounts?.isSubmitting
                        || autopay?.api?.isSubmitting
                        || saveAccount?.isSubmitting
                        || sign?.isLoading
                        || verify?.isLoading
                        || isButtonDisabled
                      }
                      loading={sign?.isLoading || verify?.isLoading || saveAccount?.isSubmitting}
                      onClick={() => finicityRef.current.openFinicityFlow()}
                    >
                      CONNECT YOUR BANK
                    </Button>
                  </>
              }

            </div>

            <TextField
              id="account-select"
              label="Account"
              size="medium"
              fullWidth
              error={!!errors?.payFromAccountDropdown}
              helperText={errors?.payFromAccountDropdown?.message || " "}
              select
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      boxSizing: "border-box",
                      maxHeight: 200,
                      marginTop: "8px"
                    }
                  },
                  sx: {
                    width: 230
                  }
                }
              }}
              required
              value={results?.length === 0 ? null : watchAccount}
              {...register("payFromAccountDropdown", {
                required: "Please select an account"
              })}
            >
              {
                Array.isArray(results) && results.length > 0
                  ? results.map((ele, i) => (
                    <MenuItem key={i} value={ele.id} selected={ele.id === watchAccount} sx={{
                      display: "block",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap"
                    }}>
                      {`Account ending in ${ele.last_4} with ${ele.banking_details.name_of_bank}`}
                    </MenuItem>
                  ))
                  : <MenuItem disabled value={""}>
                    Please connect your bank
                  </MenuItem>
              }
            </TextField>

            <TextField
              required
              id="name-on-account"
              label="Name on Account"
              error={!!errors?.nameOnAccount}
              inputProps={{ maxLength: 81 }}
              helperText={errors?.nameOnAccount?.message || " "}
              value={watchNameOnAccount}
              {...register("nameOnAccount", validate.fullName)}
            />

            <FormControl
              disabled={shouldDisableAutopayAmount}
              error={!!errors?.paymentAmount}
              required
            >
              <InputLabel htmlFor="payment-amount">Payment Amount</InputLabel>
              <OutlinedInput
                autoComplete="transaction-amount"
                disabled={shouldDisableAutopayAmount}
                id="payment-amount"
                label="Payment Amount"
                placeholder={Number(watchPaymentAmount) === 0 ? "0.00" : ""}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                inputProps={{ maxLength: 8 }}
                value={watchPaymentAmount}
                {...register("paymentAmount", {
                  disabled: shouldDisableAutopayAmount,
                  required: "Payment amount must be greater than $1",
                  validate: {
                    isValidFormat: (value) => (Number(value) ? null : "Not a valid amount"),
                    isAmountValid: (value) => validateAmount(value, ach.maxAchAmount, ach.minAchAmount)
                  }
                })}
              />
              {
                !shouldDisableAutopayAmount
                  ? <FormHelperText>{errors?.paymentAmount?.message || " "}</FormHelperText>
                  : null
              }
            </FormControl>
            {
              shouldDisableAutopayAmount
                ?
                <Alert
                  severity="info"
                  message={`Payment amount of $${formatter.format(amountToPay)} has been pre-set by your bank.`}
                  sx={{ marginBottom: "16px" }}
                />
                : null
            }
          </fieldset>
          <fieldset className="fieldset-container">
            <Typography component="legend" variant="h2" sx={{ marginBottom: "0.875rem" }}>
              Payment Schedule
            </Typography>
            <div className="date-selection-container">
              <TextField
                error={!!errors?.frequency}
                helperText={errors?.frequency?.message || " "}
                id="frequency-select"
                label="Frequency"
                size="medium"
                fullWidth
                select
                required
                value={watchFrequency}
                {...register("frequency", {
                  required: "Please select a frequency",
                  validate: async (value) => {
                    value !== "monthly" && await trigger("startDate", { shouldFocus: true });
                    return null;
                  }
                })}
              >
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="every_two_weeks">Every Two Weeks</MenuItem>
              </TextField>

              <Calendar
                label="Start Date"
                control={{
                  ...register("startDate", {
                    required: "Start Date is required",
                    validate: (value) => {
                      const isDateBefore = dayjs(value).isBefore(dayjs());
                      const isBusinessDay = isValidDate(value);
                      if (isBusinessDay || isDateBefore) return "Please choose an available date";
                      return null;
                    }
                  })
                }}
                minDate={dayjs().add(1, "day")}
                errors={errors?.startDate}
                value={dayjs(watchStartDate)}
                shouldDisableDate={(event) => isValidDate(event)}
                onChange={
                  (event) => setValue("startDate", event, {
                    shouldValidate: true,
                    shouldDirty: true
                  })
                }
              />
            </div>

            <div className="date-selection-container">
              <TextField
                {...register("endDateOptions", {
                  required: "Please select an end date option"
                })}
                error={!!errors?.endDateOptions}
                helperText={errors?.endDateOptions?.message || " "}
                id="end-date-select"
                label="End Date Options"
                size="medium"
                fullWidth
                select
                required
                value={watchEndDateOptions}
              >
                <MenuItem value='until_you_cancel'>Until you cancel</MenuItem>
                <MenuItem value='on_a_specific_date'>On a specific date</MenuItem>
              </TextField>
              {
                watchEndDateOptions === "on_a_specific_date"
                  ?
                  <Calendar
                    control={{
                      ...register("endDate", {
                        required: (watchEndDateOptions === "on_a_specific_date") && "End Date is required",
                        validate: (value) => {
                          const isAfterStartDate = dayjs(value).isAfter(watchStartDate);
                          const isBusinessDay = (watchFrequency !== "monthly") ? isValidDate(value) : null;
                          if (!isAfterStartDate) return "End date must be after start";
                          if (isBusinessDay) return "Please choose an available date";
                          return null;
                        }
                      })
                    }}
                    label="End Date"
                    errors={errors?.endDate}
                    value={dayjs(watchEndDate)}
                    minDate={dayjs(watchStartDate).add(1, "day")}
                    shouldDisableDate={(event) => ((watchFrequency !== "monthly") ? isValidDate(event) : null)}
                    onChange={(event) => setValue("endDate", event, { shouldValidate: true, shouldDirty: true })}
                    onError={
                      (event) => {
                        if (event === "minDate") {
                          setValue("endDate", dayjs(watchStartDate).add(1, "day"), {
                            shouldValidate: true,
                            shouldDirty: true
                          });
                        }
                      }
                    }
                  />
                  : null
              }
            </div>
          </fieldset>
        </section>

        <section className="checkbox-container">
          <Typography sx={{ marginBottom: "0.5rem" }} variant="body1">
            By default, recurring transactions that fall on non-banking days will process
            on the first banking day AFTER the scheduled date. By selecting the box below,
            the transaction will be processed on the closest banking day BEFORE the scheduled date.
          </Typography>

          <FormControlLabel {...register("checkbox")}
            label={<Typography>Process BEFORE?</Typography>}
            control={<Checkbox checked={watchCheckbox} />}
          />
        </section>

        <Summary
          showHeader={true}
          rows={
            [
              { title: "Type:", value: loan?.type.toLowerCase() },
              { title: "Loan:", value: maskedLoanNumber(loan?.number) },
              nextDueDate,
              { title: "Payment Amount:", value: `$${formatter.format(amountToPay)}` },
              { title: "Fees:", value: `$${formatter.format(convenienceFee || "0.00")}` },
              {
                title: "Total Balance:",
                value: `$${formatter.format(total)}`,
                divider: true
              }
            ]
          }
        />

        <section className="buttons-container">
          <Button
            color="primary"
            disabled={getAccounts?.isSubmitting || !isDirty || autopay?.api?.isSubmitting}
            loading={getAccounts?.isSubmitting || autopay?.api?.isSubmitting}
            type="submit"
          >
            Update Autopay Schedule
          </Button>
          <Button
            color="secondary"
            disabled={getAccounts?.isSubmitting || autopay?.api?.isSubmitting}
            variant="contained"
            onClick={() => {
              setOpenModal(true);
              trackEvent(actions.BUTTON_CLICK, categories.CANCEL_AUTOPAY);
            }}
          >
            Cancel Autopay Schedule
          </Button>
        </section>

      </Form>
      <Modal
        title="Cancel Autopay Schedule"
        content="Are you sure you would like to cancel your Autopay?"
        primary={{
          children: "Yes",
          onClick: () => handleDeleteAutopay(),
          loading: autopay?.deleted?.isSubmitting,
          disabled: autopay?.deleted?.isSubmitting
        }}
        secondary={{
          children: "No",
          onClick: () => setOpenModal(false),
          disabled: autopay?.deleted?.isSubmitting
        }}
        open={openModal}
      />
    </Fragment >
  );
};
