import React from "react";
import { Divider, Stack } from "@mui/material";
import { Summary } from "../../../../components/custom/features/Summary";
import { Grid, Typography } from "../../../../components/mui";
import { getAccountLastFour } from "../../../../utils/helpers/handleAccounts";

const PaymentSummary = (props) => {

  const { paymentDetails, loan, isSelectedDate, displayPaymentDate } = props;

  const paymentDate = isSelectedDate ? paymentDetails?.date : displayPaymentDate;
  const totalAmount = (Number(paymentDetails?.convenienceFee) + Number(paymentDetails?.amount)).toFixed(2).toLocaleString();
  const convenienceFee = Number(paymentDetails?.convenienceFee).toFixed(2);
  const paymentAmount = Number(paymentDetails?.amount).toFixed(2);
  const accountLastFour = getAccountLastFour(loan?.number);

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12} md={12} container>
        <Summary
          rows={[
            {
              title: "Pay To Account Ending in:",
              value: accountLastFour
            },
            {
              title: "Payment Date:",
              value: paymentDate
            },
            {
              title: "Bill Amount:",
              value: `$${paymentAmount}`
            },
            {
              title: "Fees:",
              value: `$${convenienceFee}`
            }
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} container direction="row-reverse">
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Total Amount
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              ${totalAmount}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

    </Grid>
  );
};

export default PaymentSummary;
