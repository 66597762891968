import initialState from "./initialState";

const actions = {
  setEntryPoint: (state, action) => {
    state.entryPoint = action.payload;
  },
  setShouldClearBreadcrumbs: (state, action) => {
    state.shouldClearBreadcrumbs = action.payload;
  },
  setAuthToken: (state, action) => {
    state.authToken.value = action.payload;
  },
  deleteAuthToken: (state) => {
    state.authToken.value = "";
  },
  setTimeoutMessage: (state, action) => {
    state.timeoutMessage = action.payload;
  },
  setEmail: (state, action) => {
    state.form.emailAddress = action.payload;
  },
  setFirstName: (state, action) => {
    state.form.firstName = action.payload;
  },
  setLastName: (state, action) => {
    state.form.lastName = action.payload;
  },
  setMemberAccount: (state, action) => {
    state.form.memberAccount = action.payload;
  },
  setFirstSecurityQuestion: (state, action) => {
    state.form.firstSecurityQuestion = action.payload;
  },
  setSecondSecurityQuestion: (state, action) => {
    state.form.secondSecurityQuestion = action.payload;
  },
  setForm: (state, { payload }) => {
    state.form = { ...state.form, ...payload };
  },
  setNonValidationPayload: (state, { payload }) => {
    state.register.nonValidation.payload = payload;
  },
  resetForm: (state) => {
    state.form = initialState.form;
    state.login = initialState.login;
    state.guestAuth = initialState.guestAuth;
  },
  resetAuthToken: (state) => {
    state.authToken.value = "";
  },
  resetAuth: (state) => {
    Object.assign({ ...initialState }, { form: { emailAddress: state.form.emailAddress } });
    state.form = initialState.form;
    state.register = initialState.register;
  },
  resetProfileApiResponses: (state) => {
    state.changeEmail.api.response = initialState.changeEmail.api.response;
    state.changePassword.api.response = initialState.changePassword.api.response;
    state.stateOfResidence.api.response = initialState.stateOfResidence.api.response;
  },
  resetUniqueId: (state) => {
    state.uniqueId = initialState.uniqueId;
  },
  setFlow: (state, { payload }) => {
    state.flow = payload;
  },
  clearStateOfResidenceResponse: (state) => {
    state.stateOfResidence.api.response = initialState.stateOfResidence.api.response;
  },
  clearAuthStateOnLogout: (state) => {
    state.flow = initialState.flow;
    state.entryPoint = initialState.entryPoint;
    state.user = initialState.user;
    state.shouldClearBreadcrumbs = initialState.shouldClearBreadcrumbs;
    state.failures = initialState.failures;
    state.form = initialState.form;
    state.changeEmail = initialState.changeEmail;
    state.changePassword = initialState.changePassword;
    state.stateOfResidence = initialState.stateOfResidence;
    state.deeplink = initialState.deeplink;
    state.login = initialState.login;
    state.guestAuth = initialState.guestAuth;
    state.register = initialState.register;
    state.promote = initialState.promote;
    state.authToken = initialState.authToken;
    state.resetPasswordRequest = initialState.resetPasswordRequest;
    state.resetPassword = initialState.resetPassword;
    state.uniqueId = initialState.uniqueId;
  }
};

export default actions;
