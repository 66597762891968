import { NavLink } from "react-router-dom";
import styled from "styled-components";

/**
 * NavLink component with styles.
 *
 * @component
 * @return {JSX.Element}
 */

export const StyledLink = styled( NavLink )`
  color: #333333;
  border-color: transparent;
  border-radius: 8px 8px 0px 0px;
  border-style: solid;
  border-width: 2px 2px 0px 2px;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  text-decoration: none;
  outline-color: black;
  border-bottom: solid 2px transparent;

  &.active {
    border-color: ${(props) => props?.config?.link_color};
    color: ${(props) => props?.config?.link_color};
    border-bottom: solid 2px transparent;
  }

  :hover,
  &.active:hover {
    border-bottom: solid 2px ${(props) => props?.config?.link_color};
  }
`;
