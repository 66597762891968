import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isBetween from "dayjs/plugin/isBetween";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { DayjsUtcLocalStr } from "./dayjsHelpers";

dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(utc);

export const downTime = (props) => {
  const { enabled, whitelist, id, startDate, endDate } = props;
  const currentTime = DayjsUtcLocalStr();
  const isDowntime = dayjs(currentTime).isBetween(startDate, endDate);
  const maintenance = isDowntime && enabled && !whitelist.includes(id);
  return maintenance;
};
