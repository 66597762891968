export const achVerificationSchema = {
  type: "ACHWebVerification",
  handler: "setACHWebVerificationHandler",
  eventHandler: "setEventHandler",
  start: "startACHWebVerification",
  accountId: "ACHWebVerificationAccountId",
  closeFlow: "closeACHWebFlow"
};

export const multiple = "VERIFY_ACH_MULTIPLE_ACCOUNTS";
export const single = "VERIFY_ACH_ONE_ACCOUNT_ONLY";
