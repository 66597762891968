import React from "react";
import { Typography } from "../../../components";


export const ListElement = ({ list, error }) => {
  return (
    <>
      <Typography sx={{ fontWeight: "bold" }}>{error}</Typography>
      <ul>
        {
          list?.map((ele, i) => (
            <li key={i}>
              <Typography>Account ending in {ele?.accountNumberLast4} with {ele?.nameOfInstitution}</Typography>
            </li>
          ))
        }
      </ul>
    </>
  );
};
