import React from "react";
import { Breadcrumbs as MUIBreadcrumbs } from "@mui/material";

/**
 * Wrapper component for Material UI Breadcrumbs
 *
 * @component
 * @param {Object} props Props defined by Material UI. Please see Material UI documentation for details.
 * @return {JSX.Element}
 */

export const Breadcrumbs = (props) => {
  return (
    <MUIBreadcrumbs
      data-cy="breadcrumbs"
      {...props}
    >
      {props.children}
    </MUIBreadcrumbs>
  );
};
