const initialState = {
  flow: "",
  entryPoint: "",
  user: {},
  timeoutMessage: false,
  shouldClearBreadcrumbs: true,
  failures: 0,
  getUser: {
    isSubmitting: false,
    apiError: null,
    failures: 0
  },
  form: {
    emailAddress: "",
    firstName: "",
    lastName: "",
    memberAccount: "",
    firstSecurityQuestion: "",
    secondSecurityQuestion: ""
  },
  changeEmail: {
    api: {
      isSubmitting: false,
      response: null,
      failures: 0
    },
    email: {
      changed: false,
      current: "",
      onAuth: ""
    }
  },
  changePassword: {
    api: {
      changed: false,
      isSubmitting: false,
      failures: 0,
      response: null
    }
  },
  stateOfResidence: {
    hasStateOfResidence: false,
    api: {
      isSubmitting: false,
      failures: 0,
      response: null,
      success: false
    }
  },
  deeplink: {
    api: {
      isSubmitting: false,
      failures: 0,
      response: null,
      success: false
    }
  },
  login: {
    isSubmitting: false,
    apiError: null,
    failures: 0
  },
  guestAuth: {
    isSubmitting: false,
    apiError: null,
    success: false,
    failures: 0
  },
  register: {
    isSubmitting: false,
    apiError: null,
    response: null,
    success: null,
    failures: 0,
    nonValidation: {
      payload: null,
      isSubmitting: false,
      isSuccessful: false,
      response: null,
      error: null
    }
  },
  promote: {
    isSubmitting: false,
    apiError: null,
    response: null
  },
  authToken: {
    isRefreshing: false,
    apiError: null,
    value: ""
  },
  resetPasswordRequest: {
    isSubmitting: false,
    response: null,
    apiError: null
  },
  resetPassword: {
    isSubmitting: false,
    response: null,
    apiError: null
  },
  uniqueId: {
    isSubmitting: false,
    response: null,
    apiError: null
  }
};

export default initialState;
