import React from "react";
import { Loader } from "@swbc/swivel-components";
// import { Button } from "../../../../components";
// import { useDispatch } from "react-redux";
// import { Button } from "../../../../components/mui";

/**
 * Wrapped Apple Pay button
 * @constructor
 * @param {Object} props
 * @param {Function} props.onClick - React onClick function.
 * @param {Boolean} props.loading - If true, disable button and display loading spinner.
 * @param {Object} [props.sx] - sx CSS Styling object
 * @param {String} [props.sx.borderRadius] - Border-Radius of Wrapped and Apple Pay buttons
 * @param {String} [props.sx.height] - Height of Wrapped and Apple Pay buttons.
 * @param {String} [props.sx.padding] - Padding of Apple Pay button.
 * @param {String} [props.sx.width] - Width of Wrapped and Apple Pay buttons.
 */
const ApplePayButton = (props) => {
  const buttonStyle = {
    "backgroundColor": "transparent",
    "border": "0px",
    "borderColor": "transparent",
    "borderRadius": props?.sx?.borderRadius || "3px",
    "height": props?.sx?.height || "44px",
    "padding": "0px",
    "width": props?.sx?.width || "100%"
  };

  const appleButtonStyle = {
    "--apple-pay-button-height": props?.sx?.height || "44px",
    "--apple-pay-button-border-radius": props?.sx?.borderRadius || "3px",
    "--apple-pay-button-box-sizing": "border-box",
    "--apple-pay-button-padding": props?.sx?.padding || "0px",
    "--apple-pay-button-width": props?.sx?.width || "100%"
  };

  return (
    <button
      {...props}
      disabled={props?.loading}
      style={buttonStyle}
      type="submit"
    >
      { props?.loading ?
        <Loader/>
        :
        <apple-pay-button
          buttonsx="black"
          style={appleButtonStyle}
          type="plain"
        />
      }
    </button>
  );
};

export default ApplePayButton;
