import { states } from "@swbc/swivel-components";

export const constructApplePayPayload = (paymentDetails, loan, token, appleDetails, config) => {
  const paymentAmount = !Number.isNaN(paymentDetails?.amount) ? Number(paymentDetails?.amount) : 0;
  const stateAbbreviation = states.titlecase.find(state => state.name === paymentDetails?.stateOfResidence)?.abbreviation;
  const knownPendingTransactionNumbers = (paymentDetails?.pendingTransactions || []).map((trans) => trans.TrackingNumber);
  return {
    apple_details: appleDetails,
    config,
    fee: paymentDetails?.convenienceFee,
    fee_source: paymentDetails?.convenienceFeeSource,
    known_pending_transaction_numbers: knownPendingTransactionNumbers,
    loans: [
      {
        id: loan.id,
        amount: paymentAmount
      }
    ],
    request_token: loan.idempotencyToken,
    state_of_residence: stateAbbreviation,
    token,
    type: "apple"
  };
};
