import React, { useEffect, useState } from "react";
import {
  Button,
  InputLabel,
  FormControl,
  FormHelperText,
  TextField,
  Select,
  useTheme
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Box, CircularProgress, MenuItem, Typography } from "../../components/mui";
import Title from "../../components/Title";
import {
  getBankInfo,
  postReport,
  setBankAccount,
  setBankInfo,
  setBankInfoClearError,
  setBankInfoFailure,
  setPostReportFailure,
  resetManualLinkState
} from "../../store/achDetails/achDetails.slice";
import { setIsFinicityFlow, setIsAchVerificationComplete } from "../../store/payment/makePayment.slice";
import { postSaveAccount } from "../../store/savedAccounts/savedAccounts.slice";
import { REGISTERED, MULTIPLE_FORM_ERROR_MESSAGE } from "../../utils/constants/constants";
import defaultAchAccountTypes from "../../utils/data/achAccountTypes.json";
import { bankInfoStatusCodes } from "../../utils/data/responseCodeMessages";
import { increment } from "../../utils/helpers/commonHelpers";
import { constructSaveAccountPayload } from "../../utils/helpers/constructAchPayload";
import { validate } from "../../utils/validators/validators";
import { Form, sectionStyle, circleProgress } from "./LinkBankAccount.styles";

const LinkBankAccount = () => {
  // Named selectors
  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;
  const achDetailsState = (state) => state.achDetails;
  const paymentState = (state) => state.payment;
  const accountsState = (state) => state.savedAccounts;

  // Pass in named selectors and gets state from redux
  const { authToken, flow } = useSelector(authState);
  const { config, details } = useSelector(institutionState);
  const { manuallyLinkFlow } = useSelector(paymentState);
  const { bankInfo: { isLoading, error }, report, failures } = useSelector(achDetailsState);
  const { saveAccount } = useSelector(accountsState);

  // Local State
  const [abaBlacklistedMessage, setAbaBlacklistedMessage] = useState("");
  const [accountType, setAccountType] = useState("");
  const [apiError, setApiError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [transitionLoadingSpinner, setTransitionLoadingSpinner] = useState(false);

  // Hooks
  const dispatch = useDispatch();
  const materialTheme = useTheme();
  const navigate = useNavigate();
  const { register, handleSubmit, getValues, formState: { errors } } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true
  });

  // Variables
  const formErrors = Object.values(errors);
  const formErrorsAmount = formErrors.length;
  const accountTypes = details?.ach_account_types || defaultAchAccountTypes;

  const initialize = [...new Set(Object.values(getValues()))];
  const initialFormLength = initialize.length > 1;

  // Values to watch
  useEffect(() => {
    if (!initialFormLength && !report?.submitted) {
      dispatch(resetManualLinkState());
      setAbaBlacklistedMessage("");
      setApiError(false);
      setFormErrorMessage("");
    }

    if (!initialFormLength && report?.submitted) {
      dispatch(setBankInfo(false));
    }
  }, [initialFormLength, report, dispatch]);

  useEffect(() => {
    setDisableButton(isLoading || loadingSpinner);
  }, [loadingSpinner, isLoading]);

  useEffect(() => {
    const { status } = error;
    const { response, severity } = bankInfoStatusCodes()[status] || {};
    setApiError({ response, severity });
  }, [details, error]);

  useEffect(() => {
    if (formErrorsAmount > 0) {
      setFormErrorMessage("");
      if (formErrorsAmount > 1) {
        setFormErrorMessage(MULTIPLE_FORM_ERROR_MESSAGE);
      }
    }
  }, [formErrorsAmount]);

  // Handlers
  const handleChange = (event) => setAccountType(event.target.value);
  const handleVerifyBankAccount = (event) => {
    setTransitionLoadingSpinner(true);
    dispatch(setBankInfoClearError(false));
    dispatch(setBankInfo(false));
    setAbaBlacklistedMessage("");
    setApiError(false);
    setFormErrorMessage("");

    const { accountType, routingNumber } = getValues();
    const { native_aba_list, native_aba_list_error_text } = details;
    const isAbaBlacklisted = native_aba_list.some((val) => routingNumber.includes(val.length === 9 && val));
    const [account, type] = accountType.split("-");

    if (isAbaBlacklisted) {
      setLoadingSpinner(true);
      setTimeout(() => {
        setAbaBlacklistedMessage(native_aba_list_error_text);
        setLoadingSpinner(false);
      }, 1500);
    } else {
      dispatch(setBankInfo(false));
      dispatch(getBankInfo({ ...event, token: authToken.value }))
        .unwrap()
        .then((res) => {
          const { accountType, nameOnAccount, routingNumber, accountNumber } = event;
          dispatch(setBankAccount({
            type: "banking",
            primary: false,
            last4: accountNumber.slice(-4),
            validationType: "self_validated",
            vendor: "self",
            bankingDetails: {
              accountType,
              accountNumber,
              routingNumber,
              type,
              business: account === "business",
              nameOnAccount,
              nameOfBank: res?.name
            }
          }));

          dispatch(postReport({
            token: authToken.value,
            validation_type: "self_validated",
            vendor: "self",
            bank_name: res?.name,
            institution_id: config?.id
          }))
            .unwrap()
            .then(() => {
              dispatch(setIsFinicityFlow(false));
              dispatch(setIsAchVerificationComplete(true));
            })
            .catch((err) => dispatch(setPostReportFailure({ error: err })));

          if (flow === REGISTERED) {
            const payload = constructSaveAccountPayload({ ...event, ...res });
            dispatch(postSaveAccount({ ...payload, token: authToken.value }))
              .then(() => {
                navigate(manuallyLinkFlow?.route);
              })
              .catch(() => { });
          } else {
            navigate(manuallyLinkFlow?.route);
          }
          setTransitionLoadingSpinner(false);

        })
        .catch((err) => {
          dispatch(setBankInfoFailure({
            message: err,
            count: increment(failures?.count)
          }));
          setTransitionLoadingSpinner(false);
        });
    }
  };

  return (
    <>
      <Title title="Link Bank Account" />
      <Alert message={abaBlacklistedMessage} severity="warning" />
      <Alert message={apiError?.response} severity={apiError?.severity} />
      <Alert message={formErrorMessage} severity="error" />

      <Typography component="p" variant="body2" sx={{ marginBlock: "0.5rem" }}>* Required Field</Typography>

      <Form materialTheme={materialTheme} noValidate={true} onSubmit={handleSubmit(handleVerifyBankAccount)}>
        <Box component="fieldset" gridArea="left-col" sx={sectionStyle}>
          <Typography component="legend" variant="h4" sx={{ marginBottom: "0.875rem" }}>Account Details</Typography>

          <FormControl required error={!!errors?.accountType}>
            <InputLabel htmlFor="account-type-input-id" id="account-type-label">Account Type</InputLabel>
            <Select
              aria-describedby="account-type-helper-text"
              fullWidth
              id="account-type"
              inputProps={{ "id": "account-type-input-id", ...register("accountType", { required: "Please select an account type" }) }}
              label="Account Type"
              labelId="account-type-label"
              onChange={handleChange}
              value={accountType}>
              {
                accountTypes?.map(item => (
                  <MenuItem key={item.name} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))
              }
            </Select>
            <FormHelperText id="account-type-helper-text" >{errors?.accountType ? errors?.accountType?.message : " "}</FormHelperText>
          </FormControl>

          <TextField
            autoComplete="name"
            label="Name on Account"
            error={!!errors?.nameOnAccount}
            fullWidth
            helperText={errors?.nameOnAccount ? errors?.nameOnAccount?.message : " "}
            id="name-on-account"
            inputProps={{ "aria-required": "true" }}
            required
            size="medium"
            variant="outlined"
            {...register("nameOnAccount", validate.fullName)}
          />

          <TextField
            label="Routing Number"
            error={!!errors?.routingNumber}
            fullWidth
            helperText={
              errors?.routingNumber
                ? errors?.routingNumber?.message
                : "Nine digit number found on the bottom left corner of most personal checks"
            }
            id="routing-number"
            inputProps={{ "aria-required": "true" }}
            required
            size="medium"
            variant="outlined"
            {...register("routingNumber", validate.routingNumber)}
          />

          <TextField
            label="Account Number"
            error={!!errors?.accountNumber}
            fullWidth
            helperText={
              errors?.accountNumber
                ? errors.accountNumber.message
                : "Second set of numbers found between the routing number and check number"
            }
            id="account-number"
            inputProps={{ "aria-required": "true" }}
            required
            size="medium"
            variant="outlined"
            {...register("accountNumber", validate.accountNumber)}
          />

          <TextField
            label="Confirm Account Number"
            error={!!errors?.confirmAccountNumber}
            fullWidth
            helperText={errors?.confirmAccountNumber ? errors?.confirmAccountNumber?.message : " "}
            id="confirm-account-number"
            inputProps={{ "aria-required": "true" }}
            required
            size="medium"
            variant="outlined"
            {...register("confirmAccountNumber", {
              ...validate.confirmAccountNumber,
              validate: {
                checkAccounts: (value) => {
                  const { accountNumber } = getValues();
                  const hasLength = accountNumber.length >= 1;
                  return ((value !== accountNumber) && hasLength) ? "Account numbers do not match" : null;
                }
              }
            })}
          />
        </Box>

        <Box className="form-buttons" gridArea="button-link" sx={{ position: "relative" }}>
          <Button
            aria-label="Click to link account and continue to make a payment"
            color="primary"
            disabled={transitionLoadingSpinner || saveAccount?.isSubmitting}
            fullWidth
            size="large"
            sx={{ maxWidth: { xs: "xs", md: "md" } }}
            type="submit"
            variant="contained"
          >
            {manuallyLinkFlow?.manuallyLinkText}
          </Button>
          {(transitionLoadingSpinner || disableButton || saveAccount?.isSubmitting) && <CircularProgress size="1.5rem" sx={circleProgress} />}
        </Box>
      </Form>
    </>
  );
};

export default LinkBankAccount;
