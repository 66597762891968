import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import thunk from "redux-thunk";
import achDetailsReducer from "./achDetails/achDetails.slice";
import achVerificationReducer from "./achWeb/achVerification.slice";
import activitySliceReducer from "./activity/activity.slice";
import authReducer from "./auth/auth.slice";
import institutionReducer from "./institution/institution.slice";
import loanReducer from "./loans/loans.slice";
import maintenanceReducer from "./maintenance/maintenance.slice";
import navHistoryReducer from "./navhistory/navhistory.slice";
import nonBankingDaysReducer from "./nonBankingDays/nonBankingDays.slice";
import paymentReducer from "./payment/makePayment.slice";
import savedAccountsReducer from "./savedAccounts/savedAccounts.slice";

const institutionPersistConfig = {
  key: "institution",
  storage: storageSession,
  blacklist: ["details", "detailsError", "detailsLoaded", "isInstitutionDetailsLoading"]
};

const reducers = {
  auth: authReducer,
  activity: activitySliceReducer,
  institution: persistReducer(institutionPersistConfig, institutionReducer),
  maintenance: maintenanceReducer,
  payment: paymentReducer,
  navHistory: navHistoryReducer,
  loans: loanReducer,
  achVerification: achVerificationReducer,
  achDetails: achDetailsReducer,
  nonBankingDays: nonBankingDaysReducer,
  savedAccounts: savedAccountsReducer
};

export const store = configureStore({
  reducer: reducers,
  devTools: process.env.REACT_APP_REDUX_TOOLS_ALLOWED,
  middleware: [thunk]
});

export const persistor = persistStore(store);
