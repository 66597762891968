import React from "react";
import { DialogTitle as MUIDialogTitle } from "@mui/material";

/**
 * Wrapper component for Material UI DialogTitle
 *
 * @component
 * @param {Object} props Props defined by Material UI. Please see Material UI documentation for details
 * API:
 *
 * - [DialogTitle API](https://mui.com/material-ui/api/dialog-title/)
 * @return {JSX.Element}
 */

export const DialogTitle = (props) => {

  return (
    <MUIDialogTitle
      data-cy="dialogTitle"
      {...props}
    >
      {props.children}
    </MUIDialogTitle>
  );
};
