import React from "react";
import { InputAdornment } from "@mui/material";
import { Box } from "../mui";

/**
 * Component for showing logo image as a MaterialUI Card Media.
 *
 * @component
 * @param {string} name Name of credit card provider.
 * @return {JSX.Element}
 *
 */
const CardBrandLogo = ({ cardBrand }) => {
  const style = {
    height: "1.5rem",
    width: "2.562rem"
  };

  let iconName = "";
  switch (cardBrand?.toLowerCase()) {
    case "visa":
      iconName = "Visa.png";
      break;
    case "mastercard":
      iconName = "MasterCard.png";
      break;
    case "amex":
    case "american-express":
      iconName = "Amex.png";
      break;
    case "discover":
      iconName = "Discover.png";
      break;
    default:
      return <></>;
  }

  return (
    <InputAdornment position="start" data-cy="cardMediaInputAdornment">
      <Box
        data-cy="cardMediaBox"
        sx={style}
        component="img"
        src={require(`../../assets/img/${iconName}`)}
        alt={`${cardBrand} logo`}
      />
    </InputAdornment>
  );
};

export default CardBrandLogo;
