import React from "react";
import { Grid as MUIGrid } from "@mui/material";

/**
 * Wrapper component for Material UI Grid
 *
 * @component
 * @param {Object} props Props defined by Material UI. Please see Material UI documentation for details
 * API:
 *
 * - [Grid API](https://mui.com/material-ui/api/grid/)
 * @return {JSX.Element}
 */

export const Grid = (props) => {

  return (
    <MUIGrid
      data-cy="grid"
      {...props}
    >
      {props.children}
    </MUIGrid>
  );
};
