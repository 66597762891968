export const validate = {};

validate.newPassword = {
  value: new RegExp(/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).*$/),
  message: "Password must match requirements"
};

// Checks format and length of string to see if it's a valid uuid.
validate.uuid = {
  value: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
};

// Ensures that emails are valid, prevents users from having an email longer than 64 chars before the @ symbol.
validate.email = {
  message: "Invalid email address",
  value: /^((?=[a-z0-9.!#$%&'*+/=?^_`{|}~-]{1,64}@)(([a-z]|\d|[!#$%&'*+-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i
};

// Checks to see if there is an api status code in the string.
validate.statusCode = {
  value: /^[0-9]+: /
};

// Checks to see if there are numbers within the string.
validate.name = {
  message: "Name field cannot contain numbers",
  value: /^[\x20-\x2F\x3A-\x7E]{1,}$/
};

validate.fullName = {
  maxLength: {
    message: "First and last name must be less than 80 characters",
    value: 81
  },
  pattern: {
    message: "First and last name are required and separated by a space with out numeric values.",
    value: /^[\x21-\x7E][^0-9]{0,40}[ ][\x21-\x7E][^0-9]{0,40}$/i
  },
  required: "First and last name is required"
};

validate.routingNumber = {
  maxLength: {
    message: "Routing number must be 9 characters long",
    value: 9
  },
  pattern: {
    message: "Routing number is not valid. Please enter a valid routing number.",
    value: /^[0-9]{9}$/
  },
  required: "Routing number is required",
  validate: {
    // Check first two digits, RTN must be within the ranges
    prefixValid: (value) => {
      const prefix = parseInt(value.slice(0, 2), 10);
      const prefixValid = ((prefix >= 0 && prefix <= 12) || (prefix >= 21 && prefix <= 32));
      return (!prefixValid) ? validate.routingNumber.pattern.message : null;
    },
    // checksum alg used to validate RTN, the sum of all products should be evenly divisible by 10
    checkSum: (value) => {
      const [d1, d2, d3, d4, d5, d6, d7, d8, d9] = value;
      const total = (3 * (+d1 + +d4 + +d7)) + (7 * (+d2 + +d5 + +d8)) + (+d3 + +d6 + +d9);
      const checksumValid = (total % 10 === 0);
      return (!checksumValid) ? validate.routingNumber.pattern.message : null;
    }
  }
};

validate.accountNumber = {
  maxLength: {
    message: "Account cannot be more than 17 characters long",
    value: 17
  },
  pattern: {
    message: "Not a valid account number.",
    value: /^[a-zA-Z0-9.-]+$/
  },
  required: "Account number is required",
  validate: {
    checkConsecutiveCharacters: (value) => {
      const consecutiveCharacters = /[a-zA-Z]{3,}/;
      return (consecutiveCharacters.test(value)) ? validate.accountNumber.pattern.message : null;
    }
  }
};

validate.confirmAccountNumber = {
  maxLength: {
    message: "Account cannot be more than 17 characters long",
    value: 17
  },
  pattern: {
    message: "Not a valid account number.",
    value: /^[a-zA-Z0-9.-]+$/
  },
  required: "Confirm account number is required"
};

validate.url = {
  getValidUrl: (url) => {
    const regEx = new RegExp("^(?:[a-z]+:)?//", "i");
    return regEx.test(url) ? url : `https://${url}`;
  }
};
