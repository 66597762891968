import styled from "styled-components";

export const PaymentConfirmedWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "left-col"
    "divider"
    "right-col";

  ${props => props.materialTheme.breakpoints.up("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "left-col right-col";
    gap: 2rem;
  }
`;
