import React, { useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Alert, Typography } from "../../components";
import { StyledCircularProgress } from "../../components/styled/StyledCircularProgress";
import Title from "../../components/Title";
import { postDeeplinkLogin } from "../../store/auth/auth.slice";
import { getInstitutionDetails } from "../../store/institution/institution.slice";
import { isUuidValid } from "../../utils/helpers/uuidHelpers";
import { useMultipleFormErrors } from "../../utils/hooks/useMultipleFormErrors";
import { validate } from "../../utils/validators/validators";

const sectionStyle = {
  border: "none",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  margin: "0px",
  padding: "0px"
};

const options = {
  mode: "onBlur",
  reValidateMode: "onChange",
  shouldFocusError: true
};

export const DeeplinkLogin = () => {
  // Named selectors
  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;

  // Redux State
  const { deeplink } = useSelector(authState);
  const { config, detailsLoaded, isInstitutionDetailsLoading } = useSelector(institutionState);

  // Hooks
  const { register, handleSubmit, formState: { errors } } = useForm(options); // React Hook Form
  const { errorMessage } = useMultipleFormErrors(errors); // Hook that for handling multiple form errors
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Search Params
  const params = {
    id: searchParams.get("institutionId"),
    guest: searchParams.get("guest") === "true",
    token: searchParams.get("token") || ""
  };

  const isInstitutionValid = isUuidValid(params.id) && (config?.id === params.id);
  const institutionId = isInstitutionValid && params.id;

  // Alt config
  const altMethods = config?.authentication_methods;
  const altAuth = altMethods?.alternates || [];
  const hasAlternateMethods = Array.isArray(altAuth) && Boolean(altAuth.length);
  const member_id = config?.services?.deeplink?.fields?.member_id;

  let firstAltAuthMethod;
  let firstField;

  if (hasAlternateMethods) {
    [firstAltAuthMethod] = altAuth;
    [firstField] = firstAltAuthMethod.fields;
  }

  const displaySecurityQuestionTwo = firstAltAuthMethod?.use_security_answer_2;

  // Handlers
  const handleDeeplinkLogin = (data) => {
    const payload = {
      token: params.token,
      institution_id: institutionId,
      security_answer_1: data.firstSecurityQuestion.trim(),
      brandDetails: {
        brand_phone: config?.brand_phone,
        brand_name: config?.brand_name
      }
    };

    if (hasAlternateMethods && params.guest) {
      payload.auth_scheme = {
        identifier: firstAltAuthMethod?.identifier,
        fields: {
          [firstAltAuthMethod?.identifier]: member_id?.enabled ? data.altAuthField.replace(/\D?/g, "") : data.altAuthField.trim()
        }
      };
    }

    if (params.guest) {
      payload.email_address = data.emailAddress.toLowerCase().trim();
    }

    if (params.guest && displaySecurityQuestionTwo) {
      payload.security_answer_2 = data.secondSecurityQuestion.trim();
    }

    dispatch(postDeeplinkLogin(payload))
      .unwrap()
      .then((response) => {
        dispatch(getInstitutionDetails({
          institutionId,
          token: response.token
        }));
      });
  };

  // Listeners
  useEffect(() => {
    if (deeplink?.api.success && detailsLoaded) {
      navigate("/accounts");
    }

    return () => deeplink.api.success;
  }, [navigate, deeplink.api.success, detailsLoaded]);


  return (
    <>
      <Title title={config?.instructions?.eltropy?.title || "Verify it's you"} />
      <Alert
        message={deeplink?.api?.response?.response}
        severity={deeplink?.api?.response?.severity}
      />
      <Alert
        message={errorMessage}
        severity="error"
      />
      <Typography
        component="p"
        variant="body2"
        sx={{ marginBlock: "0.5rem" }}
      >
        * Required Field
      </Typography>

      <form style={{ display: "flex", flexDirection: "column", gap: "1rem" }} onSubmit={handleSubmit(handleDeeplinkLogin)} noValidate={true}>
        <fieldset style={sectionStyle}>
          <Typography component="legend" variant="body1" sx={{ marginBottom: "0.875rem" }}>
            {config?.instructions?.eltropy?.get_started || "Please provide the following information to gain access to your account"}
          </Typography>
          {
            params.guest && hasAlternateMethods && firstAltAuthMethod && firstField
              ?
              <TextField
                error={!!errors?.altAuthField}
                fullWidth
                helperText={errors?.altAuthField?.message || firstField?.display || " "}
                id={firstAltAuthMethod?.identifier}
                inputProps={{ "aria-required": "true" }}
                label={firstAltAuthMethod?.label}
                required
                size="medium"
                {...register("altAuthField", {
                  maxLength: {
                    value: 100,
                    message: `${firstAltAuthMethod?.label} must be less than 100 characters`
                  },
                  required: `${firstAltAuthMethod?.label} is required`,
                  pattern: {
                    value: new RegExp(member_id?.regex || ""),
                    message: member_id?.error_text || ""
                  }
                })}
              /> : null
          }
          {
            params.guest
              ?
              <TextField
                autoComplete="email"
                error={!!errors?.emailAddress}
                fullWidth
                helperText={errors?.emailAddress?.message || " "}
                id="email-address"
                inputMode="email"
                inputProps={{ "aria-required": "true" }}
                label="Email"
                required
                size="medium"
                type="email"
                {...register("emailAddress", {
                  maxLength: {
                    value: 80,
                    message: "Email address must be less than 80 characters"
                  },
                  required: "Email address is required",
                  pattern: validate.email
                })}
              /> : null
          }

          <TextField
            autoComplete="off"
            error={!!errors?.firstSecurityQuestion}
            fullWidth
            helperText={
              errors?.firstSecurityQuestion
                ? errors.firstSecurityQuestion.message
                : " "
            }
            id="first-security-question"
            inputProps={{ "aria-required": "true" }}
            label={config?.security_question_1}
            required
            variant="outlined"
            {...register("firstSecurityQuestion", {
              required: `${config?.security_question_1} is required`,
              pattern: {
                value: new RegExp(
                  config?.security_question_1_regex || "^.+$"
                ),
                message: "Invalid input"
              }
            })}
          />

          {
            params.guest && displaySecurityQuestionTwo
              ?
              <TextField
                autoComplete="off"
                error={!!errors?.secondSecurityQuestion}
                fullWidth
                helperText={
                  errors?.secondSecurityQuestion
                    ? errors.secondSecurityQuestion.message
                    : " "
                }
                id="second-security-question"
                inputProps={{ "aria-required": "true" }}
                label={config?.security_question_2}
                required
                variant="outlined"
                {...register("secondSecurityQuestion", {
                  required: `${config?.security_question_2} is required`,
                  pattern: {
                    value: new RegExp(
                      config?.security_question_2_regex || "^.+$"
                    ),
                    message: "Invalid input"
                  }
                })}
              />
              : null
          }

        </fieldset >

        <div style={{ position: "relative", display: "flex", gap: "1rem" }}>
          <Button
            color="primary"
            disabled={deeplink?.api?.isSubmitting || isInstitutionDetailsLoading}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Login
          </Button>
          {(deeplink?.api?.isSubmitting || isInstitutionDetailsLoading) ? <StyledCircularProgress /> : null}
        </div>
      </form>
    </>
  );
};
