import React from "react";
import { Box as MUIBox } from "@mui/material";

/**
 * Wrapper component for Material UI Box
 *
 * @component
 * @param {Object} props Props defined by Material UI. Please see Material UI documentation for
 * API:
 *
 * - [Box API](https://mui.com/material-ui/api/box/)
 * @param {boolean} props.styledBox Prop applies custom styles to Box component.
 * @return {JSX.Element}
 */

export const Box = (props) => {

  const customStyles = {
    backgroundColor: "white",
    borderRadius: "0.5rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingBottom: "1.5rem",
    ...props.sx
  };

  return (
    <MUIBox
      data-cy="box"
      style={props.styledBox ? customStyles : props.sx}
      {...props}
    >
      {props.children}
    </MUIBox>
  );
};
