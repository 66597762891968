import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { setIsTimeoutTimerActive } from "../../store/activity/activity.slice";
import { postTokenRefresh, setTimeoutMessage } from "../../store/auth/auth.slice";
import { TIMEOUT_ERR_MSG } from "../constants/constants";
import { DayjsUtcLocal } from "./dayjsHelpers";

dayjs.extend(isSameOrAfter);

const {
  REACT_APP_MODAL_HEARTBEAT_TIMER
} = process.env;

const oneSecond = 1000;

const hasActivityOccurred = (activityStateTimestamp, navActivityTimestamp) => {
  const now = DayjsUtcLocal();
  const formActivity = dayjs(activityStateTimestamp);
  const navActivity = dayjs(navActivityTimestamp);
  const mostRecentActivity = formActivity.isSameOrAfter(navActivity) ? formActivity : navActivity;
  const timeElapsed = now - mostRecentActivity;
  const timeoutValue = Number(REACT_APP_MODAL_HEARTBEAT_TIMER) * oneSecond;
  return timeElapsed < timeoutValue;
};

const determineTimeToLive = (heartbeatTimeStamp, now) => {
  // if the lastActivityTime state value changes, need to clear the old timer and set new one for
  // the remainder of the time with the new state value since the old timer has old value.
  let timeoutValue = Number(REACT_APP_MODAL_HEARTBEAT_TIMER) * oneSecond;
  if (heartbeatTimeStamp) {
    const diff = heartbeatTimeStamp - now;
    if (diff > 0 && diff < timeoutValue) {
      timeoutValue = diff;
    }
  }

  return timeoutValue;
};

export const createHeartbeatTimer = (
  isHeartbeatTimerActive,
  lastActivityTime,
  heartbeatTimeStamp,
  presentRef,
  token,
  setHeartbeatTimerActive,
  dispatch,
  setHeartbeatTimeStamp) => {

  const now = DayjsUtcLocal();
  const timeoutValue = determineTimeToLive(heartbeatTimeStamp, now);

  // create the timer if active
  let timerHandle = null;
  if (isHeartbeatTimerActive) {
    timerHandle = setTimeout(() => {
      setHeartbeatTimerActive(false);
      if (hasActivityOccurred(lastActivityTime, presentRef.current.time)) {
        dispatch(setIsTimeoutTimerActive(false));
        dispatch(postTokenRefresh(token))
          .unwrap()
          .then(() => {
            setHeartbeatTimerActive(true);
            dispatch(setIsTimeoutTimerActive(true));
          })
          .catch((err) => {
            if (err.status === 403) {
              dispatch(setTimeoutMessage(TIMEOUT_ERR_MSG));
            }
          });
      } else {
        setTimeout(() => setHeartbeatTimerActive(true), 1);
      }
    }, timeoutValue);
    setHeartbeatTimeStamp(now + timeoutValue);
  }

  return timerHandle;
};
