import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Title from "../../components/Title";
import { setFlow, setShouldClearBreadcrumbs } from "../../store/auth/auth.slice";
import { REGISTERED, REGISTER_NEW_USER } from "../../utils/constants/constants";
import SuccessIcon from "../../views/assets/success.svg";

const SuccessPage = (props) => {
  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;

  const { user } = useSelector(authState);
  const { config } = useSelector(institutionState);

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setFlow(REGISTERED));
    dispatch(setShouldClearBreadcrumbs(false));
  };

  const isRegisteredFlow = (props.flow === REGISTER_NEW_USER);
  const isNonValidationUser = user.role === "provisional-user";
  const hasFiContactDetails = config?.brand_name && config?.brand_phone;
  const fiContactDetails = hasFiContactDetails ? `${config?.brand_name} at ${config?.brand_phone}.` : "your institution.";
  const nonValidationSuccessText = `An employee at ${config?.brand_name} will review the information you submitted
  shortly. If you do not receive an email at the address you provided within two banking days, please contact ${fiContactDetails}`;

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "1.5rem" }}>
        <img src={SuccessIcon} alt="success icon" />
      </Box>
      <Title
        title={props.title}
        subTitle={isNonValidationUser ? "" : props.subTitle}
        showDivider={false}
        isCentered={true}
      />
      {isNonValidationUser ? <Typography style={{ margin: "20px 0px" }}>{nonValidationSuccessText}</Typography> : null}
      {
        isRegisteredFlow
          ?
          <Grid container justifyContent="center">
            <Grid item xs={0} md={3} display={{ xs: "none", md: "" }} />
            <Grid item xs={12} md={6} sx={{ padding: "1.5rem 0px" }}>
              <Button
                color="primary"
                aria-label="continue to accounts page"
                component={RouterLink}
                to="/accounts"
                fullWidth
                size="large"
                variant="contained"
                type="continue"
                onClick={handleClick}
              >
                Continue
              </Button>
            </Grid>
            <Grid item xs={0} md={3} display={{ xs: "none", md: "" }} />
          </Grid>
          : null
      }
    </>
  );
};

export default SuccessPage;
