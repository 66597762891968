import React from "react";
import { Dialog as MUIDialog } from "@mui/material";

/**
 * Wrapper component for Material UI Dialog
 *
 * @component
 * @param {Object} props Props defined by Material UI. Please see Material UI documentation for
 * API:
 *
 * - [Dialog API](https://mui.com/material-ui/api/dialog/)
 * @return {JSX.Element}
 */

export const Dialog = (props) => (
  <MUIDialog
    data-cy="dialog"
    {...props}
  >
    {props.children}
  </MUIDialog>
);
