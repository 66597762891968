import React from "react";
import { visuallyHidden } from "@mui/utils";

/**
 * Modal Announcer is a visually hidden component, read by screen readers.
 * @component
 * @param   {string} modalName Name of the modal announcer.
 * @return  {JSX.Element} Visually hidden modal announcer.
 */

const ModalAnnouncer = ({ modalName }) => (
  <output
    data-cy="announcer"
    style={visuallyHidden}
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    {modalName && `A ${modalName} modal has taken screen focus.`}
  </output>
);

export default ModalAnnouncer;
