import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import { SwivelContextProvider } from "./services/sdk/context/SwivelProvider";
import { persistor, store } from "./store";

const queryClient = new QueryClient();
const renderApp = async () => {

  const LaunchDarklyProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID,
    options: {
      streaming: true
    }
  });

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <LaunchDarklyProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <SwivelContextProvider>
                  <App />
                </SwivelContextProvider>
              </QueryClientProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </LaunchDarklyProvider>
    </React.StrictMode>
  );
};

renderApp();
