export const getUserFriendlyResponse = (error) => {
  switch (error?.status) {
    case 400:
      switch (error?.data?.errorMessage) {
        case "400: You cannot reuse that password":
          return "Cannot reuse old passwords, for security.";
        case "400: New password does not meet password strength requirements.":
          return "Your new password was not strong enough.";
        default:
          return "You did not submit a valid request.";
      }
    case 403:
      switch (error?.data?.errorMessage) {
        case "403: the link is no longer valid":
          return "This link is no longer associated";
        case "403: jwt expired":
          return "This reset link is expired. Request a new one.";
        default:
          return "Malformed or invalid reset link. Request a new one.";
      }
    default:
      return "There was an error resetting your password.";
  }
};
