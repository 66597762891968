import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button, ListItem } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, AlertTitle, Box, List, ListItemIcon, ListItemText, Typography, Grid } from "../../components";
import { StyledCircularProgress } from "../../components/styled/StyledCircularProgress";
import Title from "../../components/Title";
import { useGoogleAnalytics } from "../../services/analytics/hooks/useGoogleAnalytics";
import {
  postPayment,
  setSubmitting,
  setConfirmationNum,
  setPendingTransactions
} from "../../store/payment/makePayment.slice";
import { constructAchPayload } from "../../utils/helpers/constructAchPayload";
import { constructCardPayload } from "../../utils/helpers/constructCardPayload";
import { getAccountLastFour } from "../../utils/helpers/handleAccounts";

const PendingPayments = () => {
  const authState = (state) => state.auth;
  const getPaymentState = (state) => state.payment;
  const loanState = (state) => state.loans;
  const institutionState = (state) => state.institution;

  const { form, loan, isSubmitting, pendingTransactions, paymentType, paymentDetails, error: paymentError } = useSelector(getPaymentState);
  const { authToken } = useSelector(authState);
  const { list: loans } = useSelector(loanState);
  const { config } = useSelector(institutionState);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { trackEvent, actions, categories, trackGtagEvent } = useGoogleAnalytics();

  const pendingTransactionLength = pendingTransactions.length || 0;
  const transactionVerbiage = pendingTransactionLength < 2 ? "payment" : "payments";
  const paymentInQuestion = loans.find((l) => l.number === loan.number);
  const alertHeading = `${paymentInQuestion.type} account ending in ${getAccountLastFour(loan.number)}`;
  const pendingAlertMessage = `It looks like you have ${pendingTransactionLength} pending ${transactionVerbiage} for this account.`;
  const isCardPayment = paymentType.toLowerCase() === "card";
  const gaAction = isCardPayment ? actions.CARD_PAYMENT : actions.ACH_PAYMENT;

  const submitPayment = () => {
    const isCardPayment = paymentType.toLowerCase() === "card";
    const achPayload = !isCardPayment ? { ...paymentDetails } : {};
    const payload = isCardPayment
      ? constructCardPayload(form, pendingTransactions, loan.id, authToken.value, loan?.idempotencyToken, paymentDetails, config)
      : constructAchPayload(achPayload, pendingTransactions, loan.id, authToken.value, loan?.idempotencyToken, form.convFee, config);
    dispatch(setSubmitting(true));
    dispatch(postPayment(payload))
      .unwrap()
      .then((response) => {
        if (response.tracking) {
          dispatch(setConfirmationNum(response.tracking));
          trackEvent(gaAction, categories.ATTEMPT_SUCCESS);
          navigate("/payment-confirmed");
        } else {
          dispatch(setPendingTransactions(response.PendingTransactions));
        }
      })
      .catch((error) => {
        trackGtagEvent(gaAction, categories.ATTEMPT_FAILURE, { event_details: error?.message });
      });
  };

  const getPaymentItem = (submissionDate, amount) => {
    const creationDate = dayjs(submissionDate).format("MMMM DD YYYY");
    const paymentAmount = amount ? amount.toFixed(2) : 0;
    const message = `${creationDate} - $ ${paymentAmount}`;
    return message;
  };

  const styles = {
    border: "2px solid orange",
    margin: "2rem 0px",
    display: "block"
  };

  return (
    <>
      <Title title="Pending Payments" />
      <Alert
        message={paymentError?.response}
        severity={paymentError?.severity}
      />
      <Box component="section" sx={{ width: "100%" }}>
        <Alert
          color="warning"
          icon={false}
          role="status"
          severity="warning"
          sx={styles}
          disableIcon
        >
          <AlertTitle>
            <Box sx={{ display: "flex" }}>
              <WarningAmberIcon
                color="warning"
                fontSize="large"
                sx={{ margin: ".5rem" }}
              />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography component="h2" variant="h3">
                  {alertHeading}
                </Typography>
              </Box>
            </Box>
            <Typography variant="subtitle1" component="p">
              {pendingAlertMessage}
            </Typography>
          </AlertTitle>
          <List component="ul">
            {pendingTransactions.map((trans, i) => (
              <ListItem key={i} disableGutters>
                <ListItemIcon sx={{ minWidth: "1.5rem" }}>
                  <CircleIcon color="warning" sx={{ fontSize: ".8rem" }} />
                </ListItemIcon>
                <ListItemText component="li">
                  {getPaymentItem(trans.OriginationDate, trans.Amount)}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Alert>
      </Box>
      <Grid container spacing={2} columns={16} direction={{ xs: "column-reverse", md: "row" }}>
        <Grid item xs={0} md={8} display={{ md: "none" }} />
        <Grid item xs={16} md={8}>
          <Box sx={{ position: "relative" }}>
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              size="large"
              disabled={isSubmitting}
              aria-label="Click to cancel the payment."
              onClick={() => {
                trackEvent(gaAction, categories.ATTEMPT_ABANDONED);
                navigate("/accounts");
              }}>
              Cancel the Payment
            </Button>
          </Box>
        </Grid>
        <Grid item xs={16} md={8} sx={{ position: "relative" }}>
          <Box sx={{ position: "relative" }}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              size="large"
              aria-label="Click to confirm the payment after reviewing the pending payments."
              disabled={isSubmitting}
              onClick={() => submitPayment()}>
              Confirm Pending Payments
            </Button>
            {isSubmitting ? <StyledCircularProgress /> : null}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PendingPayments;
