import React from "react";
import { Box, Typography } from "../../../components/mui";
import Title from "../../../components/Title";

const LinkExpired = () => {

  return (
    <>
      <Title
        title="Link Expired"
        subTitle="This link has expired. Please request a new link to reset your password."
        isCentered
      />
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="body1">
              If you did not receive an email, then please try again.
        </Typography>
      </Box>
    </>
  );
};

export default LinkExpired;
