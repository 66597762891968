import React from "react";
import {
  Stack
} from "@mui/material";
import { useSelector } from "react-redux";
import { Box, Typography } from "../../../components/mui";
import Title from "../../../components/Title";
import { useViewPort } from "../../../utils/hooks/useViewPort";

const CheckEmail = () => {
  const authState = (state) => state.auth;
  const { form } = useSelector(authState);

  const viewPort = useViewPort();

  return (
    <>
      <Title
        title="Check Your Email"
        subTitle={`We have sent an email to ${form?.emailAddress} with the instructions to reset your password.`}
        isCentered={viewPort.mobile}
      />
      <Box sx={{ width: "100%" }}>
        <Stack justifyContent="center" alignItems="center">
          <Typography>
               If you did not receive an email then please try again.
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default CheckEmail;
