import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { visuallyHidden } from "@mui/utils";

export const AutoCompleteDropdown = (props) => {
  // Local State
  const [open, setOpen] = useState(false);

  // Pass props and remove values that are unused by autocomplete
  const autoCompleteProps = { ...props };
  delete autoCompleteProps.error;
  delete autoCompleteProps.helperText;
  delete autoCompleteProps.registerAutoComplete;
  delete autoCompleteProps.registerTextField;
  delete autoCompleteProps.stateAnnouncement;

  // Configs
  const filterOptions = {
    matchFrom: "start",
    trim: true,
    ignoreCase: true
  };

  // Handlers
  const handleOnKeyPress = (event) => {
    event.key === "Enter" ? event.preventDefault() : null;
    setOpen(true);
  };

  const handleOnKeyDown = (event) => {
    const keys = ["ArrowUp", "ArrowDown", "Backspace", "Delete"];
    keys.includes(event.key) ? setOpen(true) : null;
    if (open && (event.key === "Tab")) {
      event.preventDefault();
      setOpen(true);
    }
  };

  // Render Textfield
  const renderInput = (params) => (
    <TextField
      {...props.registerTextField}
      {...params}
      aria-label={props["aria-label"]}
      inputProps={{ ...params.inputProps, "aria-required": "true" }}
      inputRef={params.InputProps.ref}
      label={props.label}
      onKeyPress={handleOnKeyPress}
      onKeyDown={handleOnKeyDown}
      required
      error={!!props?.error || !!props?.errors}
      helperText={props?.helperText || props?.errors?.message || " "}
      sx={{ zIndex: 0 }}
    />
  );

  return (
    <>
      <output style={visuallyHidden}>
        {props.stateAnnouncement && `${props.stateAnnouncement} selected`}
      </output>
      <Autocomplete
        {...props.registerAutoComplete}
        {...autoCompleteProps}
        autoComplete
        autoHighlight
        autoSelect
        clearOnBlur
        clearOnEscape
        disableClearable
        forcePopupIcon
        handleHomeEndKeys
        noOptionsText="No match found"
        selectOnFocus
        filterOptions={createFilterOptions(filterOptions)}
        onClose={() => setOpen(false)}
        options={autoCompleteProps?.options || []}
        renderInput={renderInput}
      />
    </>
  );
};
