import React from "react";
import { CircularProgress as MUICircularProgress } from "@mui/material";

/**
 * Wrapper component for Material UI CircularProgress
 * @component
 * @param {Object} props Props defined by Material UI. Please see Material UI documentation for
 * API:
 * - [CircularProgress API](https://mui.com/material-ui/api/circular-progress/)
 * @return {JSX.Element}
 */

export const CircularProgress = (props) => {
  return (
    <MUICircularProgress
      data-cy="circularprogress"
      {...props}
    >
      {props.children}
    </MUICircularProgress>
  );
};
