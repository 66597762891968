import React from "react";
import { ListItemIcon as MUIListItemIcon } from "@mui/material";

/**
 * Wrapper component for Material UI ListItemIcon
 *
 * @component
 * @param {object} props Props defined by Material UI. Please see Material UI documentation for
 * API:
 * - [ListItemIcon API](https://mui.com/material-ui/api/list-item-icon/)
 * @return {JSX.Element}
 */

export const ListItemIcon = (props) => {

  return (
    <MUIListItemIcon
      data-cy="listItemIcon"
      {...props}
    >
      {props.children}
    </MUIListItemIcon>
  );
};
