import React from "react";
import { AccountBalance } from "@mui/icons-material";
import { accountTypeIconMap } from "../../../../utils/data/accountTypeIconMap";
import { useIconSize } from "../../../../utils/hooks/useIconSize";
import { SvgIcon } from "../../../mui";

export const Icon = (props) => {
  const accountCards = accountTypeIconMap;
  const keys = Array.from(accountCards.keys());
  const style = {
    fontSize: useIconSize(),
    color: props?.color || "black"
  };

  props?.type ? props?.type?.toLowerCase().replace(" ", "") : "";
  const key = keys.find(type => props?.type?.toLowerCase().includes(type));

  if (key) {
    const item = accountCards.get(key);
    const label = `${item.value} account icon`;
    return (
      <SvgIcon component={item.icon} sx={style} role="img" aria-hidden="false" aria-label={label}></SvgIcon>
    );
  }

  return (
    <SvgIcon component={AccountBalance} sx={style} role="img" aria-hidden="false" aria-label="account icon"></SvgIcon>
  );
};
