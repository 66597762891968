import React, { forwardRef } from "react";
import { Button, Stack } from "@mui/material";
import dayjs from "dayjs";
import { Typography, Link, Grid } from "../../../../components";
import DomainRedirectDialog from "../../../../components/modals/DomainRedirectDialog";
import { StyledDivider } from "../../../../components/styled/StyledDivider";
import { getAccountLastFour } from "../../../../utils/helpers/handleAccounts";
import { validate } from "../../../../utils/validators/validators";
import InfoRow from "./InfoRow";

const PaymentSummary = ({ payment, payToAccount, institutionDetails, payNow, isDesktop = false }, ref) => {
  const payToInfo = [
    {
      leftSide: "Name:",
      rightSide: payToAccount?.name_on_account
    },
    {
      leftSide: "Account:",
      rightSide: `....${getAccountLastFour(payToAccount?.number)}`
    },
    {
      leftSide: "Type:",
      rightSide: payToAccount?.type
    }
  ];

  const summaryInfo = [
    {
      leftSide: "Payment Date:",
      rightSide: dayjs(payment?.transaction_date || "").format("MMM DD YYYY")
    },
    {
      leftSide: "Payment Amount:",
      rightSide: ((payment?.amount + payment?.fee) || "").toLocaleString("en-US", { style: "currency", currency: "USD" })
    },
    {
      leftSide: "Payment Confirmation:",
      rightSide: payment?.tracking
    }
  ];

  const bankDetails = [
    {
      leftSide: institutionDetails?.brand_address,
      rightSide: null
    },
    {
      leftSide: `${institutionDetails?.brand_city}, ${institutionDetails?.brand_state}`,
      rightSide: null
    },
    {
      leftSide: institutionDetails?.brand_zip,
      rightSide: null
    }
  ];

  return (
    <aside ref={ref} id="summary" role="presentation" tabIndex="-1" style={{ outline: "none", ":focus": { outline: "none" } }}>
      <Grid container>
        <Grid item xs={12} sx={{ height: "3.5rem" }}>
          <Typography variant="h2" noWrap component="h2">Payment Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>

        {/* Pay to */}
        <section style={{ width: "100%" }}>
          <Grid item xs={3}>
            <SectionHeader header="Paid to" />
          </Grid>
          {payToInfo.map((data, i) => (
            <Grid item xs={12} key={i}>
              <InfoRow leftSide={data?.leftSide} rightSide={data?.rightSide} />
            </Grid>
          ))}
        </section>

        {/* Pay from */}
        <SectionSpace />
        <section style={{ width: "100%" }}>
          <Grid item xs={3}>
            <SectionHeader header="Paid By" />
          </Grid>
          <Grid item xs={12}>
            <InfoRow leftSide={isNaN(payment?.tracking) ? "Card" : "ACH"} rightSide={`....${payment?.last_4}`} />
          </Grid>
        </section>

        {/* Summary */}
        <SectionSpace />
        <section style={{ width: "100%" }}>
          <Grid item xs={3}>
            <SectionHeader header="Summary" />
          </Grid>
          {summaryInfo.map((data, i) => (
            <Grid item xs={12} key={i} >
              <InfoRow leftSide={data?.leftSide} rightSide={data?.rightSide} />
            </Grid>
          ))}
        </section>

        {/* Institution Details */}
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
        <section style={{ width: "100%" }}>
          <Grid item xs={12}>
            <SectionHeader header={institutionDetails?.brand_name} />
          </Grid>
          {bankDetails.map((data, i) => (
            <Grid item xs={12} key={i}>
              <InfoRow leftSide={data?.leftSide} rightSide={data?.rightSide} />
            </Grid>
          ))}
        </section>

        {/* Contact */}
        <SectionSpace />
        <section style={{ width: "100%" }}>
          <Grid item xs={12}>
            <SectionHeader header="Contact" />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body1" component="p">Telephone:</Typography>
              <Link href={`tel:${institutionDetails?.brand_phone}`}>{institutionDetails?.brand_phone}</Link>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body1" component="p">Email:</Typography>
              <Link href={`mailto:${institutionDetails?.brand_email}`}>{institutionDetails?.brand_email}</Link>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body1" component="p">Website:</Typography>
              <DomainRedirectDialog
                brand={institutionDetails?.brand_name}
                url={validate.url.getValidUrl(institutionDetails?.brand_support_url)}
                message={
                  `Please note: following this link opens a new browser tab to ${institutionDetails?.brand_support_url},
                  ${institutionDetails?.brand_name}'s website. If you wish to leave, select Proceed. If not, select No Thanks.`
                }
              />
            </Stack>
          </Grid>
        </section>

        {
          (isDesktop && !payToAccount?.inactive && !payToAccount?.autopay) &&
          (
            <>
              <SectionSpace />
              <Grid item xs={12}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  variant="contained"
                  type="submit"
                  aria-label="Pay now and navigate to make payment page"
                  onClick={() => payNow()}
                >
                  Pay now
                </Button>
              </Grid>
            </>
          )
        }

      </Grid>
    </aside>
  );
};

const SectionHeader = ({ header }) => (
  <Typography variant="body1" noWrap sx={{ fontWeight: "bold" }} component="h3">{header}</Typography>
);

const SectionSpace = () => <Grid item xs={12} sx={{ height: "2rem" }} />;

export default forwardRef(PaymentSummary);
