import React, { forwardRef } from "react";
import { Checkbox as MUICheckbox } from "@mui/material";

/**
 * Wrapper component for Material UI Checkbox
 *
 * @component
 * @param {Object} props Props defined by Material UI. Please see Material UI documentation for details
 * API:
 *
 * - [Checkbox API](https://mui.com/material-ui/api/checkbox/)
 * @return {JSX.Element}
 */

const checkboxComponent = (props, ref) => {

  return (
    <MUICheckbox
      ref={ref}
      data-cy="checkbox"
      {...props}
    >
      {props.children}
    </MUICheckbox>
  );
};

export const Checkbox = forwardRef(checkboxComponent);
