import React from "react";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { Box, Typography } from "../../../../components/mui";
import { schema } from "../../../../utils/validators/formHookSchema";
import { sectionStyle } from "../styles";

const AccountDetails = ({
  formErrors,
  register,
  config,
  uniqueIdLookupEnabled
}) => {

  // Alt config
  const altMethods = config?.authentication_methods;
  const altAuth = altMethods?.alternates || [];
  const hasAlternateMethods = Array.isArray(altAuth) && Boolean(altAuth.length);

  let firstAltAuthMethod;

  if (hasAlternateMethods) {
    [firstAltAuthMethod] = altAuth;
  }

  // TODO: Refactor the dynamism of how security questions are rendered with alt config enabled
  const displaySecurityQuestion1 = hasAlternateMethods
    ? !!config?.security_question_1 && firstAltAuthMethod?.use_security_answer_1
    : config?.security_question_1;

  return (
    <Box component="fieldset" sx={sectionStyle} gridArea="right-col">
      <Typography variant="h2" noWrap>Security Questions</Typography>
      {
        uniqueIdLookupEnabled
          ?
          <Link to="/id-lookup">
            I need help finding my {schema.memberId.properties(formErrors, register, config ).label.toLowerCase()}
          </Link>
          :
          null
      }
      {
        hasAlternateMethods
          ? <TextField {...schema.authScheme.properties(formErrors, register, firstAltAuthMethod)} />
          : <TextField {...schema.memberId.properties(formErrors, register, config)} />
      }
      {
        displaySecurityQuestion1
          ? <TextField {...schema.securityQuestionOne.properties(formErrors, register, config)} />
          : null
      }
      {
        config?.security_question_2
          ? <TextField {...schema.securityQuestionTwo.properties(formErrors, register, config)} />
          : null
      }
    </Box>
  );
};

export default AccountDetails;
