import React from "react";
import { Typography as MUITypography } from "@mui/material";

/**
 * Wrapper component for Material UI Typography
 *
 * @component
 * @param {object} props Props defined by Material UI. Please see Material UI documentation for details.
 * @return {JSX.Element}
 */

export const Typography = ( props ) => {

  return (
    <MUITypography
      data-cy="typography"
      {...props}
    >
      {props.children}
    </MUITypography>
  );
};
