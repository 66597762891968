import { states } from "@swbc/swivel-components";
import { REGISTERED } from "../../../utils/constants/constants";
import { getAccountLastFour } from "../../../utils/helpers/handleAccounts";
import { getApprovedPayFromType } from "../../../utils/helpers/lib/getApprovedPayFromType";


export const constructACHPayload = (paymentDetails, loanId, token, idempotencyToken, flow, fee, config) => {
  const paymentAmount = !isNaN(paymentDetails?.amount) ? Number(paymentDetails?.amount) : 0;
  const stateAbbreviation = states.titlecase.find(state => state.name === paymentDetails?.stateOfResidence)?.abbreviation;

  if (flow === REGISTERED) {
    return {
      token,
      account_id: paymentDetails?.selectedPaymentMethod?.id,
      attribution: null,
      known_pending_transaction_numbers: paymentDetails?.pendingTransactions?.map((trans) => trans.TrackingNumber),
      loans: [
        {
          id: loanId,
          amount: paymentAmount
        }
      ],
      payment_date: paymentDetails?.date,
      request_token: idempotencyToken,
      type: "banking",
      state_of_residence: stateAbbreviation,
      fee,
      fee_source: paymentDetails?.convenienceFeeSource,
      brandDetails: {
        brand_phone: config?.brand_phone
      }
    };
  }

  return {
    token,
    attribution: null,
    banking_details: {
      account_number: paymentDetails?.selectedPaymentMethod?.banking_details?.account_number || "",
      business: false,
      name_on_account: paymentDetails?.selectedPaymentMethod?.banking_details?.name_on_account || "",
      routing_number: paymentDetails?.selectedPaymentMethod?.banking_details?.routing_number || "",
      type: paymentDetails?.selectedPaymentMethod?.banking_details?.account_type
    },
    known_pending_transaction_numbers: paymentDetails?.pendingTransactions.map((trans) => trans.TrackingNumber),
    last_4: paymentDetails?.selectedPaymentMethod?.last_4,
    loans: [
      {
        id: loanId,
        amount: paymentAmount
      }
    ],
    request_token: idempotencyToken,
    state_of_residence: stateAbbreviation,
    fee: paymentDetails?.convenienceFee,
    fee_source: paymentDetails?.convenienceFeeSource,
    type: "banking",
    validation_type: paymentDetails?.isFinicityFlow ? "web_validated" : "self_validated",
    vendor: paymentDetails?.isFinicityFlow ? "finicity" : "self",
    brandDetails: {
      brand_phone: config?.brand_phone
    }
  };
};

export const constructSaveAccountPayload = (form, nameOnAccount, isFinicity) => {
  const payload = {
    banking_details: {
      account_number: form?.accountNumber,
      business: false,
      name_of_bank: isFinicity ? form?.nameOfInstitution : form?.name,
      name_on_account: isFinicity ? nameOnAccount : form?.nameOnAccount,
      routing_number: form?.routingNumber,
      type: getApprovedPayFromType(form?.accountType)
    },
    last4: isFinicity ? form?.accountNumberLast4 : getAccountLastFour(form?.accountNumber),
    primary: false,
    type: "banking",
    validation_type: isFinicity ? form?.validationType : "self_validated",
    vendor: isFinicity ? "finicity" : "self"
  };

  if (isFinicity) return { ...payload, verified: true };
  return payload;
};
