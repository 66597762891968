import React from "react";
import { DialogContent as MUIDialogContent } from "@mui/material";

/**
 * Wrapper component for Material UI DialogContent
 *
 * @component
 * @param {Object} props Props defined by Material UI. Please see Material UI documentation for details
 * API:
 *
 * - [DialogContent API](https://mui.com/material-ui/api/dialog-content/)
 * @return {JSX.Element}
 */

export const DialogContent = (props) => {

  return (
    <MUIDialogContent data-cy="dialogContent"  {...props}>
      {props.children}
    </MUIDialogContent>
  );
};
