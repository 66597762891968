import states from "../data/states.json";
import { getAccountLastFour } from "./handleAccounts";
import { getApprovedPayFromType } from "./lib/getApprovedPayFromType";

export const constructAchPayload = (paymentDetails, pendingTransactions, loanId, token, idempotencyToken, fee, config) => {
  const paymentAmount = !isNaN(paymentDetails.amount) ? Number(paymentDetails.amount) : 0;
  const AchDropdown = paymentDetails?.stateOfResidenceAchDropdown;
  const stateAbbreviation = states.find(state => state.longName === AchDropdown)?.name;

  // * registered path (same day and future dated)
  if (paymentDetails.isRegisteredPath) {
    return {
      token,
      account_id: paymentDetails?.payFromSavedAccount,
      attribution: null,
      known_pending_transaction_numbers: pendingTransactions.map((trans) => trans.TrackingNumber),
      loans: [
        {
          id: loanId,
          amount: paymentAmount
        }
      ],
      payment_date: paymentDetails?.date,
      request_token: idempotencyToken,
      type: "banking",
      state_of_residence: stateAbbreviation,
      fee,
      fee_source: paymentDetails.feeSource,
      brandDetails: {
        brand_phone: config?.brand_phone
      }
    };
  }

  return {
    token,
    attribution: null,
    banking_details: {
      account_number: paymentDetails?.accountNumber || "",
      business: paymentDetails?.type?.toLowerCase() === "business" || paymentDetails?.accountType?.toLowerCase()?.includes("business"),
      name_on_account: paymentDetails?.nameOnAccount || "",
      routing_number: paymentDetails?.routingNumber || "",
      type: getApprovedPayFromType(paymentDetails?.accountType)
    },
    known_pending_transaction_numbers: pendingTransactions.map((trans) => trans.TrackingNumber),
    last_4: getAccountLastFour(paymentDetails?.accountNumber),
    loans: [
      {
        id: loanId,
        amount: paymentAmount
      }
    ],
    request_token: idempotencyToken,
    state_of_residence: stateAbbreviation,
    fee,
    fee_source: paymentDetails.feeSource,
    type: "banking",
    validation_type: paymentDetails?.isFinicityFlow ? "web_validated" : "self_validated",
    vendor: paymentDetails?.isFinicityFlow ? "finicity" : "self",
    brandDetails: {
      brand_phone: config?.brand_phone
    }
  };
};

export const constructSaveAccountPayload = (form, nameOnAccount, isFinicity) => {
  const payload = {
    banking_details: {
      account_number: form?.accountNumber,
      business: false,
      name_of_bank: isFinicity ? form?.nameOfInstitution : form?.name,
      name_on_account: isFinicity ? nameOnAccount : form?.nameOnAccount,
      routing_number: form?.routingNumber,
      type: getApprovedPayFromType(form?.accountType)
    },
    last4: isFinicity ? form?.accountNumberLast4 : getAccountLastFour(form?.accountNumber),
    primary: false,
    type: "banking",
    validation_type: isFinicity ? form?.validationType : "self_validated",
    vendor: isFinicity ? "finicity" : "self"
  };

  if (isFinicity) return { ...payload, verified: true };
  return payload;
};
