import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNonBankingDays } from "../../store/nonBankingDays/nonBankingDays.slice";

/**
 * useNonBankingDays - Custom Hook
 *
 * @return { array<string> } an Array of non-banking days
 * @example
 * const nonBankingDays = useNonBankingDays();
 * console.log(nonBankingDays) // ['01/01/2020', '01/20/2020', '02/17/2020']
 */

export const useNonBankingDays = (nonBankDays) => {
  const mapped = (response) => {
    if (response) {
      const allHolidays = Object?.keys(response)?.map((year) =>
        response[year]?.map(holiday => `${holiday.date.slice(0, 2)}/${holiday.date.slice(2)}/${year}`)
      );
      return allHolidays?.flat();
    }
  };
  const authState = (state) => state.auth;
  const { authToken: { value: token } } = useSelector(authState);
  const [nonBankingDays, setNonBankingDay] = useState(mapped(nonBankDays));
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = false;
    if (!mounted && token && !nonBankingDays) {
      dispatch(getNonBankingDays(token))
        .unwrap()
        .then(res => {
          const response = res?.value;
          setNonBankingDay(mapped(response));
        }).catch(() => {
          setNonBankingDay([]);
        });
    }
    return () => mounted = true;
  }, [dispatch, token, nonBankingDays]);

  return nonBankingDays;
};
