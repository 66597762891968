import React from "react";
import { creditCardBrand, CreditCardForm, MaskTextField } from "@swbc/swivel-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Title from "../../../components/Title";
import { useTransactionDispatch, transactionTypes } from "../../../context";
import { getAccountLastFour } from "../../../utils/helpers/handleAccounts";

const AddCardPage = () => {

  const institutionState = (state) => state.institution;

  const { details } = useSelector(institutionState);

  const dispatch = useTransactionDispatch();
  const navigate = useNavigate();

  const handleAddCardSubmit = (form) => {
    dispatch({
      type: transactionTypes.UPDATE_SELECTED_PAYMENT_METHOD,
      value: {
        type: "card",
        last_4: getAccountLastFour(form.data.card_number),
        label: creditCardBrand(form.data.card_number),
        ...form.data
      }
    });
    navigate("/make-payment");
  };

  const acceptedCardTypes = details?.accepted_card_types;
  const nativeBinList = details?.native_bin_list;
  const nativeBinListError = details?.native_bin_list_error_text;

  return (
    <>
      <Title title="Add Card Details" />
      <CreditCardForm
        // eslint-disable-next-line no-unused-vars
        DateField={({ value, ...props }) => <MaskTextField mask="00/00" {...props} />}
        disableGutters={true}
        onSubmit={handleAddCardSubmit}
        options={{
          whitelistCreditCardBrands: acceptedCardTypes,
          blacklistCreditCardBins: nativeBinList,
          blacklistCreditCardBinsErrorMessage: nativeBinListError
        }}
      />
    </>
  );
};

export default AddCardPage;
