import { useTheme } from "@mui/material";
import { createTheme } from "@mui/material/styles";

// Keep font sizes 1 rem or higher to avoid zoom on input fields on mobile devices
const fontSZ = {
  h1: {
    xs: 1.75,
    sm: 2
  },
  h2: {
    xs: 1.25,
    sm: 1.375,
    md: 1.5
  },
  h3: {
    xs: 1.15,
    sm: 1.25,
    md: 1.375
  },
  h4: {
    xs: 1.05
  },
  body1: {
    // body1 is a P tag
    xs: 1
  }
};

// WCAG 2.1 line hight multiplier -- this is defined as: (font-size * 1.5)
const lineHightMultiplier = 1.5;
const calculateLineHeight = (fontSize) => `${fontSize * lineHightMultiplier}rem`;

export const useMaterialTheme = (config) => {
  const materialTheme = useTheme();
  const defaultHeadingTextColor = "#1D1D1F";
  const defaultBodyTextColor = "#333333";
  const defaultPrimaryButtonColor = "#510C76";
  const defaultSecondaryButtonColor = "#C017A2";
  const defaultTertiaryButtonColor = "#009CA6";
  const defaultErrorColor = "#A91019";

  const primaryButtonColor = config?.primary_button_background_color || defaultPrimaryButtonColor;
  const primaryButtonFontColor = config?.primary_button_font_color || defaultHeadingTextColor;
  const primaryButtonColorFocus = config?.primary_button_color_focus || primaryButtonColor;
  const primaryButtonColorHover = config?.primary_button_color_hover || primaryButtonColor;

  const secondaryButtonColor = config?.secondary_button_background_color || defaultSecondaryButtonColor;
  const secondaryButtonFontColor = config?.secondary_button_font_color || defaultHeadingTextColor;
  const secondaryButtonColorFocus = config?.secondary_button_color_focus || secondaryButtonColor;
  const secondaryButtonColorHover = config?.secondary_button_color_hover || secondaryButtonColor;

  const tertiaryButtonColor = config?.tertiary_button_background_color || defaultTertiaryButtonColor;
  const tertiaryButtonFontColor = config?.tertiary_button_font_color || defaultHeadingTextColor;
  const tertiaryButtonColorFocus = config?.tertiary_button_color_focus || tertiaryButtonColor;
  const tertiaryButtonColorHover = config?.tertiary_button_color_hover || tertiaryButtonColor;

  const linkColor = config?.link_color || defaultHeadingTextColor;
  const focusColor = config?.focus_color || defaultPrimaryButtonColor;


  // baseTheme uses institution config to define the base colors for the Mui Elements
  const baseTheme = createTheme({
    palette: {
      primary: {
        main: primaryButtonColor
      },
      secondary: {
        main: secondaryButtonColor
      },
      tertiary: {
        main: tertiaryButtonColor
      },
      error: {
        main: defaultErrorColor
      }
    }
  });

  // This allows for use of the base colors defined above as options for the MUI element styles
  const styledTheme = createTheme(baseTheme, {
    components: {
      MuiFormLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: focusColor
            }
          },
          asterisk: { color: defaultErrorColor }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: focusColor
            }
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: { fontWeight: "600" }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            border: ".15rem double transparent",
            color: primaryButtonFontColor,
            "&:hover": {
              border: `.15rem double ${defaultHeadingTextColor}`,
              boxShadow: "inset 0px 0px 0px .1rem white",
              background: primaryButtonColorHover,
              color: primaryButtonFontColor
            },
            "&:focus": {
              border: `.15rem double ${defaultHeadingTextColor}`,
              boxShadow: "inset 0px 0px 0px .1rem white;",
              background: primaryButtonColorFocus,
              color: primaryButtonFontColor
            }
          },
          containedSecondary: {
            background: secondaryButtonColor,
            color: secondaryButtonFontColor,
            "&:focus": {
              background: secondaryButtonColorFocus,
              color: secondaryButtonFontColor
            },
            "&:hover": {
              background: secondaryButtonColorHover,
              color: secondaryButtonFontColor
            }
          },
          containedTertiary: {
            background: tertiaryButtonColor,
            color: tertiaryButtonFontColor,
            "&:focus": {
              background: tertiaryButtonColorFocus,
              color: tertiaryButtonFontColor
            },
            "&:hover": {
              background: tertiaryButtonColorHover,
              color: tertiaryButtonFontColor
            }
          },
          // this variant is used exclusively for mobile calendar buttons
          textPrimary: {
            border: ".15rem double transparent",
            color: primaryButtonFontColor,
            background: primaryButtonColor,
            "&:first-child": {
              background: secondaryButtonColor
            },
            "&:hover": {
              border: `.15rem double ${defaultHeadingTextColor}`,
              boxShadow: "inset 0px 0px 0px .1rem white",
              background: primaryButtonColorHover,
              color: primaryButtonFontColor,
              "&:first-child": {
                background: secondaryButtonColorHover,
                color: secondaryButtonFontColor
              }
            },
            "&:focus": {
              border: `.15rem double ${defaultHeadingTextColor}`,
              boxShadow: "inset 0px 0px 0px .1rem white;",
              background: primaryButtonColorFocus,
              color: primaryButtonFontColor,
              "&:first-child": {
                background: secondaryButtonColorFocus,
                color: secondaryButtonFontColor
              }
            }
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: linkColor,
            textDecorationColor: linkColor
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            // background: tertiaryButtonColor,
            // color: tertiaryButtonFontColor,
            border: ".15rem double transparent",
            "&:hover": {
              border: `.15rem double ${defaultHeadingTextColor}`,
              boxShadow: "inset 0px 0px 0px .1rem white;"
            },
            "&:focus": {
              border: `.15rem double ${defaultHeadingTextColor}`,
              boxShadow: "inset 0px 0px 0px .1rem white;"
            }
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              border: `2px solid ${primaryButtonColor}`
            }
          }
        }
      }
    },
    typography: {
      h1: {
        color: defaultHeadingTextColor,
        fontFamily: "Poppins",
        fontSize: `${fontSZ.h1.xs}rem`,
        fontWeight: 700,
        lineHeight: calculateLineHeight(fontSZ.h1.xs),
        [materialTheme.breakpoints.up("sm")]: {
          fontSize: `${fontSZ.h1.sm}rem`,
          lineHeight: calculateLineHeight(fontSZ.h1.sm)
        }
      },
      h2: {
        color: defaultHeadingTextColor,
        fontFamily: "Poppins",
        fontSize: `${fontSZ.h2.xs}rem`,
        fontWeight: 700,
        lineHeight: calculateLineHeight(fontSZ.h2.xs),
        [materialTheme.breakpoints.up("sm")]: {
          fontSize: `${fontSZ.h2.sm}rem`,
          lineHeight: calculateLineHeight(fontSZ.h2.sm)
        },
        [materialTheme.breakpoints.up("md")]: {
          fontSize: `${fontSZ.h2.md}rem`,
          lineHeight: calculateLineHeight(fontSZ.h2.md)
        }
      },
      h3: {
        color: defaultHeadingTextColor,
        fontFamily: "Poppins",
        fontSize: `${fontSZ.h3.xs}rem`,
        fontWeight: 700,
        lineHeight: calculateLineHeight(fontSZ.h3.xs),
        [materialTheme.breakpoints.up("sm")]: {
          fontSize: `${fontSZ.h3.sm}rem`,
          lineHeight: calculateLineHeight(fontSZ.h3.sm)
        },
        [materialTheme.breakpoints.up("md")]: {
          fontSize: `${fontSZ.h3.md}rem`,
          lineHeight: calculateLineHeight(fontSZ.h3.md)
        }
      },
      h4: {
        color: defaultHeadingTextColor,
        fontFamily: "Poppins",
        fontSize: `${fontSZ.h4.xs}rem`,
        fontWeight: 700,
        lineHeight: calculateLineHeight(fontSZ.h4.xs)
      },
      body1: {
        color: defaultBodyTextColor,
        fontFamily: "Inter",
        fontSize: `${fontSZ.body1.xs}rem`,
        lineHeight: calculateLineHeight(fontSZ.body1.xs)
      },
      body2: {
        // Body 2 will be used for required legend message e.g. : * Required Field
        color: defaultBodyTextColor,
        fontFamily: "Inter"
      }
    }
  });

  return styledTheme;
};
