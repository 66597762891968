import React from "react";
import { Button, Grid, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, Icon } from "../../components";
import { useGoogleAnalytics } from "../../services/analytics/hooks/useGoogleAnalytics";
import { setLoan, setLoanPaymentType } from "../../store/payment/makePayment.slice";
import { getAccountLastFour } from "../../utils/helpers/handleAccounts";
import { useViewPort } from "../../utils/hooks/useViewPort";

const AccountCard = (props) => {
  const { type, number, id } = props.data;
  const { config, details, idempotencyToken } = props;

  // Hooks
  const dispatch = useDispatch();
  const viewPort = useViewPort();
  const { trackEvent, actions, categories } = useGoogleAnalytics();

  const accountEnding = getAccountLastFour(number);
  const payNowAria = `Click to make a payment on account ending in ${accountEnding}`;

  const setLoanToPay = () => {
    const loanInfo = { ...props.data, idempotencyToken };
    dispatch(setLoan(loanInfo));
    dispatch(setLoanPaymentType(type));
  };

  return (
    <Grid
      container
      wrap="nowrap"
      direction={viewPort.medium ? "row" : "column"}
      sx={{ display: "flex", gap: 2, width: "100%" }}
    >
      <Grid sx={{ display: "flex", flexDirection: "row", width: "100%" }} item>
        <Stack sx={{ paddingInline: "1rem", justifyContent: "center" }}>
          <Icon type={type} color={config?.primary_button_color} />
        </Stack>

        <Grid sx={{ display: "flex", flexDirection: "column", width: "100%" }} item>
          <Typography
            variant="h2"
            component="h2"
            noWrap
            sx={{
              display: "inline-block",
              textTransform: "capitalize",
              overflow: "hidden",
              textAlign: "left",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}
          >
            {type?.toLowerCase()}
          </Typography>
          <Typography variant="body1" color="text.secondary" >
            {`Account ending in: ${accountEnding}`}
          </Typography>
        </Grid>
      </Grid>
      {details?.apply_to_types?.includes(type) ?
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            gap: 2,
            width: "100%",
            alignSelf: "center"
          }}
        >
          <Button
            id={`AccountButton-${id}`}
            component={Link}
            role="button"
            to={"/make-payment"}
            variant="contained"
            fullWidth
            color="primary"
            aria-label={payNowAria}
            state={{ data: props.info }}
            onClick={() => {
              setLoanToPay();
              trackEvent(actions.BUTTON_CLICK, categories.PAY_NOW);
            }}
            size={viewPort.medium ? "medium" : "small"}
          >
            Pay Now
          </Button>
        </Grid> :
        <Grid sx={{ display: "flex", flexDirection: "column", alignSelf: "end", width: "100%" }} item>
          <Typography variant="body1">
            {`${type} Loan is not a payable Loan. Please contact ${details?.brand_name} support.`}
          </Typography>
        </Grid>
      }
    </Grid >
  );
};

export default AccountCard;
