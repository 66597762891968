import { useViewPort } from "./useViewPort";

export const useIconSize = () => {
  const viewPort = useViewPort();
  const { desktop, tablet, mobile } = viewPort;
  switch (true) {
    case (desktop): return "3rem";
    case (tablet): return "2.5rem";
    case (mobile): return "2rem";
    default: return "2.5rem";
  }
};
