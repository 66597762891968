import React, { useEffect, useState } from "react";
import { TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Box, Typography, Alert } from "../../components";
import { StyledCircularProgress } from "../../components/styled/StyledCircularProgress";
import Title from "../../components/Title";
import { useGoogleAnalytics } from "../../services/analytics/hooks/useGoogleAnalytics";
import { postUniqueIdLookup, resetUniqueId } from "../../store/auth/auth.slice";
import { handleBackNavigation } from "../../store/navhistory/navhistory.slice";
import { useMultipleFormErrors } from "../../utils/hooks/useMultipleFormErrors";
import { schema } from "../../utils/validators/formHookSchema";

const IdLookup = () => {
  const institutionState = (state) => state.institution;
  const authState = (state) => state.auth;
  const navHistoryState = (state) => state.navHistory;

  const { config } = useSelector(institutionState);
  const { uniqueId } = useSelector(authState);
  const { previousLocations } = useSelector(navHistoryState);
  const { trackEvent, actions, categories } = useGoogleAnalytics();

  const [backRoute, setBackRoute] = useState("");
  const [backTitle, setBackTitle] = useState("");


  const { register, handleSubmit, formState: { errors: formErrors } } = useForm({
    mode: "onBlur"
  });

  const { errorMessage } = useMultipleFormErrors(formErrors);

  const dispatch = useDispatch();

  useEffect(() => {
    let pastObj;
    if (previousLocations.length > 0) {
      pastObj = previousLocations[previousLocations.length - 1];
    }
    if (pastObj) {
      setBackTitle(pastObj?.title);
      setBackRoute(pastObj?.params ? pastObj?.route + pastObj?.params : pastObj?.route);
    } else {
      setBackTitle("Login");
      setBackRoute("/login");
    }
  }, [previousLocations]);

  const handleIdLookupSubmit = (form) => {
    const payload = {
      institution_id: config.id,
      security_answer_1: form.securityAnswer1,
      security_answer_2: form.securityAnswer2,
      phone_number: form.phoneNumber
    };

    dispatch(postUniqueIdLookup(payload))
      .unwrap()
      .then(() => {
        trackEvent(actions.UNIQUE_ID_LOOKUP, categories.ATTEMPT_SUCCESS);
      })
      .catch(() => {
        trackEvent(actions.UNIQUE_ID_LOOKUP, categories.ATTEMPT_FAILURE);
      });
  };

  useEffect(() => {
    return () => dispatch(resetUniqueId());
  }, [dispatch]);

  return (
    <>
      <Title
        title={`${config.member_id_terminology ?? "Member Number"} Lookup`}
        subTitle="Enter your phone number and security questions"
      />
      <Alert message={errorMessage} severity="error" />
      {uniqueId.response?.uniqueId ?
        <Alert
          message={`Lookup successful! Your ${config.member_id_terminology ?? "Member Number"} is ${uniqueId.response?.uniqueId}`}
          severity="success" />
        : null}
      {uniqueId.apiError || uniqueId.response?.errorType ?
        <Alert
          message={`Sorry, we are unable to lookup your ${config.member_id_terminology ?? "Member Number"}
         based on the information provided. Please try again or contact your FI at ${config?.brand_phone}.`}
          severity="error" />
        : null}
      <form onSubmit={handleSubmit(handleIdLookupSubmit)} noValidate={true}>
        <Grid
          container
          spacing={3}
          component="fieldset"
          sx={{ border: "none", padding: "0" }}
        >
          <Grid item xs={12}>
            <Box>
              <Typography component="p" variant="body2" noWrap>* Required Field </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} container spacing={1}>
            <Grid item xs={12}>
              <TextField
                autoComplete="tel-national"
                error={!!formErrors?.phoneNumber}
                fullWidth
                helperText={formErrors?.phoneNumber ? formErrors.phoneNumber.message : " "}
                id="phone-number"
                inputProps={{ "aria-required": "true", minLength: 10, maxLength: 10 }}
                label="Phone number"
                required
                variant="outlined"
                {...register("phoneNumber", {
                  maxLength: {
                    value: 10,
                    message: "Phone number must be 10 characters"
                  },
                  minLength: {
                    value: 10,
                    message: "Phone number must be 10 characters"
                  },
                  required: "Phone number is required",
                  pattern: {
                    message: "Phone number can only contain digits",
                    value: /^[0-9]*$/
                  }
                })}
              />
            </Grid>
            {
              config?.security_question_1
                ?
                (
                  <Grid item xs={12}>
                    {config?.security_question_1 && <TextField {...schema.securityQuestionOne.properties(formErrors, register, config)} />}
                  </Grid>
                )
                :
                null
            }
            {
              config?.services.unique_id_lookup.security_question_2.enabled ?
                (
                  <Grid item xs={12}>
                    {config?.security_question_2 && <TextField {...schema.securityQuestionTwo.properties(formErrors, register, config)} />}
                  </Grid>
                )
                :
                null
            }
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={6} order={{ sm: 2, md: 1 }}>
              <Button
                color="secondary"
                fullWidth
                size="large"
                variant="contained"
                aria-label={`Back to ${backTitle}`}
                component={Link}
                to={backRoute}
                onClick={() => dispatch(handleBackNavigation())}
              >
                Back to {backTitle}
              </Button>
            </Grid>
            <Grid item xs={12} md={6} order={{ sm: 1, md: 2 }}>
              <Box sx={{ position: "relative" }}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  variant="contained"
                  type="submit"
                  disabled={uniqueId.isSubmitting}
                >
                  Submit
                </Button>
                {uniqueId.isSubmitting ? <StyledCircularProgress /> : null}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default IdLookup;
