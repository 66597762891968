import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Typography, Button, AutoCompleteDropdown, Grid } from "../../components";
import Title from "../../components/Title";
import { putStateOfResidence } from "../../store/auth/auth.slice";
import states from "../../utils/data/states.json";

const sectionStyle = {
  border: "none",
  display: "flex",
  width: "100%",
  flexDirection: "column",
  gap: "0.5rem",
  margin: "0px",
  padding: "0px"
};

const options = {
  mode: "onBlur",
  reValidateMode: "onChange",
  shouldFocusError: true
};

export const StateOfResidence = () => {
  // Named selectors
  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;

  // Redux State
  const { authToken, stateOfResidence, user } = useSelector(authState);
  const { config } = useSelector(institutionState);

  // Local State
  const [stateAnnouncement, setStateAnnouncement] = useState("");

  // Hooks
  const { watch, register, handleSubmit, setValue, formState: { errors } } = useForm(options);
  const dispatch = useDispatch();

  // Handlers
  const handleStateOfResidence = (data) => {

    const payload = {
      token: authToken?.value,
      user_id: user?.id,
      state_of_residence: data?.stateOfResidenceDropdown,
      brandDetails: {
        brand_phone: config?.brand_phone,
        brand_name: config?.brand_name
      }
    };
    dispatch(putStateOfResidence(payload));
  };

  const isValidState = (value) => {
    const findState = states.find((state) => state?.longName?.toLowerCase() === value?.toLowerCase());
    return (!value || !findState) ? "Invalid value" : null;
  };

  // Watch values
  const watchStateDropdown = watch("stateOfResidenceDropdown", user?.state_of_residence || "");
  const watchStateInput = watch("stateOfResidenceInput", user?.state_of_residence || "");

  return (
    <>
      <Title title="Change State of Residence" />
      <Alert
        message={stateOfResidence?.api?.response?.response}
        severity={stateOfResidence?.api?.response?.severity}
      />
      <Typography
        component="p"
        variant="body2"
        sx={{ marginBlock: "0.5rem" }}
      >
        * Required Field
      </Typography>
      <Grid container component="form" sx={{ gap: "1rem" }} onSubmit={handleSubmit(handleStateOfResidence)} noValidate={true}>
        <fieldset style={sectionStyle}>
          <Typography component="legend" variant="body1" sx={{ marginBottom: "0.875rem" }}>
            Please provide your state of residence.
          </Typography>
          <AutoCompleteDropdown
            registerAutoComplete={{
              ...register("stateOfResidenceDropdown", {
                required: "State of residence is required",
                validate: {
                  state: (value) => isValidState(value)
                }
              })
            }}
            registerTextField={{
              ...register("stateOfResidenceInput", {
                required: "State of residence is required",
                validate: {
                  state: (value) => isValidState(value)
                }
              })
            }}
            aria-label="Please select a state"
            errors={errors?.stateOfResidenceDropdown || errors?.stateOfResidenceInput}
            options={states?.map(ele => ele.longName)}
            getOptionLabel={(option) => option}
            label="State of Residence"
            id="state-of-residence"
            selected={watchStateDropdown.length ? watchStateDropdown : ""}
            onInputChange={(_, value) => {
              setValue("stateOfResidenceInput", value, {
                shouldDirty: true,
                shouldValidate: true
              });
            }}
            onChange={(_, value) => {
              setStateAnnouncement(value);
              setValue("stateOfResidenceDropdown", value, {
                shouldDirty: true,
                shouldValidate: true
              });
            }}
            inputValue={watchStateInput ? watchStateInput : ""}
            value={watchStateDropdown ? watchStateDropdown : null}
            isOptionEqualToValue={(option, value) => (value === "" || value === option)}
            stateAnnouncement={stateAnnouncement}
          />
        </fieldset >
        <Button
          color="primary"
          disabled={stateOfResidence?.api?.isSubmitting || null}
          loading={stateOfResidence?.api?.isSubmitting || null}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Save Changes
        </Button>
      </Grid>
    </>
  );
};
