import { createSlice } from "@reduxjs/toolkit";
import { DayjsUtcLocalStr } from "../../utils/helpers/dayjsHelpers";

const initialState = {
  lastActivityTime: null,
  timeoutModal: {
    isOpen: false,
    isTimerActive: false,
    apiError: null
  }
};

export const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    setActivity: (state) => {
      state.lastActivityTime = DayjsUtcLocalStr();
    },
    clearActivity: () => initialState,
    setIsTimeoutModalOpen: (state, action) => {
      state.timeoutModal.isOpen = action.payload;
    },
    setIsTimeoutTimerActive: (state, action) => {
      state.timeoutModal.isTimerActive = action.payload;
    },
    setTimeoutApiError: (state, action) => {
      state.timeoutModal.apiError = action.payload;
    }
  }
});

export const {
  setActivity,
  clearActivity,
  setIsTimeoutModalOpen,
  setIsTimeoutTimerActive,
  setTimeoutApiError
} = activitySlice.actions;

export default activitySlice.reducer;
