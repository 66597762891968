import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const { REACT_APP_API_URL } = process.env;
const initialState = {
  bankInfo: {
    isLoading: false,
    error: false,
    institution: false
  },
  manuallyLinkForm: {},
  report: {},
  failures: {
    count: 0,
    messages: []
  }
};

export const getBankInfo = createAsyncThunk(
  "GET: /bankinfo/{routingNumber}",
  async (data, { rejectWithValue }) => {
    const { token, routingNumber } = data;
    const config = { headers: { Authorization: token } };
    const res = await axios
      .get(`${REACT_APP_API_URL}/bankinfo/${routingNumber}`, config)
      .then(response => ({
        name: response.data.InstitutionName,
        phone: response.data.InstitutionPhone
      }))
      .catch(error => rejectWithValue({
        status: error?.response?.status || (error?.request?.status || 504),
        data: error?.response?.data
      }));
    return res;
  }
);

export const postReport = createAsyncThunk(
  "POST: /report/web_validation_report",
  async (data, { rejectWithValue }) => {
    const { token, validation_type, vendor, bank_name, institution_id } = data;
    const payload = { validation_type, vendor, bank_name, institution_id };
    const config = { headers: { Authorization: token } };
    const res = await axios
      .post(`${REACT_APP_API_URL}/report/web_validation_report`, payload, config)
      .then((response) => response.data)
      .catch((error) => rejectWithValue({
        status: error?.response?.status || (error?.request?.status || 504),
        data: error?.response?.data
      }));
    return res;
  }
);

export const achDetailsSlice = createSlice({
  name: "achDetails",
  initialState,
  reducers: {
    setBankAccount: (state, { payload }) => {
      state.manuallyLinkForm = payload;
    },
    setBankInfo: (state, { payload }) => {
      state.bankInfo.institution = payload;
    },
    setBankInfoFailure: (state, { payload }) => {
      state.failures.count = payload.count;
      state.failures.messages = [...state.failures.messages, payload.message];
    },
    setBankInfoClearError: (state, { payload }) => {
      state.bankInfo.error = payload;
    },
    setPostReportFailure: (state, { payload }) => {
      state.report.error = payload;
    },
    resetManualLinkState: (state) => {
      state.bankInfo = initialState.bankInfo;
      state.report = initialState.report;
      state.failures = initialState.failures;
      state.manuallyLinkForm = initialState.manuallyLinkForm;
    }
  },
  extraReducers: {
    [getBankInfo.pending]: (state) => {
      state.bankInfo.isLoading = true;
    },
    [getBankInfo.fulfilled]: (state, { payload }) => {
      state.bankInfo.institution = payload;
      state.bankInfo.isLoading = false;
    },
    [getBankInfo.rejected]: (state, { payload }) => {
      state.bankInfo.error = payload;
      state.bankInfo.isLoading = false;
    },
    [postReport.pending]: (state) => {
      state.report.isSubmitting = true;
    },
    [postReport.fulfilled]: (state) => {
      state.report.isSubmitting = false;
      state.report.submitted = true;
    },
    [postReport.rejected]: (state, { payload }) => {
      state.report.error = payload;
      state.report.isSubmitting = false;
    }
  }
});

export const {
  setBankAccount,
  setBankInfo,
  setBankInfoClearError,
  setBankInfoFailure,
  resetManualLinkState,
  setPostReportFailure
} = achDetailsSlice.actions;
export default achDetailsSlice.reducer;
