import { styled } from "@mui/system";
import { CircularProgress as MUICircularProgress } from "../mui/elements/CircularProgress/CircularProgress";

/**
 * Component for displaying a CircularProgress graphic with static styles.
 *
 * @component
 * @param {string} size size of CircularProgress.
 * @param {object} style
 * @return {JSX.Element}
 */

// MUI component
const Styled = styled(MUICircularProgress);

// Styles for the component
const Spinner = ({ size = "1.5rem", style }) => ({
  color: "#1A1A1A",
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: `calc(0rem - (${size} / 2))`,
  marginLeft: `calc(0rem - (${size} / 2))`,
  maxHeight: `${size}`,
  maxWidth: `${size}`,
  ...style
});

export const StyledCircularProgress = Styled(Spinner);
