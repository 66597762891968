export const formStyle = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "1rem"
};

export const sectionStyle = {
  border: "none",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  margin: "0px",
  padding: "0px"
};
