import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const getNonBankingDays = createAsyncThunk(
  "GET: /settings/NonBankingDays",
  async (token, { rejectWithValue }) => {
    const header = { headers: { Authorization: token } };
    const res = await axios
      .get(`${REACT_APP_API_URL}/settings/NonBankingDays`, header)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return rejectWithValue({
          status: error.response.status,
          data: error.response.data,
          isFailed: true
        });
      });
    return res;
  }
);

const initialState = {
  isLoading: false,
  response: [],
  error: {}
};

export const nonBankingDaysSlice = createSlice({
  name: "loans",
  initialState,
  reducers: {},
  extraReducers: {
    [getNonBankingDays.pending]: (state) => {
      state.isLoading = true;
      state.response = null;
      state.error = null;
    },
    [getNonBankingDays.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.response = payload;
      state.error = null;
    },
    [getNonBankingDays.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.response = null;
      state.error = payload;
    }
  }
});

export default nonBankingDaysSlice.reducer;
