import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "../../../components";
import { SwivelContext } from "../context/SwivelProvider";

export const ButtonElement = () => {
  const { isLoading, setPreviousRoute } = useContext(SwivelContext);
  const location = useLocation();

  const handleRoute = () => {
    setPreviousRoute(location.pathname);
  };

  return (
    <Button
      color="secondary"
      component={Link}
      disabled={isLoading}
      loading={isLoading}
      onClick={handleRoute}
      to="/connect-bank"
    >
      CONNECT YOUR BANK
    </Button>
  );
};
