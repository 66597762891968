import styled from "styled-components";

export const Form = styled.form`
  box-sizing: border-box;
  display: grid;
  width: 100%;
  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "form"
    "checkbox"
    "summary"
    "buttons"
  ;

  .inputs-container {
    box-sizing: border-box;
    grid-area: form;
    display: grid;
  }

  .fieldset-container {
    box-sizing: border-box;
    border: none;
    display: grid;
    gap: 0.5rem;
    margin: 0px;
    padding: 0px;
  }

  .checkbox-container {
    grid-area: checkbox;
    padding-block: 1rem;
    border-block: 1px solid #eee;
  }

  .buttons-container {
    display: flex;
    gap: 1rem;
    flex-direction: column-reverse;
    grid-area: buttons;
  }

  .date-selection-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
  }

  ${props => props.materialTheme.breakpoints.up("md")} {
    column-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      "form summary"
      "checkbox checkbox"
      ". buttons"
    ;
    .buttons-container {
      gap: 2rem;
      flex-direction: row-reverse;
    }
    .date-selection-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;
