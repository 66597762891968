import axios from "axios";

const { REACT_APP_API_URL } = process.env;
const options = { headers: { "Content-Type": "application/json" } };

export const sdkSignRequest = ({ event, token }) => {
  options.headers["Authorization"] = token;
  return axios.post(`${REACT_APP_API_URL}/sign`, event, { ...options });
};

export const sdkVerifyResponse = ({ event, token }) => {
  options.headers["Authorization"] = token;
  return axios.post(`${REACT_APP_API_URL}/verify`, { payload: event }, options);
};
