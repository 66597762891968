import styled from "styled-components";

/**
 * Form component with styles.
 *
 * @component
 * @return {JSX.Element}
 */

export const Form = styled.form`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  grid-template-areas:
    "left-col"
    "right-col"
    "button";

  ${props => props.materialTheme.breakpoints.up("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "left-col right-col"
      ". button";
  }
`;

export const TwoColumnForm = styled(Form)`
  grid-template-areas:
    "left-col"
    "right-col"
    "terms-of-service"
    "button";
  ${props => props.materialTheme.breakpoints.up("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "left-col right-col"
      "terms-of-service terms-of-service"
      ". button";
  }
 `;
