import { validate } from "../validators/validators";

export const capitalizeFirstLetter = (val) => {
  const str = String(val);
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const lowerCaseFirstLetter = (val) => {
  const str = String(val);
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const increment = (val) => {
  const num = Number(val);
  return (num || 0) + 1;
};

export const removeStatusCode = (val) => {
  const str = String(val);
  return str.replace(validate.statusCode.value, "");
};

/**
 * @description formats account information into a single line of text
 * @param {String} last4 the last4 of the account
 * @param {String} nameOfBank the bank attached to the account
 * @returns {String} a string formated to -> "Account ending in [LAST_4] with [NAME_OF_BANK]"
 */
export const formatAccount = (last4, nameOfBank) => {
  return `Account ending in ${last4} with ${nameOfBank}`;
};
