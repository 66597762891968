import React from "react";
import { Link as MUILink } from "@mui/material";

/**
 * Wrapper component for Material UI LINK
 *
 * @component
 * @param {object} props Props defined by Material UI. Please see Material UI documentation for
 * API:
 * - [Link API](https://mui.com/material-ui/api/link/)
 * @return {JSX.Element}
 */

export const Link = (props) => {
  return (
    <MUILink
      data-cy="link"
      {...props}
    >
      {props.children}
    </MUILink>
  );
};
