import React from "react";
import { Typography } from "../../../../components/mui";
import { dailyCutoffTimeFormat } from "../../../../utils/helpers/dayjsHelpers";

const Disclosure = ({ displayTodaysDate, futureDate, details, form, accountLastFour, accountVerbiage }) => {

  return (
    <>
      <Typography variant="body1" component="p">
        You are authorizing {details.brand_name} to initiate a single payment {accountVerbiage} in the amount of ${Number(form.total).toFixed(2)},
        which includes a convenience fee of ${Number(form.convFee).toFixed(2)}, dated
        on or after {futureDate || displayTodaysDate}.
      </Typography>
      <Typography variant="body1" component="p">
        This amount excluding the convenience fee, will be applied to your {details.brand_name} account ending in {accountLastFour}.
      </Typography>
      <Typography variant="body1" component="p">
        Payments submitted after {dailyCutoffTimeFormat(details.daily_cutoff_time)} Central Time will be processed within 3-5 business days.
      </Typography>
      <Typography variant="body1" component="p">
        This authorization may be revoked, prior to the processing of this payment,
        by contacting {details.brand_name} at {details.brand_phone}.
      </Typography>
    </>
  );
};

export default Disclosure;
