import {
  CreditCard,
  DirectionsBoatFilled,
  DirectionsCarFilled,
  House,
  AccountBalance
} from "@mui/icons-material";

export const accountTypeIconMap = new Map([
  ["auto", { icon: DirectionsCarFilled, value: "auto" }],
  ["boat", { icon: DirectionsBoatFilled, value: "boat" }],
  ["creditcard", { icon: CreditCard, value: "card" }],
  ["mortgage", { icon: House, value: "mortgage" }],
  ["banking", { icon: AccountBalance, value: "banking" }]
]);
