import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const { REACT_APP_API_URL } = process.env;


export const signRequest = createAsyncThunk(
  "POST: /sign",
  async (payload, thunkAPI) => {
    const { first_name, last_name, email_address, ach_web_verification_id, token, experience } = payload;
    const requestPayload = {
      user: {
        first_name,
        last_name,
        username: email_address
      },
      ACHWebVerificationAccountId: ach_web_verification_id,
      experience
    };
    const header = { headers: { Authorization: token, "Content-Type": "application/json" } };
    const response = await axios.post(`${REACT_APP_API_URL}/sign`, requestPayload, header)
      .then((res) => {
        return res.data.signedMessage;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(JSON.parse(JSON.stringify(error)));
      });
    return response;
  }
);

export const verifyResponse = createAsyncThunk(
  "POST: /verify",
  async (payload, thunkAPI) => {
    const { token, message } = payload;
    const header = { headers: { Authorization: token, "Content-Type": "application/json" } };

    const response = await axios.post(`${REACT_APP_API_URL}/verify`, { payload: message }, header)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(handleError(error));
      });
    return response;
  }
);

const handleError = (error) => {
  const errorMessages = {
    user_cancelled: "You have exited bank account verification.",
    user_timed_out: "Your session has timed out",
    not_acceptable: "Please login to your online bank account and select the Statements option to authorize your account to be added"
  };

  const redirectErrors = [
    "failed_dependency",
    "institution_not_found",
    "institution_not_supported",
    "user_timed_out",
    "setup_error",
    "error"
  ];

  const response = {
    type: undefined,
    message: "",
    errorType: error?.errorType,
    shouldRedirect: false
  };

  if (redirectErrors.includes(error.errorType.toLowerCase())) {
    response.shouldRedirect = true;
  } else {
    response.message = errorMessages[error.errorType.toLowerCase()] || "There was a problem adding your account.";
  }

  return response;
};



const initialState = {
  sign: {
    isLoading: false,
    message: null,
    error: null,
    isSigned: false
  },
  verify: {
    isSubmitting: false,
    accountDescription: "",
    account: null,
    error: null,
    isVerified: false
  }
};

export const achVerificationSlice = createSlice({
  name: "achVerification",
  initialState,
  reducers: {
    setAccountDescription: (state, action) => {
      state.verify.accountDescription = action.payload;
    },
    clearVerificationState: () => initialState
  },
  extraReducers: {
    [signRequest.pending]: (state) => {
      state.sign.isLoading = true;
      state.sign.error = null;
    },
    [signRequest.fulfilled]: (state, action) => {
      state.sign.isLoading = false;
      state.sign.isSigned = true;
      state.sign.message = action.payload;
    },
    [signRequest.rejected]: (state, action) => {
      state.sign.isLoading = false;
      state.sign.error = action.payload;
    },
    [verifyResponse.pending]: (state) => {
      state.verify.isSubmitting = true;
      state.verify.error = null;
    },
    [verifyResponse.fulfilled]: (state, action) => {
      state.verify.isSubmitting = false;
      state.verify.account = action.payload;
    },
    [verifyResponse.rejected]: (state, action) => {
      state.verify.isSubmitting = false;
      state.verify.error = action.payload;
    }
  }
});

export const { setAccountDescription, clearVerificationState } = achVerificationSlice.actions;
export default achVerificationSlice.reducer;
