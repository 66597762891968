import { setEmail } from "../../store/auth/auth.slice";
import formValidationRules from "../constants/validationRules";
import { validate } from "./validators";

export const schema = {
  defaultProperties: (dataId) => ({
    fullWidth: true,
    inputProps: { "aria-required": true, "data-cy": dataId },
    required: true,
    variant: "outlined"
  }),
  firstName: {
    properties: (formErrors, register) => ({
      ...schema.defaultProperties("firstName"),
      ...register("firstName", schema.firstName.options),
      autoComplete: "given-name",
      error: !!formErrors?.firstName,
      helperText: formErrors?.firstName?.message || " ",
      id: "firstName",
      label: "First name",
      variant: "outlined"
    }),
    options: {
      maxLength: { value: 40, message: "First name must be less than 40 characters" },
      required: "First name is required",
      pattern: validate.name
    }
  },
  lastName: {
    properties: (formErrors, register) => ({
      ...schema.defaultProperties("lastName"),
      ...register("lastName", schema.lastName.options),
      autoComplete: "family-name",
      error: !!formErrors?.lastName,
      helperText: formErrors?.lastName?.message || " ",
      id: "lastName",
      label: "Last name"
    }),
    options: {
      maxLength: { value: 40, message: "Last name must be less than 40 characters" },
      required: "Last name is required",
      pattern: validate.name
    }
  },
  email: {
    properties: (formErrors, register, defaultValue, dispatch) => ({
      ...schema.defaultProperties("email"),
      ...register("emailAddress", schema.email.options(dispatch)),
      autoComplete: "email",
      defaultValue,
      error: !!formErrors?.emailAddress,
      helperText: formErrors?.emailAddress?.message || " ",
      id: "email-address",
      inputMode: "email",
      label: "Email",
      type: "email"
    }),
    options: (dispatch) => ({
      maxLength: {
        value: formValidationRules.emailMaxLength,
        message: `Email address must be less than ${formValidationRules.emailMaxLength} characters`
      },
      required: "Email address is required",
      pattern: validate.email,
      onChange: (e) => dispatch(setEmail(e.target.value))
    })
  },
  memberId: {
    properties: (formErrors, register, config) => ({
      ...schema.defaultProperties("memberId"),
      ...register("memberId", schema.memberId.options(config)),
      autoComplete: "off",
      error: !!formErrors?.memberId,
      helperText: formErrors?.memberId?.message || " ",
      id: "memberId",
      label: config?.member_id_terminology
    }),
    options: (config) => ({
      required: config?.no_validation_file?.member_id?.required ? `${config?.member_id_terminology} is required` : false,
      pattern: {
        value: new RegExp(config?.services?.registered?.fields?.member_id?.regex || "^.+$"),
        message: config?.services?.registered?.fields?.member_id?.error_text || ""
      }
    })
  },
  authScheme: {
    properties: (formErrors, register, config) => ({
      ...schema.defaultProperties("authScheme"),
      ...register("authScheme", schema.authScheme.options(config)),
      autoComplete: "off",
      error: !!formErrors?.authScheme,
      helperText: formErrors?.authScheme?.message || " ",
      id: "authScheme",
      label: config?.label
    }),
    options: (config) => ({
      required: `${config?.label} is required`,
      pattern: {
        value: new RegExp("^.+$"),
        message: "Invalid input"
      }
    })
  },
  securityQuestionOne: {
    properties: (formErrors, register, config) => ({
      ...schema.defaultProperties("securityAnswer1"),
      ...register("securityAnswer1", schema.securityQuestionOne.options(config)),
      autoComplete: "off",
      error: !!formErrors?.securityAnswer1,
      helperText: formErrors?.securityAnswer1?.message || " ",
      id: "securityAnswer1",
      label: config?.security_question_1
    }),
    options: (config) => ({
      required: `${config?.security_question_1} is required`,
      pattern: {
        value: new RegExp(config?.security_question_1_regex || "^.+$"),
        message: "Invalid input"
      }
    })
  },
  securityQuestionTwo: {
    properties: (formErrors, register, config) => ({
      ...schema.defaultProperties("securityAnswer2"),
      ...register("securityAnswer2", schema.securityQuestionTwo.options(config)),
      autoComplete: "off",
      error: !!formErrors?.securityAnswer2,
      helperText: formErrors?.securityAnswer2?.message || " ",
      id: "securityAnswer2",
      label: config?.security_question_2
    }),
    options: (config) => ({
      required: `${config?.security_question_2} is required`,
      pattern: {
        value: new RegExp(config?.security_question_2_regex || "^.+$"),
        message: "Invalid input"
      }
    })
  },
  nonValidationField: {
    properties: (formErrors, register, properties) => ({
      ...register(`registration_fields_${properties.index}`, schema.nonValidationField.options(properties)),
      fullWidth: true,
      inputProps: { "aria-required": properties?.required },
      required: properties?.required,
      variant: "outlined",
      autoComplete: "off",
      error: !!formErrors?.[`registration_fields_${properties.index}`],
      helperText: formErrors?.[`registration_fields_${properties.index}`]?.message || " ",
      id: `registration_fields_${properties.index}`,
      label: properties?.label,
      type: properties?.secure ? "password" : ""
    }),
    options: (properties) => ({
      required: properties?.required ? "Registration Question is required and must be in the correct format." : false,
      pattern: {
        value: new RegExp(properties?.regex || "^.+$"),
        message: "Invalid input"
      }
    })
  },
  password: {
    properties: (formErrors, register, isPasswordVisible) => ({
      ...schema.defaultProperties("password"),
      ...register("password", schema.password.options),
      error: !!formErrors?.password,
      helperText: formErrors?.password?.message || " ",
      id: "password",
      inputMode: "text",
      label: "Password",
      type: isPasswordVisible ? "text" : "password",
      autoComplete: "current-password"
    }),
    options: {
      maxLength: {
        value: formValidationRules.passwordMaxLength,
        message: `Password must be less than ${formValidationRules.passwordMaxLength} characters`
      },
      required: "Password is required"
    }
  },
  checkBox: {
    properties: {
      required: "You must agree to the Terms of Service."
    }
  }
};
