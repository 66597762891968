import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const getInstitution = createAsyncThunk(
  "GET: /institution/{id}",
  async (institutionId, { rejectWithValue }) => {
    const res = await axios
      .get(`${REACT_APP_API_URL}/institution/${institutionId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error?.response?.data);
      });
    return res;
  }
);

export const getInstitutionDetails = createAsyncThunk(
  "GET: /institution/{id}/details",
  async (data, { rejectWithValue }) => {
    const res = await axios
      .get(`${REACT_APP_API_URL}/institution/${data.institutionId}/details`, {
        headers: { Authorization: data.token }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error?.response?.data);
      });
    return res;
  }
);

const initialState = {
  config: null,
  details: null,
  detailsLoaded: false,
  detailsError: null,
  isLoading: true,
  isInstitutionDetailsLoading: false,
  institutionError: null
};

export const institutionSlice = createSlice({
  name: "institution",
  initialState,
  reducers: {
    setLoadingState: (state, action) => {
      state.isLoading = action?.payload ?? false;
    }
  },
  extraReducers: {
    [getInstitution.pending]: (state) => {
      state.isLoading = true;
    },
    [getInstitution.fulfilled]: (state, action) => {
      if (action?.payload?.results) {
        const [results] = action.payload.results;
        state.config = results;
      }
      state.isLoading = false;
    },
    [getInstitution.rejected]: (state, action) => {
      state.isLoading = false;
      state.institutionError = action?.payload || action;
    },
    [getInstitutionDetails.pending]: (state) => {
      state.isInstitutionDetailsLoading = true;
    },
    [getInstitutionDetails.fulfilled]: (state, action) => {
      if (action?.payload?.results) {
        const [results] = action.payload.results;
        state.details = results;
      }
      state.detailsLoaded = true;
      state.isInstitutionDetailsLoading = false;
    },
    [getInstitutionDetails.rejected]: (state, action) => {
      state.isInstitutionDetailsLoading = false;
      state.detailsError = action?.payload || action;
    }
  }
});

export const { setLoadingState } = institutionSlice.actions;
export default institutionSlice.reducer;
