import styled from "styled-components";

export const SummaryContainer = styled.aside`
  box-sizing: border-box;
  grid-area: summary;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 1rem;
  width: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
