import React from "react";
import { MenuItem as MUIMenuItem } from "@mui/material";

/**
 * Wrapper component for Material UI MenuItem
 *
 * @component
 * @param {Object} props Props defined by Material UI. Please see Material UI documentation for details
 * API:
 *
 * - [Checkbox API](https://mui.com/material-ui/api/menu-item/)
 * @return {JSX.Element}
 */

export const MenuItem = (props) => {

  return (
    <MUIMenuItem
      data-cy="menuitem"
      {...props}
    >
      {props.children}
    </MUIMenuItem>
  );
};
