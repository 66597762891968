import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { addBreadCrumb } from "../../../store/navhistory/navhistory.slice";
import { routeMap } from "../../../utils/data/routeMap";
import { DayjsUtcLocal } from "../../../utils/helpers/dayjsHelpers";
import CreatePassword from "../../Password/CreatePassword";
import LinkExpired from "../LinkExpired";

const ResetPassword = () => {

  const dispatch = useDispatch();
  const [searchParams] =  useSearchParams();
  const [token] = useState(searchParams.get("token"));

  useEffect(() => {
    const routeItem = routeMap.get("/login");
    const dataForCurrentLocation = {
      title: routeItem.title,
      route: routeItem.route
    };
    dispatch(addBreadCrumb(dataForCurrentLocation));
    return undefined;
  }, []);

  const validateIsTokenValid = () => {
    if (!token) return false;

    const expirationTime = dayjs(jwtDecode(token).exp * 1000);
    const today = DayjsUtcLocal();
    return today.isBefore(expirationTime);
  };

  if (!validateIsTokenValid()) {
    return <LinkExpired />;
  }

  return (
    <CreatePassword isResetPasswordFlow urlToken={token} />
  );
};

export default ResetPassword;
