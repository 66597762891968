import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  display: grid;
  gap: 2rem;

  .login-form {
    display: grid;
    gap: 1rem;
  }

  .buttons-container {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
  }

  ${props => props.materialTheme.breakpoints.up("md")} {
    .buttons-container {
      gap: 2rem;
      flex-direction: row;
    }
  }
`;
