import React, { useState } from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert } from "../../../components/mui";
import { StyledCircularProgress } from "../../../components/styled/StyledCircularProgress";
import Title from "../../../components/Title";
import { postResetPasswordRequest } from "../../../store/auth/auth.slice";
import { useViewPort } from "../../../utils/hooks/useViewPort";
import { schema } from "../../../utils/validators/formHookSchema";

const Request = () => {
  // * Named selectors
  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;

  // * Pass in named selectors and gets state from redux
  const { form, resetPasswordRequest } = useSelector(authState);
  const { config } = useSelector(institutionState);

  // * Hooks
  const dispatch = useDispatch();
  const viewPort = useViewPort();
  const navigate = useNavigate();
  const formOptions = {
    mode: "onBlur",
    defaultValues: { emailAddress: form?.emailAddress || "" }
  };
  const { register, handleSubmit, formState: { errors: formErrors } } = useForm(formOptions);
  const [errorMessage, setErrorMessage] = useState("");

  const postRequest = (form) => {
    setErrorMessage("");

    const payload = {
      email_address: form?.emailAddress,
      institution_id: config?.id,
      logo: config?.brand_logo_url,
      origin: window.location.origin
    };
    dispatch(postResetPasswordRequest(payload))
      .then((response) => {
        if (response["error"]) throw new Error();

        navigate("/check-email");
      }).
      catch(() => {
        setErrorMessage("There was an error sending your reset password link.");
      });
  };

  return (
    <>
      <Title
        title="Reset Your Password"
        subTitle="We won't be able to retrieve your password, but we can help you reset it"
        isCentered={viewPort.medium}
      />
      <Alert message={errorMessage} severity="error" />
      <form onSubmit={handleSubmit(postRequest)} noValidate={true}>
        <Grid
          container
          spacing={3}
          component="fieldset"
          sx={{ border: "none", padding: "0" }}
        >
          <Grid item xs={12}>
            <TextField {...schema.email.properties(formErrors, register, form?.emailAddress, dispatch)} />
          </Grid>

          <Grid item container direction="row-reverse">
            <Grid item xs={12} md={6}>
              <Box sx={{ position: "relative" }}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  variant="contained"
                  type="submit"
                  disabled={resetPasswordRequest.isSubmitting}
                  aria-label="Submit and navigate to success page"
                >
                  Submit
                </Button>
                {resetPasswordRequest?.isSubmitting && <StyledCircularProgress />}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default Request;
