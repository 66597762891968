import React, { useEffect, useState, useRef } from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { TextField, InputAdornment, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, SvgIcon, AutoCompleteDropdown, Checkbox } from "../../../../components";
import CardBrandLogo from "../../../../components/makePayment/CardBrandLogo";
import { setActivity } from "../../../../store/activity/activity.slice";
import {
  setName,
  setStreet,
  setCity,
  setPaymentType,
  setState,
  setFormErrorMessage,
  setIsStateOfResidenceConfirmed
} from "../../../../store/payment/makePayment.slice";
import { DEFAULT_CARDS } from "../../../../utils/constants/constants";

import states from "../../../../utils/data/states.json";
import {
  handleCardNumber,
  validateCardNumber,
  validateCvv,
  validateAmount,
  formatAmount,
  handleExpirationDate,
  handleZipCode,
  handlePaymentAmount,
  handleSecurityCode,
  validateExpirationDate
} from "../../../../utils/helpers/cardHelpers";
import { useMultipleFormErrors } from "../../../../utils/hooks/useMultipleFormErrors";
import { cardFormValidators } from "../../../../utils/validators/CardFormValidators";

const CardForm = (props) => {
  const { formTraits, submitForm } = props;
  const { convFee, shouldDisableAmount, card: { maxCardAmount, minCardAmount } } = formTraits;

  // Named selectors
  const paymentState = (state) => state.payment;
  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;
  const loanState = (state) => state.loans;

  // Pass in named selectors and gets state from redux
  const { config, details } = useSelector(institutionState);
  const { form, paymentType, loan, paymentDetails } = useSelector(paymentState);
  const { user, stateOfResidence: authStateOfResidence } = useSelector(authState);
  const { list: loans } = useSelector(loanState);

  const loanToPay = loans.find((l) => l.number === loan.number);

  // Local State
  const [maxCvvLength, setMaxCvvLength] = useState(3);
  const [stateAnnouncement, setStateAnnouncement] = useState("");

  // Hooks
  const options = {
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      amount: form?.amount || loanToPay.next_amount_due
    }
  };
  const {
    watch,
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
    trigger
  } = useForm(options);
  const { errorMessage } = useMultipleFormErrors(errors, ["stateInput", "stateOfResidenceCardInput"]);
  const dispatch = useDispatch();
  const previousAmount = useRef(form.amount);

  // * Watch values
  const watchCardCheckbox = authStateOfResidence?.hasStateOfResidence ? watch("cardCheckbox", false) : null;
  const stateOfRes = authStateOfResidence?.hasStateOfResidence ? user?.state_of_residence : paymentDetails?.stateOfResidenceCardInput;
  const watchStateOfResidenceCardDropdown = watch("stateOfResidenceCardDropdown", stateOfRes || "");
  const watchStateOfResidenceCardInput = watch("stateOfResidenceCardInput", stateOfRes || "");
  const watchStateDropdown = watch("state", form?.state || "");
  const watchStateInput = watch("stateInput", form?.state || "");

  useEffect(() => {
    dispatch(setPaymentType("card"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setIsStateOfResidenceConfirmed(watchCardCheckbox));
  }, [watchCardCheckbox, dispatch]);


  useEffect(() => {
    if (paymentType || errorMessage) {
      dispatch(setFormErrorMessage(errorMessage));
    }
  }, [dispatch, paymentType, errorMessage]);

  // Handlers
  const handleActivity = () => {
    dispatch(setActivity());
  };

  const handleNameChange = (event) => {
    dispatch(setName(event.target.value));
  };

  const handleStreetChange = (event) => {
    dispatch(setStreet(event.target.value));
  };

  const handleCityChange = (event) => {
    dispatch(setCity(event.target.value));
  };

  const maxCardNumberLength = 19;
  const acceptedCardTypes = details?.accepted_card_types?.length ? details?.accepted_card_types : DEFAULT_CARDS;

  const fullNameOptions = {
    onChange: handleNameChange,
    required: "Full name is required",
    pattern: {
      value: cardFormValidators.fullName.value,
      message: cardFormValidators.fullName.message
    }
  };

  const isValidState = (value) => {
    const findState = states.find((state) => state?.longName?.toLowerCase() === value?.toLowerCase());
    return (!value || !findState) ? "Invalid value" : null;
  };

  const cardNumberOptions = {
    onChange: (e) => handleCardNumber(e.target.value, form.securityCode, setMaxCvvLength, setError, clearErrors, dispatch),
    required: "Card number is required",
    maxLength: maxCardNumberLength,
    validate: {
      validCardNumber: (value) => {
        const cardNumberPayload = {
          cardNumber: value,
          acceptedCardTypes,
          cardBrand: form.cardBrand,
          nativeBinList: details?.native_bin_list,
          nativeBinErrorMessage: details?.native_bin_list_error_text
        };
        return validateCardNumber(cardNumberPayload);
      }
    }
  };

  const expirationDateOptions = {
    onChange: e => handleExpirationDate(form.expirationDate, e.target.value, setError, clearErrors, dispatch),
    required: "Expiration date is required",
    validate: {
      validExpirationDate: value => validateExpirationDate(value)
    }
  };

  const cvvOptions = {
    onChange: (e) => handleSecurityCode(e.target.value, setError, clearErrors, dispatch),
    required: "Security code is required",
    validate: {
      validCardNumber: value => validateCvv(value, form.cardBrand)
    }
  };

  const cityOptions = {
    onChange: handleCityChange,
    required: "Billing city is required",
    pattern: {
      value: cardFormValidators.city.value,
      message: cardFormValidators.city.message
    }
  };

  const streetOptions = {
    onChange: handleStreetChange,
    required: "Billing street is required",
    pattern: {
      value: cardFormValidators.street.value,
      message: cardFormValidators.street.message
    }
  };

  const zipCodeOptions = {
    onChange: (e) => handleZipCode(e.target.value, setError, clearErrors, dispatch),
    required: "Zip code is required",
    pattern: {
      value: cardFormValidators.zipCode.value,
      message: cardFormValidators.zipCode.message
    }
  };

  const amountOptions = {
    onChange: (e) => handlePaymentAmount(e.target.value, previousAmount, convFee, setError, clearErrors, dispatch, setValue),
    required: "Payment amount is required",
    validate: {
      validAmount: value => validateAmount(value, maxCardAmount, minCardAmount)
    },
    onBlur: e => {
      formatAmount(e.target.value, convFee, dispatch, setValue);
      trigger("amount");
    }
  };

  return (
    <aside>

      <form onSubmit={handleSubmit(submitForm)} onBlur={handleActivity} id="payment-form" noValidate={true}>
        <Grid container
          direction="column"
          columns={{ xs: 12, md: 12 }}
          spacing={1}
          component="fieldset"
          sx={{ border: "none", padding: "0px", marginTop: "0.5rem" }}>
          <Grid item xs={12}>
            <Typography variant="h4" component="legend">
              Payment Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("fullName", fullNameOptions)}
              autoComplete=" cc-name"
              id="name-input"
              label="Name on Card"
              required
              inputProps={{ "aria-required": "true" }}
              fullWidth
              value={form.fullName}
              helperText={errors?.fullName ? errors.fullName.message : " "}
              error={!!errors?.fullName} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              {...register("cardNumber", cardNumberOptions)}
              autoComplete=" cc-number"
              id="card-number-input"
              label="Card Number"
              required
              fullWidth
              value={form.cardNumber.userDisplay}
              helperText={errors?.cardNumber ? errors.cardNumber.message : acceptedCardTypes?.join(", ")}
              error={!!errors?.cardNumber}
              InputProps={{ endAdornment: <CardBrandLogo cardBrand={form.cardBrand} /> }}
              inputProps={{ maxLength: maxCardNumberLength, "aria-required": "true" }} />
          </Grid>
          <Grid container item xs={12} md={6} spacing={1}>
            <Grid item xs={6}>
              <TextField
                {...register("expirationDate", expirationDateOptions)}
                autoComplete=" cc-exp"
                id="expiration-date-input"
                label="Expiration Date"
                required
                fullWidth
                value={form.expirationDate}
                helperText={errors?.expirationDate ? errors.expirationDate.message : "(MM/YY)"}
                error={!!errors?.expirationDate}
                inputProps={{ maxLength: 5, "aria-required": "true" }} />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                {...register("securityCode", cvvOptions)}
                autoComplete=" cc-csc"
                id="security-code-input"
                label="Security Code"
                required
                fullWidth
                value={form.securityCode}
                helperText={errors?.securityCode ? errors.securityCode.message : " "}
                error={!!errors?.securityCode}
                inputProps={{ maxLength: maxCvvLength, "aria-required": "true" }} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" component="legend">
              Billing Address
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("street", streetOptions)}
              autoComplete=" address-line1"
              id="street-input"
              label="Street"
              required
              inputProps={{ "aria-required": "true" }}
              fullWidth
              value={form.street}
              helperText={errors?.street ? errors.street.message : " "}
              error={!!errors?.street} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("city", cityOptions)}
              autoComplete="address-level2"
              id="city-input"
              label="City"
              required
              inputProps={{ "aria-required": "true" }}
              fullWidth
              value={form.city}
              helperText={errors?.city ? errors.city.message : " "}
              error={!!errors?.city} />
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={6}>
              <AutoCompleteDropdown
                registerAutoComplete={{
                  ...register("state", {
                    required: "State is required",
                    validate: {
                      state: (value) => isValidState(value)
                    }
                  })
                }}
                registerTextField={{
                  ...register("stateInput", {
                    required: "State is required",
                    validate: {
                      state: (value) => isValidState(value)
                    }
                  })
                }}
                aria-label="Please select a state"
                errors={errors?.state || errors?.stateInput}
                options={states?.map(ele => ele.longName)}
                getOptionLabel={(option) => option}
                label="State"
                id="state"
                selected={watchStateDropdown.length ? watchStateDropdown : ""}
                onInputChange={(_, value) => {
                  setValue("stateInput", value, {
                    shouldDirty: true,
                    shouldValidate: true
                  });
                }}
                onChange={(_, value) => {
                  setStateAnnouncement(value);
                  setValue("state", value, {
                    shouldDirty: true,
                    shouldValidate: true
                  });
                  dispatch(setState(value));
                }}
                inputValue={watchStateInput ? watchStateInput : ""}
                value={watchStateDropdown ? watchStateDropdown : null}
                isOptionEqualToValue={(option, value) => (value === "" || value === option)}
                stateAnnouncement={stateAnnouncement}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register("zipCode", zipCodeOptions)}
                autoComplete="postal-code"
                id="zipCode-input"
                label="Zip Code"
                required
                inputProps={{ "aria-required": "true", maxLength: 5 }}
                fullWidth
                value={form.zipCode}
                helperText={errors?.zipCode ? errors.zipCode.message : "5-digit"}
                error={!!errors?.zipCode}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" component="legend">
              Payment Amount
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("amount", amountOptions)}
              autoComplete=" transaction-amount"
              id="payment-amount-input"
              label="Payment Amount"
              required
              fullWidth
              disabled={shouldDisableAmount}
              placeholder={Number(form.amount) === 0 ? "0.00" : ""}
              helperText={errors?.amount ? errors.amount.message : " "}
              error={!!errors?.amount}
              inputProps={{ "aria-required": "true" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon component={AttachMoneyIcon}></SvgIcon>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          {
            config.services?.payments?.state_of_residence?.enabled
              ?
              (
                <>
                  <Grid item xs={12}>
                    <AutoCompleteDropdown
                      registerAutoComplete={{
                        ...register("stateOfResidenceCardDropdown", {
                          required: "State of residence is required",
                          validate: {
                            state: (value) => isValidState(value)
                          }
                        })
                      }}
                      registerTextField={{
                        ...register("stateOfResidenceCardInput", {
                          required: "State of residence is required",
                          validate: {
                            state: (value) => isValidState(value)
                          }
                        })
                      }}
                      aria-label="Please select a state"
                      errors={errors?.stateOfResidenceCardDropdown || errors?.stateOfResidenceCardInput}
                      options={states?.map(ele => ele.longName)}
                      getOptionLabel={(option) => option}
                      label="State of Residence"
                      id="state-of-residence"
                      selected={watchStateOfResidenceCardDropdown.length ? watchStateOfResidenceCardDropdown : ""}
                      onInputChange={(_, value) => {
                        setValue("stateOfResidenceCardInput", value, {
                          shouldDirty: true,
                          shouldValidate: true
                        });
                      }}
                      onChange={(_, value) => {
                        setStateAnnouncement(value);
                        setValue("stateOfResidenceCardDropdown", value, {
                          shouldDirty: true,
                          shouldValidate: true
                        });
                      }}
                      inputValue={watchStateOfResidenceCardInput ? watchStateOfResidenceCardInput : ""}
                      value={watchStateOfResidenceCardDropdown ? watchStateOfResidenceCardDropdown : null}
                      isOptionEqualToValue={(option, value) => (value === "" || value === option)}
                      stateAnnouncement={stateAnnouncement}
                    />
                  </Grid>
                  {
                    authStateOfResidence.hasStateOfResidence ?
                      <Grid item>
                        <FormControl error={!!errors?.cardCheckbox}>
                          <FormControlLabel
                            {...register("cardCheckbox", {
                              required: "Please confirm state of residence."
                            })}
                            aria-label="Click check mark box to confirm your state of residence."
                            control={<Checkbox checked={watchCardCheckbox || false} />}
                            label={`I confirm my current state of residence is ${watchStateOfResidenceCardDropdown}`}
                          />
                          <FormHelperText>{errors?.cardCheckbox?.message || " "}</FormHelperText>
                        </FormControl>
                      </Grid>
                      : null
                  }
                </>
              )
              :
              null
          }
        </Grid>
      </form>
    </aside>
  );
};

export default CardForm;
