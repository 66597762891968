import React, { useState, useEffect, forwardRef, useCallback, useRef } from "react";
import { Modal, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsTimeoutTimerActive, setIsTimeoutModalOpen, setTimeoutApiError } from "../../store/activity/activity.slice";
import { setTimeoutMessage, postTokenRefresh, clearAuthStateOnLogout } from "../../store/auth/auth.slice";
import { resetNavState } from "../../store/navhistory/navhistory.slice";
import { SESSION_TIMEOUT, ONE_SECOND, TIMEOUT_MODAL_TITLE, EXPRESS_PAY, REGISTERED, TIMEOUT_ERR_MSG } from "../../utils/constants/constants";
import { getExpressPayApiResponse } from "../../utils/data/ExpressPayApiResponses";
import { getLoginApiResponse } from "../../utils/data/LoginApiResponses";
import ModalAnnouncer from "../announcers/ModalAnnouncer";
import { Alert, Box, Typography, Grid } from "../mui";
import { StyledCircularProgress } from "../styled/StyledCircularProgress";
import Title from "../Title";

const countDownTimer = Number(process.env.REACT_APP_MODAL_COUNTDOWN_TIMER);

const modalBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgColor: "background.paper",
  textAlign: "center",
  maxWidth: "466px"
};

const TimeoutModal = ({ redirectionPath }, ref) => {
  // Named selectors
  const activityState = (state) => state.activity;
  const authState = (state) => state.auth;

  // Use named selectors
  const { timeoutModal } = useSelector(activityState);
  const { authToken, flow, user } = useSelector(authState);

  // Hooks
  const [secondsCounter, setSecondsCounter] = useState(countDownTimer);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeoutApiErrorMessage, setTimeoutApiErrorMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isContinueClicked = useRef(false);

  const logout = useCallback((isExitButtonClicked) => {
    dispatch(setIsTimeoutTimerActive(false));
    dispatch(setIsTimeoutModalOpen(false));
    dispatch(resetNavState());
    if (!isExitButtonClicked && !user.from_sso) {
      dispatch(setTimeoutMessage(SESSION_TIMEOUT));
    }
    setTimeoutApiErrorMessage("");
    dispatch(clearAuthStateOnLogout());
    navigate(redirectionPath);
  }, [dispatch, navigate, redirectionPath]);

  useEffect(() => {
    let timeout;
    if (isContinueClicked.current) {
      isContinueClicked.current = false;
    } else if (secondsCounter > 0) {
      timeout = setTimeout(() => {
        setSecondsCounter(secondsCounter - 1);
      }, ONE_SECOND);
    } else {
      logout(false);
    }

    return () => clearTimeout(timeout);
  }, [secondsCounter, logout]);

  useEffect(() => {
    if (timeoutModal.apiError) {
      setTimeoutApiErrorMessage(timeoutModal.apiError.response);
    }
  }, [timeoutModal.apiError]);

  const handleContinueClick = () => {
    setIsSubmitting(true);
    dispatch(setIsTimeoutModalOpen(false));
    isContinueClicked.current = true;
    dispatch(setIsTimeoutTimerActive(false));
    dispatch(setTimeoutApiError(null));
    setTimeoutApiErrorMessage("");
    dispatch(postTokenRefresh(authToken.value))
      .unwrap()
      .then((response) => {
        if (response) {
          dispatch(setIsTimeoutTimerActive(true));
        }
      })
      .catch((error) => {
        if (error?.status === 403) {
          dispatch(setTimeoutMessage(TIMEOUT_ERR_MSG));
          return;
        }
        const entryPath = {
          [EXPRESS_PAY]: getExpressPayApiResponse(error),
          [REGISTERED]: getLoginApiResponse(error)
        };

        const defaultError = {
          severity: "error",
          response: "An unexpected error occurred. Please try again."
        };

        dispatch(setTimeoutApiError(entryPath[flow] || defaultError));
      });
    setIsSubmitting(false);
  };

  return (
    <Modal aria-label="Timeout Modal" open={timeoutModal.isOpen} ref={ref}>
      <Box sx={modalBoxStyle}>
        <ModalAnnouncer modalName="session timeout" />
        <Box styledBox sx={{ paddingTop: "1.5rem" }}>
          <Alert message={timeoutApiErrorMessage} severity="error" />
          <Title title={TIMEOUT_MODAL_TITLE} aria-label={TIMEOUT_MODAL_TITLE} isCentered />
          <span aria-label={`${countDownTimer} seconds to respond`}></span>
          <Typography variant="h2" component="p" id="countdown-timer" aria-hidden="true">{secondsCounter}</Typography>
          <Typography variant="body1" sx={{ textAlign: "left" }}>
            The current session is about to timeout. Click CONTINUE to stay logged in or EXIT to exit the session.
          </Typography>
          <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
            <Grid item xs={12} md={6} order={{ sm: 1, md: 2 }}>
              <Box sx={{ position: "relative" }} gridArea="button">
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  sx={{ whiteSpace: "nowrap" }}
                  disabled={isSubmitting}
                  onClick={handleContinueClick}
                >
                  Continue Session
                </Button>
                {isSubmitting && <StyledCircularProgress size="1.5rem" />}
              </Box>
            </Grid>
            <Grid item xs={12} md={6} order={{ sm: 2, md: 1 }}>
              <Button
                color="secondary"
                fullWidth
                onClick={logout}
                size="large"
                sx={{ whiteSpace: "nowrap" }}
                variant="contained"
              >
                Exit Session
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default forwardRef(TimeoutModal);
