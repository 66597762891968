import React from "react";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Typography } from "../../components";
import { StyledCircularProgress } from "../../components/styled/StyledCircularProgress";
import Title from "../../components/Title";
import { putChangeEmail } from "../../store/auth/auth.slice";
import { useMultipleFormErrors } from "../../utils/hooks/useMultipleFormErrors";
import { validate } from "../../utils/validators/validators";

const sectionStyle = {
  border: "none",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  margin: "0px",
  padding: "0px"
};

const options = {
  mode: "onBlur",
  reValidateMode: "onChange",
  shouldFocusError: true
};

export const ProfileChangeEmail = () => {
  // Named selectors
  const authState = (state) => state.auth;

  // Redux State
  const { authToken, changeEmail, user } = useSelector(authState);

  // Hooks
  const { register, handleSubmit, formState: { errors } } = useForm(options); // React Hook Form
  const { errorMessage } = useMultipleFormErrors(errors); // Hook that for handling multiple form errors
  const dispatch = useDispatch();

  // Handlers
  const handleChangeEmail = (data) => {
    const payload = {
      token: authToken.value,
      user_id: user.id,
      email_address: data.emailAddress.toLowerCase().trim()
    };
    dispatch(putChangeEmail(payload));
  };

  return (
    <>
      <Title title="Change Email" />
      <Alert
        message={changeEmail?.api?.response?.response}
        severity={changeEmail?.api?.response?.severity}
      />
      <Alert
        message={errorMessage}
        severity="error"
      />
      <Typography
        component="p"
        variant="body2"
        sx={{ marginBlock: "0.5rem" }}
      >
        * Required Field
      </Typography>

      <form style={{ display: "flex", flexDirection: "column", gap: "1rem" }} onSubmit={handleSubmit(handleChangeEmail)} noValidate={true}>
        <fieldset style={sectionStyle}>
          <Typography component="legend" variant="body1" sx={{ marginBottom: "0.875rem" }}>
            Please provide a valid email address for important notifications.
          </Typography>
          <TextField
            autoComplete="email"
            defaultValue={user?.email_address}
            error={!!errors?.emailAddress}
            fullWidth
            helperText={errors?.emailAddress?.message || " "}
            id="email-address"
            inputMode="email"
            inputProps={{ "aria-required": "true" }}
            label="Email"
            required
            size="medium"
            type="email"
            {...register("emailAddress", {
              maxLength: {
                value: 80,
                message: "Email address must be less than 80 characters"
              },
              required: "Email address is required",
              pattern: validate.email
            })}
          />
        </fieldset >

        <div style={{ position: "relative", display: "flex", gap: "1rem" }}>
          <Button
            color="primary"
            disabled={changeEmail?.api?.isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Save Changes
          </Button>
          {changeEmail?.api?.isSubmitting ? <StyledCircularProgress /> : null}
        </div>
      </form>
    </>
  );
};
